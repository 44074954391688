import { gql } from 'graphql-tag'
const modelsCarsQuery = gql`
  query modelsCarsQuery(
    $makeSlug: String
    $modelName: String
    $perPage: Int
    $sortByFinancingPrice: String
    $sortByRelevance: Boolean
    $page: Int
    $useCasesIn: [String!]
    $fuelTypeIn: [String!]
    $powertrainTypeIn: [String!]
    $transmissionTypeIn: [String!]
    $discountedPriceCentsBetween: [Int!]
    $discountedPriceCentsGte: Int
    $discountedPriceCentsLte: Int
    $instalmentPriceCentsBetween: [Int!]
    $instalmentPriceCentsGte: Int
    $instalmentPriceCentsLte: Int
    $bodyworkIn: [String!]
    $environmentalLabels: [String!]
    $financialEntityName: String
  ) {
    models(
      includeNoPublishable: false
      make: { slugLike: $makeSlug }
      vehicleTypeIn: ["car", "van"]
      perPage: $perPage
      sortByFinancingPrice: $sortByFinancingPrice
      sortByRelevance: $sortByRelevance
      page: $page
      useCasesIn: $useCasesIn
      nameLike: $modelName
      versions: {
        specs: {
          fuelTypeIn: $fuelTypeIn
          powertrainTypeIn: $powertrainTypeIn
          transmissionTypeIn: $transmissionTypeIn
        }
        bodyworkIn: $bodyworkIn
        environmentalLabelIn: $environmentalLabels
        vehicles: { categoryIn: "VN" }
      }
      vehicles: { categoryIn: "VN" }
      discountedPriceCentsBetween: $discountedPriceCentsBetween
      discountedPriceCentsGte: $discountedPriceCentsGte
      discountedPriceCentsLte: $discountedPriceCentsLte
      instalmentPriceCentsBetween: $instalmentPriceCentsBetween
      instalmentPriceCentsGte: $instalmentPriceCentsGte
      instalmentPriceCentsLte: $instalmentPriceCentsLte
    ) {
      nodes {
        name
        slug
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
        publishableUnits(category: "vn")
        environmentalLabels(category: "vn")
        hero {
          file
        }
        make {
          name
          slug
        }
        vehicles(sortByPrice: "asc", categoryIn: "VN") {
          useCases
          vehicleConfig {
            publishable
            hero {
              file
            }
          }
        }
      }
      pagesCount
      nodesCount
      hasNextPage
      hasPreviousPage
    }

    vehicles(
      make: { slugLike: $makeSlug }
      model: { nameLike: $modelName, vehicleTypeIn: ["car", "van"], includeNoPublishable: false }
      useCasesIn: $useCasesIn
      version: {
        specs: {
          fuelTypeIn: $fuelTypeIn
          powertrainTypeIn: $powertrainTypeIn
          transmissionTypeIn: $transmissionTypeIn
        }
        bodyworkIn: $bodyworkIn
        environmentalLabelIn: $environmentalLabels
        vehicles: { categoryIn: "VN" }
      }
      categoryIn: "VN"
      discountedPriceCentsBetween: $discountedPriceCentsBetween
      discountedPriceCentsGte: $discountedPriceCentsGte
      discountedPriceCentsLte: $discountedPriceCentsLte
      instalmentPriceCentsBetween: $instalmentPriceCentsBetween
      instalmentPriceCentsGte: $instalmentPriceCentsGte
      instalmentPriceCentsLte: $instalmentPriceCentsLte
    ) {
      nodesCount
    }
  }
`

export default modelsCarsQuery
