/**
 * dniRegex admit 8 numbers follow by a
 * a letter, which depends on the previous numbers
 * % by 23 (it's a security letter)
 */
const dniRegex = new RegExp('^[0-9]{8}[a-zA-Z]{1}$')
const dniFormat = (value) => dniRegex.test(value)
function lastLetterValidator(value) {
  const idVal = Number(value.slice(0, 8)) % 23
  const lastLetter = 'TRWAGMYFPDXBNJZSQVHLCKE'
  return lastLetter[idVal] === value.slice(-1).toUpperCase()
}
const dni = (value) => dniFormat(value) && lastLetterValidator(value)

export default dni
