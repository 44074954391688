const BASE_URL = '/v1/customers'

export default ($axios, language) => ({
  register(customer) {
    return $axios.post(`${BASE_URL}/registration`, { customer })
  },

  login(customer) {
    return $axios.post(`${BASE_URL}/login`, { customer })
  },

  requestMagicLink(data) {
    return $axios.post('/v1/passwordless/customers/sign_in', {
      ...data,
    })
  },

  loginWithOtp(data) {
    return $axios.get('/v1/passwordless/customers/magic_link', {
      params: { ...data },
    })
  },

  updatePassword({ current, password, confirmation }) {
    const customer = {
      current_password: current,
      password,
      password_confirmation: confirmation || password,
    }

    return $axios.put(`${BASE_URL}/registration`, { customer })
  },

  requestNewPassword(email) {
    const customer = {
      email,
    }

    return $axios.post(`${BASE_URL}/password`, { customer })
  },

  validateCreatePasswordToken(token) {
    const params = {
      reset_password_token: token,
    }

    return $axios.get(`${BASE_URL}/password/edit`, { params })
  },

  createPassword({ token, password, confirmation }) {
    const params = {
      customer: {
        reset_password_token: token,
        password,
        confirmation,
      },
    }

    return $axios.put(`${BASE_URL}/password`, params)
  },

  fetchCustomer() {
    return $axios.$get('/v1/me')
  },

  updateCustomer(customer) {
    return $axios.put('/v1/me', { customer })
  },

  updateCustomerGdpr(gdprConsents, token) {
    return $axios.$patch('/v1/me/gdpr_consents', { gdpr_consents: gdprConsents, token })
  },

  removeCustomer(customer) {
    return $axios.$patch('/v1/me/remove_accounts', { customer })
  },

  deleteCustomer() {
    return $axios.$delete('/v1/me')
  },
})
