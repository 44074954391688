var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['select-wrapper', { 'skeleton-loader': _vm.skeletonLoader }]},[(_vm.label)?_c('label',{staticClass:"f-size-14 f-500",attrs:{"for":`select-${_vm.id}`}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{class:[
      'select',
      {
        'select--focused': _vm.focused,
        'select--error': _vm.error,
        'select--disabled': _vm.disabled,
        'select--filled': _vm.isFilled,
        ...(_vm.size && { [`select--${_vm.size}`]: _vm.size }),
      },
    ]},[(_vm.prependIcon)?_c('div',{staticClass:"icon-wrapper prepend",class:{ clickable: _vm.prependClickable },attrs:{"data-cy":"prepend-icon"},on:{"click":function($event){return _vm.onPrependClick()}}},[_c('Icon',{attrs:{"name":_vm.prependIcon,"size":"1.25rem"}})],1):_vm._e(),_vm._v(" "),_c('select',_vm._g(_vm._b({ref:`select-${_vm.id}`,attrs:{"id":`select-${_vm.id}`,"aria-errormessage":_vm.error ? `select-${_vm.id}-error` : undefined,"aria-invalid":!!_vm.error,"aria-label":_vm.label,"disabled":_vm.disabled,"invalid":!!_vm.error,"name":_vm.name},on:{"input":function($event){return _vm.onInput($event.target.value)},"focus":function($event){return _vm.onFocus()},"blur":function($event){return _vm.onBlur()}}},'select',_vm.$attrs,false),_vm.listeners),[(_vm.placeholder)?_c('option',{attrs:{"value":""}},[_vm._v("\n        "+_vm._s(_vm.placeholder)+"\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.options),function([key, display]){return _c('option',{key:key,domProps:{"value":key,"selected":_vm.value === key}},[_vm._v("\n        "+_vm._s(display)+"\n      ")])}),_vm._v(" "),_vm._l((_vm.groups),function(group){return _c('optgroup',{key:group.name,attrs:{"label":group.name}},_vm._l((group.values),function([key, display]){return _c('option',{key:key,domProps:{"value":key,"selected":_vm.value === key}},[_vm._v("\n          "+_vm._s(display)+"\n        ")])}),0)})],2),_vm._v(" "),_c('div',{staticClass:"icon-wrapper"},[_c('Icon',{attrs:{"name":_vm.error ? 'warning' : _vm.disabled ? 'lock' : 'keyboard_arrow_down',"size":"1.25rem"}})],1)]),_vm._v(" "),_c('div',{staticClass:"select-wrapper__feedback"},[(_vm.error)?_c('span',{staticClass:"error f-size-12",attrs:{"id":`input-${_vm.id}-error`,"role":"alert"}},[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")]):(_vm.hint)?_c('span',{staticClass:"hint f-size-12"},[_vm._v(_vm._s(_vm.hint))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }