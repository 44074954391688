<template>
  <div id="layoutsDefault">
    <main>
      <nuxt />
    </main>

    <NewsletterModule />

    <Footer />
  </div>
</template>

<script>
// Components
import Footer from '@/components/Footer.vue'
import NewsletterModule from '@/components/modules/Newsletter.vue'

export default {
  name: 'LayoutsDefault',
  scrollToTop: true,
  components: {
    Footer,
    NewsletterModule,
  },
  async beforeCreate() {
    await this.$store.dispatch('campaign/fetchData', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
}
</script>

<style lang="scss" scoped>
#layoutsDefault main {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - 36.125rem);

  @supports (min-height: 100dvh) {
    min-height: calc(100dvh - 36.125rem);
  }
}
</style>
