<template>
  <div class="astara-benefits-slider">
    <div
      v-for="(benefit, index) in benefits"
      :key="`benefit-${index}`"
      class="container astara-benefits-slider__slide container-flex s-gap-12"
    >
      <p class="f-500 s-m-0">{{ $t('modules.astara_benefits.title') }}</p>
      <div class="astara-benefits-slider__slide__icon">
        <Icon :name="benefit.icon" />
      </div>
      <p class="s-m-0">
        <span class="desktop">{{ benefit.short_description }}</span>
        <span class="mobile">{{ benefit.x_short_description }}</span>
      </p>
    </div>
  </div>
</template>

<script>
// Helpers & mixins
import { astaraBenefits } from '@/helpers/catalog'

// Components
import Icon from '@/components/Icon.vue'

export default {
  name: 'ModulesAstaraBenefitsSlider',
  components: {
    Icon,
  },
  data() {
    return {
      benefits: astaraBenefits(this.$i18n),
    }
  },
}
</script>

<style lang="scss" scoped>
.astara-benefits-slider {
  position: relative;
  height: 2.25rem;
  overflow: hidden;
  background-color: $c-primary-800;

  @include size-xl-up {
    height: 3rem;
  }

  &__slide {
    position: absolute;
    top: 100%;
    left: 0;
    justify-content: center;
    width: 100%;
    height: inherit;
    overflow: 0;
    animation: 16s slide infinite ease-in-out;
    background-color: $c-primary-800;
    color: $text-primary;
    text-align: center;

    &__icon {
      --size: 1.25rem;

      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--size);
      min-width: var(--size);
      height: var(--size);
      border-radius: 0.125rem;
      background-color: $c-neutral-025;
      color: $text-primary;

      :deep(.icon) {
        font-size: 1rem;
      }

      @include size-xl-up {
        --size: 2rem;

        :deep(.icon) {
          font-size: 1.5rem;
        }
      }
    }

    &:nth-child(1) {
      z-index: 1;
      animation-delay: 0s;
      background-color: $c-primary-800;
      color: $c-white;
    }

    &:nth-child(2) {
      z-index: 2;
      animation-delay: 4s;
      background-color: $c-neutral-025;

      .astara-benefits-slider__slide__icon {
        background-color: $c-primary-050;
      }
    }

    &:nth-child(3) {
      z-index: 3;
      animation-delay: 8s;
      background-color: $c-support-01-500;
    }

    &:nth-child(4) {
      z-index: 4;
      animation-delay: 12s;
      background-color: $c-support-02-500;
    }

    p {
      @include size-xl-up {
        font-size: 1.125rem;

        .mobile {
          display: none;
        }
      }

      @include size-l {
        font-size: 0.75rem;

        &:first-child {
          display: none;
        }

        .desktop {
          display: none;
        }
      }
    }
  }

  @keyframes slide {
    0% {
      top: 100%;
    }
    4% {
      top: 0%;
    }
    33.33% {
      top: 0%;
    }
    37.33% {
      top: -100%;
    }
    100% {
      top: -100%;
    }
  }
}
</style>
