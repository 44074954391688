<template>
  <picture
    :class="[
      'image-imgix',
      xclass,
      {
        'image-imgix--fallback': imgixError,
      },
    ]"
    data-cy="image-imgix"
  >
    <img
      ref="image"
      :alt="alt"
      :decoding="required ? undefined : 'async'"
      :fetchpriority="required ? 'medium' : 'low'"
      :loading="required ? undefined : 'lazy'"
      :srcset="imageSrcset"
      :src="imageSrc"
      @error="() => setupFallbackImage(url)"
      @click="$emit('click')"
    />
  </picture>
</template>

<script>
export default {
  name: 'ImageImgix',
  props: {
    url: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    area: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    alt: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    width: {
      type: [Number, undefined],
      required: false,
      default: undefined,
    },
    height: {
      type: [Number, undefined],
      required: false,
      default: undefined,
    },
    xclass: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      imageSrc: '',
      imageSrcset: '',
      imgixError: false,
    }
  },
  methods: {
    // l.c.d.: least common divisor
    lcd(a, b) {
      return b === 0 ? a : this.lcd(b, a % b)
    },
    aspectRatio() {
      const areaParams = this.area.split(',')
      const width = parseInt(areaParams[0])
      const height = parseInt(areaParams[1])
      const divider = this.lcd(width, height)

      return `${width / divider} / ${height / divider}`
    },
    setupFallbackImage(url) {
      if (!this.imgixError) {
        this.imageSrc = url || '/images/no-image-available-placeholder.webp'
        this.imgixError = true

        if (this.area) {
          this.$refs.image.style.aspectRatio = this.aspectRatio()
        }
      }
    },
    generateImageImgix() {
      try {
        if (
          !this.url ||
          (process?.browser && process.env.isDevelopment && (window.Cypress || window.cy))
        ) {
          this.setupFallbackImage('/images/no-image-available-placeholder.webp')
        } else {
          let imgxQuery = ''

          if (this.area) {
            const areaParams = this.area.split(',')
            imgxQuery = `fit=crop&max-h=${areaParams[1]}&max-w=${areaParams[0]}&min-h=${areaParams[1]}&min-w=${areaParams[0]}`
          }

          if (this.width && this.height) {
            imgxQuery += `&fit=${this.width}.${this.height}`
          } else if (this.width) {
            imgxQuery += `&w=${this.width}`
          } else if (this.height) {
            imgxQuery += `&h=${this.height}`
          }

          const baseUrl = this.url.split('/uploads/')[1]

          this.imageSrc = `${process.env.urlImgix}/${baseUrl}?&auto=compress,format&${imgxQuery}`

          if (this.width || this.height) {
            this.imageSrcset = `${this.imageSrc}&dpr=1 1x, ${this.imageSrc}&dpr=2 2x, ${this.imageSrc}&dpr=3 3x`
          }
        }
      } catch (err) {
        this.$logger('Error processing Imgix image URL', err)
        this.setupFallbackImage(this.url)
      }
    },
  },
  mounted() {
    this.generateImageImgix()
  },
  watch: {
    // If the url changes, the imageSrc param should change as well
    url(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.generateImageImgix()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.image-imgix {
  margin: 0;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }

  &--fallback img {
    object-fit: cover;
    object-position: center;
  }
}
</style>
