<template>
  <div
    :data-cy="`${type}_notification`"
    :class="{
      'notification--status-inline': inline,
      'notification--status-visible': displayed,
      'notification--style-horizontal': isHorizontal,
      'notification--style-light': light,
      'notification--style-no-header': !$slots.title && !title && !isHorizontal,
      'notification--style-no-close-btn': !canBeClosed,
      'notification--style-small': small,
      'notification--type-error': error || type === 'error',
      'notification--type-info':
        !info && !error && !success && !warning ? true : info || type === 'info',
      'notification--type-success': success || type === 'success',
      'notification--type-warning': warning || type === 'warning',
      notification: true,
    }"
  >
    <aside class="notification__aside">
      <Icon v-if="currentIcon" filled :name="currentIcon" size="1.25rem" />
    </aside>

    <div class="notification__body">
      <div
        v-if="title || $slots.title || (canBeClosed && !light)"
        class="notification__body__header"
      >
        <slot v-if="$slots.title && !isHorizontal" name="title" />

        <p
          v-else-if="title && !isHorizontal"
          :class="[
            'notification__body__header__title',
            'bold',
            {
              'f-size-16': small,
              'f-size-18': !small,
            },
          ]"
        >
          {{ title }}
        </p>

        <Button
          v-if="canBeClosed || (notificationId && !light)"
          type-theme="ghost-light"
          size="small"
          class="notification__body__header__close"
          subtype="only-icon"
          @click="() => $emit('close')"
        >
          <Icon name="close" />
        </Button>
      </div>

      <div v-if="message || actions.length || $slots.message" class="notification__body__main">
        <p
          v-if="message && !$slots.message"
          :class="[
            {
              'f-size-14': small,
              'f-size-16': !small,
            },
          ]"
          v-html="message"
        />

        <slot name="message" />

        <nav v-if="actions.length" class="notification__body__actions">
          <component
            :is="action.to || action.href ? 'NuxtLink' : 'span'"
            v-for="action in actions"
            :key="action.label"
            :to="action.to ? action.to : undefined"
            :href="action.href ? action.href : undefined"
            :class="{
              notification__body__actions__action: true,
              'f-as-link': inline || light,
              'upper-text f-not-as-link': !inline && !light,
              'f-size-16': !inline && !light && !small,
              'f-size-14': !inline && !light && small,
            }"
            @click="action?.fn ? action.fn : undefined"
          >
            {{ action.label }}
          </component>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'Notification',
  components: {
    Button,
    Icon,
  },
  props: {
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
    displayed: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    info: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    success: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    warning: {
      type: Boolean,
      required: false,
      default: false,
    },
    notificationId: {
      type: String,
      required: false,
      default: '',
    },
    canBeClosed: {
      type: Boolean,
      required: false,
      default: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isHorizontal() {
      return this.horizontal || (this.error && !this.title && !this.$slots.title)
    },
    currentIcon() {
      if (!this.icon) {
        if (this.error) {
          return 'error'
        } else if (this.success) {
          return 'check_circle'
        } else if (this.warning) {
          return 'warning'
        } else {
          return 'info'
        }
      } else {
        return this.icon
      }
    },
    type() {
      if (this.success) {
        return 'success'
      } else if (this.info) {
        return 'info'
      } else if (this.warning) {
        return 'warning'
      } else if (this.error) {
        return 'error'
      } else {
        return 'default'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.notification {
  // Notification
  --notification-border-radius: 0.5rem;

  // Notification: toast
  --notification-border-color-info: #2377d1;
  --notification-body-background-color-info: #1e1932;
  --notification-body-color-info: #fff;
  --notification-background-color-info: #2377d1;
  --notification-icon-color-info: #fff;
  --notification-action-color-info: #cbc5e2;
  --notification-border-color-error: #d01002;
  --notification-body-background-color-error: #1e1932;
  --notification-body-color-error: #fff;
  --notification-background-color-error: #d01002;
  --notification-icon-color-error: #fff;
  --notification-action-color-error: #cbc5e2;
  --notification-border-color-success: #069c64;
  --notification-body-background-color-success: #1e1932;
  --notification-body-color-success: #fff;
  --notification-background-color-success: #069c64;
  --notification-icon-color-success: #fff;
  --notification-action-color-success: #cbc5e2;
  --notification-border-color-warning: #faaf23;
  --notification-body-background-color-warning: #1e1932;
  --notification-body-color-warning: #fff;
  --notification-background-color-warning: #faaf23;
  --notification-icon-color-warning: #fff;
  --notification-action-color-warning: #cbc5e2;

  // Notification: inline
  --notification-border-color-info-inline: #2377d1;
  --notification-body-background-color-info-inline: transparent;
  --notification-body-color-info-inline: #1e1932;
  --notification-background-color-info-inline: #e9f1fa;
  --notification-icon-color-info-inline: #2377d1;
  --notification-border-color-error-inline: #d01002;
  --notification-body-background-color-error-inline: transparent;
  --notification-body-color-error-inline: #1e1932;
  --notification-background-color-error-inline: #fae7e6;
  --notification-icon-color-error-inline: #d01002;
  --notification-border-color-success-inline: #069c64;
  --notification-body-background-color-success-inline: transparent;
  --notification-body-color-success-inline: #1e1932;
  --notification-background-color-success-inline: #e6f5ef;
  --notification-icon-color-success-inline: #069c64;
  --notification-border-color-warning-inline: #faaf23;
  --notification-body-background-warning-info-inline: transparent;
  --notification-body-color-warning-inline: #1e1932;
  --notification-background-color-warning-inline: #fff7e9;
  --notification-icon-color-warning-inline: #faaf23;
  --width: auto;

  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  transition: opacity 0.3s ease;
  border: 0.0625rem solid var(--border-color);
  border-radius: var(--notification-border-radius);
  background-color: var(--background-color);
  box-shadow: var(--e-shadow-1);

  p {
    margin: 0;
  }

  &__aside {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75rem;
    min-width: 2.75rem;
    margin-bottom: auto;
    padding: 1.125rem 0.75rem 0;
    color: var(--icon-color);
  }

  &__body {
    position: relative;
    width: 100%;
    padding: 1.125rem 0.75rem;
    background-color: var(--body-background-color);
    color: var(--body-color);
    text-align: left;

    &__header {
      padding-right: 2rem;

      &__title {
        padding-bottom: 0.875rem;
      }

      :deep(.button) {
        position: absolute !important;
        top: calc(1.125rem - 0.4rem) !important;
        right: 0.75rem !important;
        width: 2rem;
        height: 2rem;
        color: var(--action-color);
      }
    }

    &__main :deep(ul) {
      margin: 0;
    }

    &__actions__action {
      cursor: pointer;
    }
  }

  &:not(.notification--style-horizontal) {
    --width: 26.5rem;

    .notification__body__header__title {
      padding-bottom: 0.25rem;
    }
  }

  &--style-horizontal {
    min-height: 4.5rem;

    .notification__aside {
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 1.125rem 0.625rem;
    }

    .notification__body__header {
      padding: 0;

      &__close {
        top: calc(2.25rem - 1.0625rem) !important;
      }
    }

    .notification__body,
    .notification__body__main {
      display: flex;
      align-items: center;
    }

    .notification__body__main {
      padding: 0 3rem 0 0 !important;
    }

    .notification__body__actions {
      padding: 0 1.5rem !important;
    }
  }

  &:not(.notification--status-visible) {
    opacity: 0;
    pointer-events: none;

    &.notification--status-inline {
      display: none;
    }
  }

  &:not(.notification--status-inline) {
    position: fixed;
    z-index: 99999;
    bottom: 4.5rem;
    left: calc(var(--menu-bar-width) + 1.5rem);
    width: var(--width);

    .notification__body__actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-top: 1.5rem;
      padding-bottom: 0.375rem;
      color: var(--action-color);
      gap: 1rem;
    }

    &.notification--type-info {
      --border-color: var(--notification-border-color-info);
      --body-background-color: var(--notification-body-background-color-info);
      --body-color: var(--notification-body-color-info);
      --background-color: var(--notification-background-color-info);
      --icon-color: var(--notification-icon-color-info);
      --action-color: var(--notification-action-color-info);
    }

    &.notification--type-error {
      --border-color: var(--notification-border-color-error);
      --body-background-color: var(--notification-body-background-color-error);
      --body-color: var(--notification-body-color-error);
      --background-color: var(--notification-background-color-error);
      --icon-color: var(--notification-icon-color-error);
      --action-color: var(--notification-action-color-error);
    }

    &.notification--type-success {
      --border-color: var(--notification-border-color-success);
      --body-background-color: var(--notification-body-background-color-success);
      --body-color: var(--notification-body-color-success);
      --background-color: var(--notification-background-color-success);
      --icon-color: var(--notification-icon-color-success);
      --action-color: var(--notification-action-color-success);
    }

    &.notification--type-warning {
      --border-color: var(--notification-border-color-warning);
      --body-background-color: var(--notification-body-background-color-warning);
      --body-color: var(--notification-body-color-warning);
      --background-color: var(--notification-background-color-warning);
      --icon-color: var(--notification-icon-color-warning);
      --action-color: var(--notification-action-color-warning);
    }
  }

  &--status-inline,
  &--style-light {
    .notification__body {
      padding-left: 0.5rem;
    }

    .notification__body__actions {
      display: flex;
      flex-direction: column;

      &__action {
        margin-top: 0.5rem;
      }
    }
  }

  &--status-inline {
    .notification__aside {
      align-items: flex-start;
    }

    &.notification--type-info {
      --border-color: var(--notification-border-color-info-inline);
      --body-background-color: var(--notification-body-background-color-info-inline);
      --body-color: var(--notification-body-color-info-inline);
      --background-color: var(--notification-background-color-info-inline);
      --icon-color: var(--notification-icon-color-info-inline);
    }

    &.notification--type-error {
      --border-color: var(--notification-border-color-error-inline);
      --body-background-color: var(--notification-body-background-color-error-inline);
      --body-color: var(--notification-body-color-error-inline);
      --background-color: var(--notification-background-color-error-inline);
      --icon-color: var(--notification-icon-color-error-inline);
    }

    &.notification--type-success {
      --border-color: var(--notification-border-color-success-inline);
      --body-background-color: var(--notification-body-background-color-success-inline);
      --body-color: var(--notification-body-color-success-inline);
      --background-color: var(--notification-background-color-success-inline);
      --icon-color: var(--notification-icon-color-success-inline);
    }

    &.notification--type-warning {
      --border-color: var(--notification-border-color-warning-inline);
      --body-background-color: var(--notification-body-background-color-warning-inline);
      --body-color: var(--notification-body-color-warning-inline);
      --background-color: var(--notification-background-color-warning-inline);
      --icon-color: var(--notification-icon-color-warning-inline);
    }
  }

  &--style-light {
    border: 0;
    box-shadow: none;

    .notification__aside,
    .notification__body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .notification__aside {
      width: auto;
      min-width: unset;
      padding-left: 0;
    }

    &.notification--type-info {
      --border-color: transparent;
      --body-background-color: transparent;
      --body-color: var(--notification-body-color-info-inline);
      --background-color: transparent;
      --icon-color: var(--notification-icon-color-info-inline);
    }

    &.notification--type-error {
      --border-color: transparent;
      --body-background-color: transparent;
      --body-color: var(--notification-body-color-error-inline);
      --background-color: transparent;
      --icon-color: var(--notification-icon-color-error-inline);
    }

    &.notification--type-success {
      --border-color: transparent;
      --body-background-color: transparent;
      --body-color: var(--notification-body-color-success-inline);
      --background-color: transparent;
      --icon-color: var(--notification-icon-color-success-inline);
    }

    &.notification--type-warning {
      --border-color: transparent;
      --body-background-color: transparent;
      --body-color: var(--notification-body-color-warning-inline);
      --background-color: transparent;
      --icon-color: var(--notification-icon-color-warning-inline);
    }
  }

  &--style-no-header:not(.notification--style-no-close-btn) {
    .notification__body__main {
      padding-right: 2rem;
    }
  }
}
</style>
