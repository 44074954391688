var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'loader',
    {
      'loader--absolute': _vm.absolute,
      'loader--fixed': _vm.fixed,
      'loader--reduced': _vm.reduced,
      'loader--state-loading': _vm.loading,
    },
  ]},[_c('div',[(_vm.reduced || _vm.userBrowser === 'safari')?_c('span',{class:[
        'css-loader',
        {
          'css-loader--light': _vm.theme === 'light',
          'css-loader--dark': _vm.theme === 'dark',
        },
      ]}):_c('video',{attrs:{"autoplay":"","loop":"","muted":"","playsinline":"","width":"128","height":"128","aria-hidden":"true"},domProps:{"muted":true}},[_c('source',{attrs:{"src":`/videos/loader/${_vm.theme}.webm`,"type":"video/webm"}}),_vm._v("\n      Your browser does not support the video tag.\n    ")])]),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }