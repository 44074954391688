import {
  optionsModelQuery,
  optionsCarQuery,
  optionsMotorbikeQuery,
} from '~/queries/optionsFilterQuery'
import modelsFilterQuery from '~/queries/modelsFilterQuery'

export const state = () => ({
  lowestInstalmentPrice: 0,
  highestInstalmentPrice: 0,
  lowestPrice: 0,
  highestPrice: 0,
  bodyworks: null,
  useCases: null,
  environmentalLabels: null,
  makes: null,
  models: null,
})

export const getters = {
  getLowestInstallmentPrice(state) {
    return state.lowestInstalmentPrice
  },
  getHighestInstallmentPrice(state) {
    return state.highestInstalmentPrice
  },
  getLowestPrice(state) {
    return state.lowestPrice
  },
  getHighestPrice(state) {
    return state.highestPrice
  },
  getBodyworks(state) {
    return state.bodyworks
  },
  getUseCases(state) {
    return state.useCases
  },
  getEnvironmentalLabels(state) {
    return state.environmentalLabels
  },
  getMakes(state) {
    return state.makes
  },
  getModels(state) {
    return state.models
  },
}

export const mutations = {
  setLowestInstalmentPrice(state, lowestInstalmentPrice) {
    state.lowestInstalmentPrice = lowestInstalmentPrice
  },
  setHighestInstalmentPrice(state, highestInstalmentPrice) {
    state.highestInstalmentPrice = highestInstalmentPrice
  },
  setLowestPrice(state, lowestPrice) {
    state.lowestPrice = lowestPrice
  },
  setHighestPrice(state, highestPrice) {
    state.highestPrice = highestPrice
  },
  setBodyworks(state, bodyworks) {
    state.bodyworks = bodyworks
  },
  setUseCases(state, useCases) {
    state.useCases = useCases
  },
  setEnvironmentalLabels(state, environmentalLabels) {
    if (environmentalLabels?.length) {
      const sorted = ['0', 'eco', 'c', 'b']
      environmentalLabels.sort((a, b) => sorted.indexOf(a) - sorted.indexOf(b))
    }
    state.environmentalLabels = environmentalLabels
  },
  setMakes(state, makes) {
    state.makes = makes
  },
  setModels(state, models) {
    state.models = models
  },
}

const storageKey = (vehicleType, category) =>
  `catalog-options-${vehicleType.toLowerCase()}-${category.toLowerCase()}`

const setSessionStorage = (key, value) => {
  if (!process.client) return undefined

  sessionStorage.setItem(key, JSON.stringify(value))
}

const getSessionStorage = (key) => {
  if (!process.client) return undefined

  const value = sessionStorage.getItem(key)
  return value ? JSON.parse(value) : null
}

export const actions = {
  async checkCatalogOptions({ commit, rootGetters }, data = {}) {
    const client = this.app.apolloProvider.defaultClient

    let vehicleType = data.vehicleType
    let category = data.category
    let query
    let vnPrice
    let categoryIn
    let move

    if (!vehicleType || !category) {
      switch (this.app.context.route.name.split('__')[0]) {
        case 'motorbikes':
        case 'motorbike-make':
          query = optionsMotorbikeQuery
          vnPrice = true
          categoryIn = 'VN'
          move = false
          vehicleType = 'motorbike'
          category = 'VN'
          break
        case 'astara-selection-cars':
          query = optionsCarQuery
          vnPrice = false
          categoryIn = 'VO'
          move = true
          vehicleType = 'car'
          category = 'VO'
          break
        case 'cars-vo':
          query = optionsCarQuery
          vnPrice = false
          categoryIn = 'VO'
          move = false
          vehicleType = 'car'
          category = 'VO'
          break
        case 'cars-vn':
          query = optionsModelQuery
          vnPrice = true
          categoryIn = 'VN'
          move = false
          vehicleType = 'car'
          category = 'VN'
          break
        case 'cars-km0':
          query = optionsCarQuery
          vnPrice = false
          categoryIn = 'Km0'
          move = false
          vehicleType = 'car'
          category = 'Km0'
          break
      }
    } else if (vehicleType === 'car') {
      switch (category.toLowerCase()) {
        case 'move':
          query = optionsCarQuery
          vnPrice = false
          categoryIn = 'VO'
          move = true
          break
        case 'vo':
          query = optionsCarQuery
          vnPrice = false
          categoryIn = 'VO'
          move = false
          break
        case 'vn':
          query = optionsModelQuery
          vnPrice = true
          categoryIn = 'VN'
          move = false
          break
        case 'km0':
          query = optionsCarQuery
          vnPrice = false
          categoryIn = 'Km0'
          move = false
          break
      }
    } else if (vehicleType === 'motorbike') {
      query = optionsMotorbikeQuery
      vnPrice = true
      categoryIn = 'VN'
      move = false
    }

    const sessionKey = storageKey(vehicleType, category)
    const cachedData = getSessionStorage(sessionKey)

    if (cachedData && !!Object.keys(cachedData)) {
      commit('setHighestInstalmentPrice', cachedData.highestInstalmentPrice)
      commit('setLowestInstalmentPrice', cachedData.lowestInstalmentPrice)
      commit('setHighestPrice', cachedData.highestPrice)
      commit('setLowestPrice', cachedData.lowestPrice)
      commit('setBodyworks', cachedData.bodyworks)
      commit('setUseCases', cachedData.useCases)
      commit('setEnvironmentalLabels', cachedData.environmentalLabels)
      commit('setMakes', cachedData.makes)

      return cachedData
    }

    const variables = {
      financialEntityName: 'Nemuru',
      categoryIn,
      category: categoryIn,
      language: rootGetters['index/languageIso'] || 'es',
      ...(move && { dealerUidIn: process.env.uidMoveDealer }),
    }

    if (client) {
      try {
        const {
          lowestInstalmentPrice,
          highestInstalmentPrice,
          lowestPrice,
          highestPrice,
          bodyworks,
          useCases,
          environmentalLabels,
          makes,
        } = await client.query({ query, variables }).then(({ data }) => {
          return {
            lowestInstalmentPrice:
              data.lowestPrice.nodes.length &&
              data.lowestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing'] &&
              data.lowestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing'].financing
                .length
                ? data.lowestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing']
                    .financing[0].installment_price
                : 0,
            highestInstalmentPrice:
              data.highestPrice.nodes.length &&
              data.highestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing'] &&
              data.highestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing']
                .financing.length
                ? data.highestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing']
                    .financing[0].installment_price
                : 0,
            lowestPrice:
              data.lowestPrice.nodes.length &&
              data.lowestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing'] &&
              data.lowestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing']
                .discounted_price
                ? data.lowestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing']
                    .discounted_price
                : 0,
            highestPrice:
              data.highestPrice.nodes.length &&
              data.highestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing'] &&
              data.highestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing']
                .discounted_price
                ? data.highestPrice.nodes[0][vnPrice ? 'salesVnCheapestPrice' : 'salesPricing']
                    .discounted_price
                : 0,
            bodyworks: data.bodyworks,
            useCases: data.useCases,
            environmentalLabels: data.environmentalLabels,
            makes: data.makes.nodes.map((node) => {
              return [
                node.slug,
                node.name,
                {
                  metaTitle: node.metaTitle,
                  metaDescription: node.metaDescription,
                  title: node?.title,
                  description: node?.description,
                  characteristics: node.characteristic?.description,
                },
              ]
            }),
          }
        })

        const storedData = {
          vehicleType,
          category,
          highestInstalmentPrice,
          lowestInstalmentPrice,
          highestPrice,
          lowestPrice,
          bodyworks,
          useCases,
          environmentalLabels,
          makes,
        }

        setSessionStorage(sessionKey, storedData)

        commit('setHighestInstalmentPrice', highestInstalmentPrice)
        commit('setLowestInstalmentPrice', lowestInstalmentPrice)
        commit('setHighestPrice', highestPrice)
        commit('setLowestPrice', lowestPrice)
        commit('setBodyworks', bodyworks)
        commit('setUseCases', useCases)
        commit('setEnvironmentalLabels', environmentalLabels)
        commit('setMakes', makes)

        return storedData
      } catch {
        commit('setHighestInstalmentPrice', [])
        commit('setLowestInstalmentPrice', [])
        commit('setHighestPrice', [])
        commit('setLowestPrice', [])
        commit('setBodyworks', [])
        commit('setUseCases', [])
        commit('setEnvironmentalLabels', [])
        commit('setMakes', [])
      }
    }
  },
  async setModelsByMake({ commit, rootGetters }, makeSlug) {
    try {
      const client = this.app.apolloProvider.defaultClient
      let vehicleTypeLike
      let categoryIn
      let move
      switch (this.app.context.route.name.split('__')[0]) {
        case 'motorbikes':
        case 'motorbike-make':
          vehicleTypeLike = 'motorbike'
          categoryIn = 'VN'
          move = false
          break
        case 'astara-selection-cars':
          vehicleTypeLike = 'car'
          categoryIn = 'VO'
          move = true
          break
        case 'cars-vo':
          vehicleTypeLike = 'car'
          categoryIn = 'VO'
          move = false
          break
        case 'cars-vn':
          vehicleTypeLike = 'car'
          categoryIn = 'VN'
          move = false
          break
        case 'cars-km0':
          vehicleTypeLike = 'car'
          categoryIn = 'Km0'
          move = false
          break
      }

      const variables = {
        vehicleTypeLike,
        categoryIn,
        makeSlug,
        language: rootGetters['index/languageIso'] || 'es',
        ...(move && { dealerUidIn: process.env.uidMoveDealer }),
      }

      await client.query({ query: modelsFilterQuery, variables }).then(({ data }) => {
        const models = data.models.nodes.map((node) => {
          return [
            node.slug.replace(/-\d{4}(?=\s*$)/, ''),
            node.name.replace(/-\d{4}(?=\s*$)/, ''),
            {
              metaTitle: node.metaTitle,
              metaDescription: node.metaDescription,
              title: node?.title,
              description: node?.description,
              characteristics: node.characteristic?.description,
            },
          ]
        })
        commit('setModels', models)
      })
    } catch (error) {
      commit('setModels', null)
    }
  },
}
