var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'tag',
    `tag--${_vm.theme}`,
    {
      'tag--disabled': _vm.disabled,
      'tag--small': _vm.small,
      'tag--large': _vm.large,
      'tag--link': _vm.to,
      'tag--has-delete-action': _vm.showDeleteAction,
    },
  ]},[(_vm.to)?_c('NuxtLink',{staticClass:"tag__link",attrs:{"to":_vm.localePath(_vm.to)}}):_vm._e(),_vm._v(" "),(_vm.icon)?_c('Icon',{attrs:{"name":_vm.icon,"size":"1.25rem"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"tag__label f-size-14 f-500"},[(_vm.label)?[_vm._v(_vm._s(_vm.label))]:[_vm._t("default")]],2),_vm._v(" "),(_vm.showDeleteAction)?_c('div',{staticClass:"tag__delete-action",on:{"click":function($event){return _vm.$emit('delete')}}},[_c('Icon',{attrs:{"name":"delete","size":"1.5rem"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }