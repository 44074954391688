var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'submenu',
    'f-size-14',
    {
      'submenu--grow-right': _vm.grow === 'right',
      'submenu--open': _vm.open,
    },
  ],on:{"mouseout":_vm.onDropdownMouseout}},[_c('div',{staticClass:"submenu__header",on:{"mouseover":_vm.onDropdownMouseover,"click":_vm.onHeaderClick}},[_c('div',{staticClass:"submenu__header__title"},[_vm._t("header")],2),_vm._v(" "),_c('Icon',{attrs:{"name":_vm.open ? 'expand_less' : 'expand_more',"size":"1.5rem"}})],1),_vm._v(" "),_c('div',{staticClass:"submenu__dropdown",attrs:{"data-cy":"submenu-dropdown"},on:{"mouseover":_vm.onDropdownMouseover}},[_c('div',{staticClass:"submenu__dropdown__menu",on:{"click":_vm.onMenuClick}},[_vm._t("menu")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }