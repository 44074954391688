// This middleware makes sure that a view can only be accessed if the user
// is logged in. Otherwise, the user will be asked to login before returning
// to the page that initially we wanted to go to.

export default function ({ app, route, redirect, store }) {
  const loggedIn = store.getters['auth/loggedIn']

  if (!loggedIn) {
    const redirectTo = encodeURIComponent(route.fullPath)
    redirect(app.localePath('sign-in') + `?return=${redirectTo}`)
  }
}
