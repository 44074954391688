// Helpers
import { getBaseUrl, getFullPageUrl } from '@/helpers/urls'

export default ({ app, route }, inject) => {
  // Dependencies
  const i18n = app.i18n

  // Methods
  const getCanonicals = (currentRoute) => {
    const { path, query } = currentRoute
    let queryString = ''

    // Iterate over each query parameter
    for (const key in query) {
      // Include 'page' only if its value is not '1', ignore all other params
      if (key === 'page' && query[key] !== '1') {
        queryString += `${queryString ? '&' : '?'}${key}=${query[key]}`
      }
    }

    // Construct the full URL
    return `${getBaseUrl()}${path}${queryString}`
  }

  const generatePageMetasFromKey = (key, visible = true, type, image) => {
    const title = i18n.te(`${key}.head.title`) ? i18n.t(`${key}.head.title`) : undefined
    const metaTitle = i18n.te(`${key}.head.title`) ? i18n.t(`${key}.head.meta.title`) : undefined
    const metaDescription = i18n.te(`${key}.head.title`)
      ? i18n.t(`${key}.head.meta.description`)
      : undefined

    return generatePageMetas(title, metaTitle, metaDescription, visible, type, image)
  }

  const generatePageMetas = (
    title,
    metaTitle,
    metaDescription,
    visible = true,
    type = 'website',
    image,
  ) => {
    const metas = {
      meta: [
        {
          hid: 'og:url',
          property: 'og:url',
          content: getFullPageUrl(route, true, false),
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: type,
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: i18n.locale,
        },
        {
          hid: 'twitter:domain',
          property: 'twitter:domain',
          content: 'https://astarastore.com/',
        },
      ],
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: getCanonicals(route),
        },
      ],
    }

    const actualImage = image || `${getBaseUrl()}/images/og.png`
    if (actualImage) {
      ;['og:image', 'twitter:image'].forEach((entry) => {
        metas.meta.push({
          hid: entry,
          name: entry,
          content: actualImage,
        })
      })

      metas.link.push({
        hid: 'image_src',
        rel: 'image_src',
        href: actualImage,
      })

      metas.meta.push({
        hid: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image',
      })
    }

    if (title || metaTitle) {
      metas.title = title || metaTitle
    }

    if (metaTitle) {
      ;['title', 'og:title', 'twitter:title'].forEach((entry) => {
        metas.meta.push({
          hid: entry,
          name: entry,
          content: metaTitle,
        })
      })
    }

    if (metaDescription) {
      ;['description', 'og:description', 'twitter:description'].forEach((entry) => {
        metas.meta.push({
          hid: entry,
          name: entry,
          content: metaDescription,
        })
      })
    }

    if (!visible) {
      metas.meta.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow',
      })
    }

    return metas
  }

  inject('generatePageMetasFromKey', generatePageMetasFromKey)
  inject('generatePageMetas', generatePageMetas)
}
