<template>
  <div class="warranty-badge container-flex container-flex--inline s-gap-8 f-primary">
    <div class="warranty-badge__icon">
      <Icon name="workspace_premium" size="1.5rem" />
    </div>
    <p class="f-size-18 f-500 s-m-0">
      {{ $t(`warranty_badge.${type}`) }}
    </p>
  </div>
</template>

<script>
// Components
import Icon from '@/components/Icon.vue'

// Other
const TYPES = ['manufacturer', 'astara_one_year']

export default {
  name: 'PartialWarrantyBadge',
  components: {
    Icon,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => TYPES.includes(value),
    },
  },
}
</script>

<style lang="scss" scoped>
.warranty-badge__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.125rem;
  background-color: $c-neutral-025;
}
</style>
