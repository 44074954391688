import Vue from 'vue'
import Prismic from '@prismicio/client'
import PrismicDOM from 'prismic-dom'

import linkResolver from '../link-resolver.js'
import htmlSerializer from '../html-serializer.js'

export default async (context, inject) => {
  const { req, route, res, query, redirect, nuxtState, base } = context
  let options = {}

  // Pass through server requests, primarily for preview
  if (process.server) {
    options.req = req
  }

  let api = {}
  try {
    api = Prismic.client('https://astarastore.cdn.prismic.io/api/v2', Object.assign({}, options,  {"routes":[{"type":"homepage","path":"/news"},{"type":"categorypage","path":"/news/:category","resolvers":{"category":"category"}},{"type":"article","path":"/news/:category/:uid","resolvers":{"category":"category"}}]}))
  } catch (error) {
    console.error(error)
    console.error("Failed to init Prismic API, preventing app fatal error.")
  }

  let prismic = new Vue({
    computed: {
      api() {
        return api
      },
      apiEndpoint() {
        return 'https://astarastore.cdn.prismic.io/api/v2'
      },
      predicate() {
        return Prismic.Predicates
      },
      predicates() {
        return Prismic.Predicates
      },
      dom() {
        return PrismicDOM
      }
    },
    methods: {
      asHTML(richText) {
        return this.asHtml(richText)
      },
      asHtml(richText) {
        if (richText) {
          return PrismicDOM.RichText.asHtml(
            richText,
            linkResolver,
            htmlSerializer
          )
        }
      },
      asText(richText) {
        if (richText) {
          return PrismicDOM.RichText.asText(richText)
        }
      },
      asLink(link) {
        if (link) {
          return PrismicDOM.Link.url(
            link,
            linkResolver
          )
        }
      },
      asDate(date) {
        if (date) {
          return PrismicDOM.Date(date)
        }
      },

      ...(linkResolver && { linkResolver }),
      ...(htmlSerializer && { htmlSerializer })
    }
  })

  inject('prismic', prismic)
  context.$prismic = prismic
}
