/* eslint-disable no-console */
export default ({ app }, inject) => {
  const logMethods = {
    warn: console.warn,
    info: console.log,
    error: console.error,
  }

  const logger = (msg, feedback = '', type = 'error') => {
    if (!process.env.isProduction) {
      const logMethod = logMethods[type] || console.error
      const formattedMessage = `${type.charAt(0).toUpperCase() + type.slice(1)}! ${msg}${
        feedback ? ':' : ''
      }`

      logMethod(formattedMessage, feedback)
    }
  }

  inject('logger', logger)
}
