const numberFormats = {
  en: {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    // useGrouping: true,
  },
  es: {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    // useGrouping: true,
  },
}

export const toCurrency = (data) => {
  let dataValue
  if (!Object.prototype.hasOwnProperty.call(data, 'value')) {
    dataValue = data
  } else {
    dataValue = data.value
  }

  let value = Number(dataValue)

  if (typeof value !== 'number' || Number.isNaN(value) || !Number.isFinite(value)) {
    return value
  }

  if (!data?.whole) {
    value *= 0.01
  }

  let formatter
  switch (data?.lang?.toLowerCase()) {
    case 'en':
      formatter = new Intl.NumberFormat('en-UK', numberFormats.en)
      break
    default:
      formatter = new Intl.NumberFormat('es-ES', numberFormats.es)
      break
  }

  // We multiply it by 0.01 because the prices returned by
  // the API are in cents
  return formatter.format(value).replace(/[\u202F\u00A0]/, '')
}

export default {
  toCurrency,
}
