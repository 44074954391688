var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'favourite-wrapper',
    {
      'favourite-wrapper--fixed': _vm.showModalsSign,
    },
  ]},[_c('ModalsSign',{staticClass:"sign-modal-form",attrs:{"open":_vm.showModalsSign,"type":"favourites"},on:{"close":() => (_vm.showModalsSign = false),"submit":() => _vm.toggle()}}),_vm._v(" "),_c('button',{class:[
      'favourite-action',
      { 'favourite-action--disabled': _vm.loading || !_vm.favouriteMarkableId || !_vm.favouriteMarkableType },
    ],on:{"!click":function($event){return (() => _vm.toggle()).apply(null, arguments)}}},[_c('Icon',{attrs:{"name":_vm.iconName,"size":"1.25rem"}}),_vm._v(" "),(_vm.showLabel)?_c('span',{staticClass:"favourite-action__label"},[_vm._v("\n      "+_vm._s(_vm.$t(`pages.favourites.cta.${_vm.status ? 'remove' : 'add'}`))+"\n    ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }