<template>
  <div
    :class="[
      'tag',
      `tag--${theme}`,
      {
        'tag--disabled': disabled,
        'tag--small': small,
        'tag--large': large,
        'tag--link': to,
        'tag--has-delete-action': showDeleteAction,
      },
    ]"
  >
    <NuxtLink v-if="to" class="tag__link" :to="localePath(to)" />

    <Icon v-if="icon" :name="icon" size="1.25rem" />

    <span class="tag__label f-size-14 f-500">
      <template v-if="label">{{ label }}</template>
      <template v-else><slot /></template>
    </span>

    <div v-if="showDeleteAction" class="tag__delete-action" @click="$emit('delete')">
      <Icon name="delete" size="1.5rem" />
    </div>
  </div>
</template>

<script>
// Components
import Icon from '@/components/Icon'

// Other
const THEMES = [
  'primary',
  'secondary',
  'base-dark',
  'base',
  'base-light',
  'info',
  'error',
  'success',
  'warning',
  'neutral',
  'success-light',
  'warning-light',
  'info-light',
]

export default {
  name: 'Tag',
  components: { Icon },
  props: {
    showDeleteAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      validator: (value) => THEMES.includes(value),
      default: 'common',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
      required: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: undefined,
    },
    label: {
      type: [String, Number, Boolean],
      default: undefined,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.tag {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.875rem;
  border-radius: 0.875rem;
  color: $c-primary-900;

  :deep(.icon),
  span {
    pointer-events: none;
  }

  &__label::first-letter {
    text-transform: uppercase;
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__delete-action {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  &--link {
    position: relative;
    cursor: pointer;
  }

  &--small {
    padding: 0.25rem 0.5rem;
  }

  &--has-delete-action {
    padding-top: 0.375rem;
    padding-right: 0.5rem;
    padding-bottom: 0.375rem;
  }

  &--primary {
    background-color: $c-support-01-500;
  }

  &--secondary {
    background-color: $c-support-02-500;
  }

  &--base-dark {
    background-color: $c-primary-900;
    color: $c-white;
  }

  &--base {
    background-color: $c-primary-700;
    color: $c-white;
  }

  &--base-light {
    background-color: $c-primary-500;
    color: $c-white;
  }

  &--info {
    background-color: $c-info;
  }

  &--error {
    background-color: $c-error;
  }

  &--success {
    background-color: $c-success;
  }

  &--warning {
    background-color: $c-warning;
  }

  &--neutral {
    background-color: $c-neutral-500;
    color: $c-neutral-900;
  }

  &--success-light {
    background-color: #b5e2d1;
  }

  &--warning-light {
    background-color: #f5beb8;
  }

  &--info-light {
    background-color: $c-product-04;
  }
}
</style>
