export const state = () => ({
  notifications: [],
})

export const getters = {
  notifications: (state) => state.notifications,
}

export const mutations = {
  add(state, notification) {
    state.notifications.push(notification)
  },
  close(state, id) {
    state.notifications = state.notifications.filter((notification) => notification.id !== id)
  },
}
