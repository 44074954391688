<template>
  <div class="internal-error">
    <div class="container container--pv-navbar container-flex">
      <div class="error-title">
        <h1 class="f-500" data-cy="error-title">
          {{ $t('purchase.request_finance_token.title') }}
        </h1>
        <span class="f-size-18 f-500">
          {{ $t('purchase.request_finance_token.description') }}
        </span>
      </div>
      <Button
        class="error-button"
        type-theme="primary-light"
        size="large"
        data-cy="error-button"
        @click="sendNewLink()"
      >
        {{ $t('purchase.request_finance_token.cta') }}
      </Button>
      <div class="error-info">
        <p class="f-size-18 f-500 text-secondary">
          {{ $t('purchase.request_finance_token.info[0]') }}
        </p>
        <p class="f-size-18 f-500 text-secondary">
          {{ $t('purchase.request_finance_token.info[1]') }}
        </p>
      </div>
      <div class="error-contact-info" data-cy="error-contact-info">
        <div class="as-link as-link--with-icon">
          <Icon name="chat" />
          <span>
            {{ $t('pages.error.default.chat') }}
          </span>
        </div>

        <a class="as-link as-link--with-icon" href="mailto:help@astarastore.com">
          <Icon name="email" />
          <span>
            {{ $t('pages.error.default.email') }}
          </span>
        </a>

        <a class="as-link as-link--with-icon" :href="`tel:${contactPhone}`">
          <Icon name="phone" />
          <span>
            {{ contactPhone }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import Button from '@/components/Button'
import Icon from '@/components/Icon'

export default {
  components: { Button, Icon },
  data() {
    return {
      contactPhone: process.env.contactPhone,
    }
  },
  methods: {
    async sendNewLink() {
      const response = await this.$store.dispatch(
        'purchase/sendAutologinUrl',
        this.$route.params.uid,
      )
      if (response.status === 'ok') {
        this.$displayNotification(
          'success',
          this.$t('purchase.request_finance_token.notification.title'),
          this.$t('purchase.request_finance_token.notification.message'),
          [],
          false,
        )
        this.$router.push(this.localePath('index'))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.internal-error {
  min-height: 100vh;
  background-image: url('/images/error-bg.webp');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @include size-s {
    background-image: url('/images/error-bg-mobile.webp');
    text-align: left;
  }

  .container {
    display: flex;
    flex-direction: column;

    h1 {
      margin: 1.5rem 0 0.5rem;
      color: $highlight-1;
      font-size: $font-size-5xl;
      @include size-m {
        font-size: $font-size-3xl;
      }
      @include size-xs {
        font-size: $font-size-2xl;
      }
    }

    p {
      margin: 0;
    }

    .error-title {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .error-button {
      margin: 2.5rem 0 4rem;

      @include size-xs {
        margin-top: 1.5rem;
      }
    }

    .error-contact-info {
      display: inline-flex;
      align-items: center;
      margin-top: 0.75rem;

      @include size-xs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1.5rem;
      }

      .as-link {
        margin: 0 0.375rem 0 0;

        &:not(:first-child) {
          &::before {
            content: ' | ';
            margin: 0 1rem;
            color: $text-secondary;

            @include size-xs {
              display: none;
            }
          }

          @include size-xs {
            margin-top: 1.5rem;
          }
        }

        @include size-xs {
          flex-direction: row;
        }
      }
    }
  }
  ::v-deep .notification__body {
    padding-left: 1.5rem;
    text-align: left;
  }
}
</style>
