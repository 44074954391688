export const state = () => ({
  config: null,
  order: null,
  payment: null,
  vehicleFinancingOptions: null,
  customerFinancingOptions: null,
  downPayment: null,
})

export const getters = {
  order: (state) => state.order,
  payment: (state) => state.payment,
  config: (state) => state.config,
  vehicleFinancingOptions: (state) => state.vehicleFinancingOptions,
  customerFinancingOptions: (state) => state.customerFinancingOptions,
  downPayment: (state) => state.downPayment,
}

export const mutations = {
  setConfig: (state, payload) => (state.config = { ...state.config, ...payload }),
  setOrder: (state, payload) => (state.order = payload),
  setPayment: (state, payload) => (state.payment = payload),
  setVehicleFinancingOptions: (state, payload) => (state.vehicleFinancingOptions = payload),
  setCustomerFinancingOptions: (state, payload) => (state.customerFinancingOptions = payload),
  setDownPayment: (state, payload) => (state.downPayment = payload),
}

export const actions = {
  /*
   * Create a brand new order based in a specific vehicle config that will be bought.
   * - config: the slug for the vehicle config that will be purchased.
   */
  async createOrder({ commit }, { config }) {
    const order = await this.$purchaseService.createOrder(config.slug)
    commit('setOrder', order.uid)
    commit('setConfig', {
      make: config.make.name,
      model: config.model.name,
      version: config.version.name,
      thumbnail: config.thumbnail.file,
      price: config.price,
    })
    return order
  },

  /*
   * Create a new purchase attempt for a specific order.
   * - order: the uid of the order that will own the purchase attempt.
   * - paymentMethod: the specific payment method that will be used.
   */
  async createPurchase({ commit }, { order, paymentMethod }) {
    const purchase = await this.$purchaseService.createPayment(order, paymentMethod)
    commit('setPayment', purchase.uid)
    return purchase
  },

  /*
   * Restore from the API information of a previous purchase (for instance, after a page reload).
   * - order: the uid of the order that will be retrieved.
   * - payment: the uid of the payment attempt that will be retrieved.
   */
  async recallPurchase({ commit }, { order }) {
    const orderData = await this.$purchaseService.getOrder(order)
    commit('setOrder', order)
    commit('setConfig', {
      make: orderData.config.make,
      model: orderData.config.model,
      version: orderData.config.version,
      thumbnail: orderData.config.thumbnailUrl,
      price: orderData.config.price.cents,
    })
  },

  async calculateFinancingOptions({ commit }, { financingOptions, initialValues }) {
    try {
      let downPayment
      if (initialValues === undefined) {
        initialValues = false
      }
      if (initialValues) {
        downPayment = financingOptions.downpayment ? financingOptions.downpayment : null
      } else {
        downPayment = financingOptions.downpayment ? financingOptions.downpayment * 100 : null
      }

      const calculateInfo = await this.$purchaseService
        .calculate({
          amount: financingOptions.amount,
          installments: financingOptions.installments,
          interest_rate: financingOptions.interest_rate,
          downpayment: downPayment,
          currency: 'EUR',
        })
        .then((res) => {
          return res.data
        })
      commit('setCustomerFinancingOptions', calculateInfo)
      commit('setDownPayment', downPayment !== null ? downPayment : 0)
    } catch (error) {
      return error
    }
  },

  setCustomerFinancingOptions({ commit }, customerFinancingOptions) {
    commit('setCustomerFinancingOptions', customerFinancingOptions)
  },

  setDownPayment({ commit }, downPayment) {
    commit('setDownPayment', downPayment)
  },

  removeFinancingOptions({ commit }) {
    commit('setCustomerFinancingOptions', null)
    commit('setDownPayment', null)
  },

  async sendAutologinUrl({ commit }, params) {
    return await this.$purchaseService.sendAutologinUrl(params)
  },
}
