import { gql } from 'graphql-tag'
const versionsMotorbikeQuery = gql`
  query versionsMotorbikeQuery(
    $makeSlug: String
    $modelName: String
    $perPage: Int
    $sortByFinancingPrice: String
    $sortByRelevance: Boolean
    $drivingLicenses: [String!]
    $environmentalLabels: [String!]
    $bodyworkIn: [String!]
    $page: Int
    $fuelTypeIn: [String!]
    $powertrainTypeIn: [String!]
    $useCasesIn: [String!]
    $transmissionTypeIn: [String!]
    $discountedPriceCentsBetween: [Int!]
    $financialEntityName: String
    $discountedPriceCentsGte: Int
    $discountedPriceCentsLte: Int
    $instalmentPriceCentsBetween: [Int!]
    $instalmentPriceCentsGte: Int
    $instalmentPriceCentsLte: Int
    $cubicCapacityGte: Int
    $cubicCapacityLte: Int
    $batteryMaxPowerGte: Int
    $batteryMaxPowerLte: Int
  ) {
    vehicles: versions(
      make: { slugLike: $makeSlug }
      model: { nameLike: $modelName, vehicleTypeLike: "motorbike" }
      perPage: $perPage
      sortByFinancingPrice: $sortByFinancingPrice
      sortByRelevance: $sortByRelevance
      drivingLicenceIn: $drivingLicenses
      bodyworkIn: $bodyworkIn
      environmentalLabelIn: $environmentalLabels
      specs: {
        fuelTypeIn: $fuelTypeIn
        transmissionTypeIn: $transmissionTypeIn
        powertrainTypeIn: $powertrainTypeIn
        cubicCapacityGte: $cubicCapacityGte
        cubicCapacityLte: $cubicCapacityLte
        batteryMaxPowerGte: $batteryMaxPowerGte
        batteryMaxPowerLte: $batteryMaxPowerLte
      }
      page: $page
      useCasesIn: $useCasesIn
      discountedPriceCentsBetween: $discountedPriceCentsBetween
      discountedPriceCentsGte: $discountedPriceCentsGte
      discountedPriceCentsLte: $discountedPriceCentsLte
      instalmentPriceCentsBetween: $instalmentPriceCentsBetween
      instalmentPriceCentsGte: $instalmentPriceCentsGte
      instalmentPriceCentsLte: $instalmentPriceCentsLte
      vehicleConfigs: { includeNoPublishable: false }
      includeNoPublishable: false
    ) {
      nodes {
        slug
        name
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
        drivingLicence
        specs
        bodywork
        environmentalLabel
        hero {
          file
        }
        useCases
        publishableUnits(category: "vn")
        model {
          name
          slug
          vehicleType
        }
        make {
          name
          slug
        }
        discounts {
          typeId
          startDate
          percentage
          name
          endDate
          amountCurrency
          amountCents
        }
        vehicleConfigs(sortByPrice: "asc") {
          specs
          publishable
          hero {
            file
          }
          colors {
            name
            surfaceType
          }
        }
      }
      pagesCount
      nodesCount
    }
  }
`

export default versionsMotorbikeQuery
