var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.componentType,_vm._g(_vm._b({tag:"component",class:[
    'button',
    {
      'skeleton-loader': this.skeletonLoader,
      ...(this.typeTheme && { [`-${this.typeTheme}`]: this.typeTheme }),
      ...(this.size && { [`-${this.size}`]: this.size }),
      ...(this.state && { [`-${this.state}`]: this.state }),
      ...(this.subtype && { [`-${this.subtype}`]: this.subtype }),
      '-disabled': _vm.isDisabled,
    },
  ],attrs:{"disabled":_vm.isDisabled,"aria-disabled":_vm.isDisabled,"to":_vm.to,"href":_vm.href,"target":_vm.target}},'component',_vm.$attrs,false),_vm.$listeners),[(_vm.loading)?_c('Spinner',{attrs:{"aria-role":"progressbar"}}):_vm._e(),_vm._v(" "),(_vm.icon)?_c('div',{staticClass:"icon-wrapper"},[_c('Icon',{attrs:{"name":_vm.icon,"size":['big_cta', 'large'].includes(_vm.size) ? '1.5rem' : '1.25rem'}})],1):_vm._e(),_vm._v(" "),(_vm.label)?_c('span',[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._t("default"),_vm._v(" "),(_vm.iconRight)?_c('div',{staticClass:"icon-wrapper"},[_c('Icon',{attrs:{"name":_vm.iconRight,"size":['big_cta', 'large'].includes(_vm.size) ? '1.5rem' : '1.25rem'}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }