/**
 * nieRegex admit a letter (either y, x, or z),
 * 7 numbers follow by a letter,
 * which depends on the previous numbers
 * % by 23 (it's a security letter)
 */
const nieRegex = new RegExp('^[x-zX-Z]{1}[0-9]{7}[a-zA-Z]{1}$')
const nieFormat = (value) => nieRegex.test(value)
function lastLetterValidator(value) {
  const nieFirstLetter = value.replace(/^[xX]/, '0').replace(/^[yY]/, '1').replace(/^[zZ]/, '2')
  const idVal = Number(nieFirstLetter.slice(0, 8)) % 23
  const lastLetter = 'TRWAGMYFPDXBNJZSQVHLCKE'
  return lastLetter[idVal] === value.slice(-1).toUpperCase()
}
const nie = (value) => nieFormat(value) && lastLetterValidator(value)

export default nie
