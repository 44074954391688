<template>
  <PartialsFlag
    v-if="!image"
    :class="`use-case-flag--style-${type.replaceAll('_', '-')}`"
    :data-cy="customId"
    :filled-icon="actualIcon.filled"
    :icon="actualIcon.name"
    :label="actualLabel"
  />
  <img
    v-else
    :class="`use-case-flag--style-${type.replaceAll('_', '-')}`"
    :data-cy="customId"
    :src="image"
    decoding="async"
    fetchpriority="low"
    loading="lazy"
  />
</template>

<script>
// Helpers & mixins
import { stringToSlug } from '@/helpers/urls'

// Components
import PartialsFlag from '@/components/partials/Flag.vue'

export default {
  name: 'PartialUseCaseFlag',
  components: {
    PartialsFlag,
  },
  props: {
    type: {
      type: String,
      required: true,
      default: 'default',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    actualIcon() {
      switch (this.type) {
        case 'sold_out':
          return { name: 'label_off', filled: false }
        case 'out_of_stock':
          return { name: 'remove_shopping_cart', filled: false }
        case 'on_offer':
          return { name: 'verified', filled: false }
        default:
          return { name: 'sell', filled: false }
      }
    },
    actualLabel() {
      if (this.label) return this.label

      return this.$t(`use_cases_flags.${this.type}`)
    },
    customId() {
      let name
      try {
        name = stringToSlug(this.actualLabel)
      } catch {
        name = this.type.replaceAll('_', '-')
      }

      return `${name}-flag`
    },
  },
}
</script>

<style lang="scss" scoped>
.use-case-flag--style-default {
  --color: #1e1932;
  --icon-color: #1e1932;
  --background-color: #fab42d;
}

.use-case-flag--style-sold-out {
  --color: #fff;
  --icon-color: #fff;
  --background-color: #41366c;
}

.use-case-flag--style-out-of-stock {
  --color: #1e1932;
  --icon-color: #1e1932;
  --background-color: #fab42d;
}

.use-case-flag--style-on-offer {
  --color: #fff;
  --icon-color: #fff;
  --background-color: #ff5a00;
}
</style>
