// vee-validate configuration
//
// In vee-validate v3, rules and locales are separated from the main library to make the
// bundle size smaller. However, it is required to manually extend and localize with the
// desired rules and languages. Doing this in a plugin so that it's globally done.

import { ValidationProvider, extend, localize } from 'vee-validate'
import {
  email,
  numeric,
  min,
  max,
  // eslint-disable-next-line camelcase
  min_value,
  // eslint-disable-next-line camelcase
  max_value,
  length,
  required,
  regex,
  between,
} from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import es from 'vee-validate/dist/locale/es.json'
import Vue from 'vue'
import date from './validators/date'
import dni from './validators/dni'
import nie from './validators/nie'
import iban from './validators/iban'

extend('email', email)
extend('min', min)
extend('max', max)
extend('min_value', min_value)
extend('max_value', max_value)
extend('length', length)
extend('numeric', numeric)
extend('required', required)
extend('regex', regex)
extend('between', between)
extend('between_prices', between)
extend('down_payment', max_value)

/*
 * A validator that will validate that a String value is a valid ISO 8601 date.
 * Note that because I'm using Date.parse, it may validate additional kinds of
 * dates – such as "Wed, January 15, 2020". Please, do not do that, because
 * Strings other than ISO dates are browser dependent and not guaranteed to be
 * portable. This was known as date_format in vee-validate 2.x, but they have
 * removed this validator in vee-validate 3.x. :(
 */
extend('date', date)

// A validator for DNIs.
extend('spanish_dni', dni)

// A validator for NIEs.
extend('spanish_nie', nie)

// A validator for IBANs.
extend('bank_account', iban)

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
})

const formatterValue = (v) => {
  return v
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

localize({
  es: {
    ...es,
    messages: {
      ...es.messages,
      required: (field) => {
        if (field === 'Términos y condiciones de uso') {
          return 'Debes aceptar los Términos y condiciones y la Política de privacidad'
        } else {
          return `El campo ${field} es obligatorio`
        }
      },
      regex: (field) => {
        if (field === 'Nombre' || field === 'Primer apellido') {
          return `El formato del ${field} no admite caracteres especiales`
        } else if (field === 'Número de cuenta') {
          return 'Empieza por ES, seguido de 22 caracteres sin espacios'
        } else if (field === 'Dirección') {
          return 'Por favor, verifica que el nombre es correcto y que has introducido el número de tu calle'
        } else {
          return `El formato del campo ${field} no es válido`
        }
      },
      bank_account: () => 'Debes introducir un número de cuenta válido',
      spanish_dni: () => 'El DNI no es válido o no tiene el formato adecuado',
      spanish_nie: () => 'El NIE no es válido o no tiene el formato adecuado',
      date: () => 'El formato de fecha no es valido',
      between_prices: (fieldName, placeholders) => {
        return `El valor de ${fieldName} debe estar entre ${formatterValue(
          placeholders.min,
        )} y ${formatterValue(placeholders.max)} `
      },
      password: () => 'Contraseña de verificación no coincide',
      down_payment: () => {
        return 'No puede ser mayor o igual al precio del vehículo'
      },
    },
  },
  en: {
    ...en,
    messages: {
      ...en.messages,
      required: (field) => {
        if (field === 'Terms of use & conditions of sale') {
          return 'You must accept the Terms and Conditions and Privacy Policy'
        } else {
          return `The field ${field} is required`
        }
      },
      bank_account: () => 'You need to introduce a valid bank account number',
      spanish_dni: () => 'The DNI is not valid or not in the right format',
      spanish_nie: () => 'The NIE is not valid or not in the right format',
      date: () => 'The date format is not valid',
      between_prices: (fieldName, placeholders) => {
        return `The value of ${fieldName} must be between ${formatterValue(
          placeholders.min,
        )} and ${formatterValue(placeholders.max)} `
      },
      password: () => 'Verification password does not match',
      down_payment: () => {
        return 'Cannot be greater than or equal to the price of the vehicle.'
      },
    },
  },
})

export default function ({ app }) {
  try {
    localize(app.$cookiez.get('preferred_language').split('_')[1].toLowerCase())
  } catch {
    localize('es')
  }
}

Vue.component('ValidationProvider', ValidationProvider)
