<template>
  <div class="tabbed-layout">
    <header class="page__header">
      <div class="container">
        <Breadcrumb v-if="breadcrumb?.length" :breadcrumb-links="breadcrumb" />

        <h1 class="section-title">
          {{ title }}
        </h1>

        <nav ref="swiper" class="tabs swiper">
          <div class="swiper-wrapper">
            <NuxtLink
              v-for="tab in tabs"
              :key="tab.key"
              :class="[
                'tabs__tab',
                'f-not-as-link',
                'swiper-slide',
                {
                  'tabs__tab--active': tab.key === currentTab,
                },
              ]"
              :to="tab.url"
            >
              <Icon v-if="tab.icon" :name="tab.icon" size="1.25rem" />
              <span>
                {{ tab.label }}
              </span>
            </NuxtLink>
          </div>
        </nav>
      </div>
    </header>
    <slot />
  </div>
</template>

<script>
// Dependencies
import { Swiper } from 'swiper'
import 'swiper/swiper.min.css'

// Components
import Breadcrumb from '@/components/Breadcrumb.vue'
import Icon from '@/components/Icon.vue'

export default {
  name: 'LayoutTabbed',
  components: {
    Breadcrumb,
    Icon,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    currentTab: {
      type: String,
      required: false,
      default: undefined,
    },
    breadcrumb: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    setupSlider() {
      let initialSlide
      try {
        initialSlide = this.tabs.findIndex((tab) => tab.key === this.currentTab)
      } catch {
        initialSlide = 0
      }

      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesOffsetBefore: 20,
        slidesOffsetAfter: 20,
        centeredSlides: false,
        centeredSlidesBounds: false,
        initialSlide,
        breakpoints: {
          // >= 480px
          480: {
            slidesOffsetBefore: 0,
            slidesOffsetAfter: 0,
          },
        },
      })
    },
    onResize() {
      this.setupSlider()
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.setupSlider()
  },
  beforeDestroy() {
    window.addEventListener('resize', this.onResize)
  },
}
</script>

<style lang="scss" scoped>
.page__header {
  border-bottom: 0.125rem solid $c-neutral-500;
  background-color: $c-neutral-100;

  .container {
    padding-top: 1.5rem;
    padding-bottom: 0;

    @include size-s {
      padding-right: 0;
      padding-left: 0;

      .section-title {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
      }
    }
  }

  .section-title {
    margin: 0 0 1.5rem;
  }

  .breadcrumb + .section-title {
    margin-top: 1.5rem;
  }

  .tabs {
    // display: flex;
    // flex-wrap: nowrap;
    // align-items: stretch;
    // justify-content: flex-start;
    transform: translateY(0.125rem);

    &__tab {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      padding: 1rem;
      transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
      border: solid transparent;
      border-width: 0.125rem 0.125rem 0;
      border-radius: 0.5rem 0.5rem 0 0;
      cursor: pointer;
      gap: 0.5rem;

      * {
        pointer-events: none;
      }

      span {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25rem;
        text-transform: uppercase;
      }

      .icon,
      span {
        color: $c-neutral-900;
      }

      &--active {
        border-color: $c-neutral-500;
        background-color: $c-white;

        .icon,
        span {
          color: $text-primary !important;
        }
      }

      @include size-l-up {
        &:hover {
          background-color: $c-neutral-300;

          .icon,
          span {
            color: $c-neutral-1000;
          }
        }
      }

      &:active {
        background-color: $c-neutral-500;

        .icon,
        span {
          color: $c-neutral-1000;
        }
      }
    }
  }
}
</style>
