<template>
  <div :class="['select-wrapper', { 'skeleton-loader': skeletonLoader }]">
    <label v-if="label" :for="`select-${id}`" class="f-size-14 f-500">
      {{ label }}
    </label>

    <div
      :class="[
        'select',
        {
          'select--focused': focused,
          'select--error': error,
          'select--disabled': disabled,
          'select--filled': isFilled,
          ...(size && { [`select--${size}`]: size }),
        },
      ]"
    >
      <div
        v-if="prependIcon"
        data-cy="prepend-icon"
        class="icon-wrapper prepend"
        :class="{ clickable: prependClickable }"
        @click="onPrependClick()"
      >
        <Icon :name="prependIcon" size="1.25rem" />
      </div>

      <select
        :id="`select-${id}`"
        :ref="`select-${id}`"
        :aria-errormessage="error ? `select-${id}-error` : undefined"
        :aria-invalid="!!error"
        :aria-label="label"
        :disabled="disabled"
        :invalid="!!error"
        :name="name"
        v-bind="$attrs"
        v-on="listeners"
        @input="onInput($event.target.value)"
        @focus="onFocus()"
        @blur="onBlur()"
      >
        <option v-if="placeholder" value="">
          {{ placeholder }}
        </option>
        <option v-for="[key, display] in options" :key="key" :value="key" :selected="value === key">
          {{ display }}
        </option>
        <optgroup v-for="group in groups" :key="group.name" :label="group.name">
          <option
            v-for="[key, display] in group.values"
            :key="key"
            :value="key"
            :selected="value === key"
          >
            {{ display }}
          </option>
        </optgroup>
      </select>

      <div class="icon-wrapper">
        <Icon
          :name="error ? 'warning' : disabled ? 'lock' : 'keyboard_arrow_down'"
          size="1.25rem"
        />
      </div>
    </div>

    <div class="select-wrapper__feedback">
      <span v-if="error" :id="`input-${id}-error`" class="error f-size-12" role="alert">
        {{ error }}
      </span>
      <span v-else-if="hint" class="hint f-size-12">{{ hint }}</span>
    </div>
  </div>
</template>

<script>
// Components
import Icon from '@/components/Icon'

// Other
const SIZES = ['s', 'm']

export default {
  name: 'Select',
  components: { Icon },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    options: {
      type: Array,
      required: false,
      default: () => [],
    },
    groups: {
      type: Array,
      required: false,
      default: () => [],
    },
    name: {
      type: String,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    error: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    hint: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    prependIcon: {
      type: String,
      default: undefined,
      required: false,
    },
    prependClickable: {
      type: Boolean,
      default: false,
      required: false,
    },
    size: {
      type: String,
      validator: (value) => SIZES.includes(value),
      default: 'm',
    },
    skeletonLoader: {
      type: Boolean,
      default: false,
      required: false,
    },
    placeholder: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      id: undefined,
      focused: false,
    }
  },
  computed: {
    isFilled() {
      return this.value !== ''
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    onFocus() {
      this.$emit('focus')
      this.focused = true
    },
    onBlur() {
      this.$emit('blur')
      this.focused = false
    },
    onPrependClick() {
      this.$emit('prependClick')
    },
  },
  created() {
    this.id = this._uid
  },
}
</script>

<style lang="scss" scoped>
.select-wrapper {
  height: 6rem;
  margin-top: 2rem;

  .select {
    --height: 3.25rem;

    &--s {
      --height: 2.5rem;
    }

    /* stylelint-disable-next-line */
    & {
      display: flex;
      position: relative;
      align-items: center;
      height: var(--height);
      margin: 0.25rem 0 0;
      padding: 1rem;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      border: solid 0.125rem $c-neutral-700;
      border-radius: 0.5rem;
      outline: none;
      background-color: $c-neutral-300;
      cursor: pointer;
      gap: 0.25rem;
    }

    select {
      appearance: none;
      position: relative;
      flex: 1;
      width: 100%;
      height: fit-content;
      border: none;
      outline: none;
      background-color: transparent;
      color: $c-neutral-900;

      &::placeholder {
        color: $c-neutral-800;
      }
    }

    &:not(.select--disabled) {
      &:hover {
        border-color: $c-neutral-900;

        select,
        .icon {
          color: $text-primary;
        }
      }

      &.select--focused select,
      &.select--filled select {
        color: $text-primary;
      }

      &.select--filled {
        border-color: $c-neutral-900;
      }

      &.select--focused {
        border-color: $c-primary-500;
      }

      &.select--error {
        padding-left: 1rem;
        border-color: $c-error;

        select,
        .icon {
          color: $c-error;
        }
      }
    }

    &--disabled {
      border-color: $disabled-01;
      background-color: $disabled-01;
      color: $disabled-02;

      .icon {
        color: $disabled-02;
      }

      select {
        padding-right: 1.5rem;
        cursor: no-drop;
      }

      cursor: no-drop;
    }

    .icon-wrapper {
      display: flex;
      position: absolute;
      right: 1rem;
      pointer-events: none;
    }

    * {
      cursor: pointer;
    }
  }

  &__feedback {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.25rem 0.25rem 0 1rem;

    .error {
      color: $c-error;
    }

    .hint {
      color: $c-info;
    }

    .length-counter {
      margin-left: auto;
    }
  }
}
</style>
