const date = (value) => {
  if (isNaN(Date.parse(value))) {
    return false
  }
  const date = new Date(value)
  const dayString = date.toISOString().split('T')[0]
  return dayString === value
}

export default date
