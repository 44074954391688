<template>
  <div
    :class="[
      'astara-benefits',
      {
        'astara-benefits--minified': minified,
      },
    ]"
  >
    <div v-for="benefit in benefits" :key="benefit.name" class="astara-benefits__benefit">
      <div>
        <div class="astara-benefits__benefit__header container-flex s-gap-8">
          <div class="astara-benefits__benefit__header__icon">
            <Icon :name="benefit.icon" size="1.5rem" />
          </div>
          <span
            :class="{
              'f-size-18 f-500': !minified,
              'f-size-14': minified,
            }"
          >
            {{ minified ? benefit.short_description : benefit.name }}
          </span>
        </div>

        <p v-if="!minified" class="astara-benefits__benefit__description f-size-14 s-mt-12">
          {{ benefit.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// Helpers & mixins
import { astaraBenefits } from '@/helpers/catalog'

// Components
import Icon from '@/components/Icon.vue'

export default {
  name: 'ModulesAstaraBenefits',
  components: {
    Icon,
  },
  props: {
    minified: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      benefits: astaraBenefits(this.$i18n),
    }
  },
}
</script>

<style lang="scss" scoped>
p:not(.section-title) {
  margin: 0;
}

.astara-benefits {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;

  &__benefit {
    > div {
      height: 100%;
    }

    &__header__icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      border-radius: 0.125rem;
      color: $text-primary;
    }

    &:nth-child(1) .astara-benefits__benefit__header__icon {
      background-color: $c-primary-800;
      color: $c-white;
    }

    &:nth-child(2) .astara-benefits__benefit__header__icon {
      background-color: $c-white;
    }

    &:nth-child(3) .astara-benefits__benefit__header__icon {
      background-color: $c-support-01-500;
    }

    &:nth-child(4) .astara-benefits__benefit__header__icon {
      background-color: $c-support-02-500;
    }
  }

  &:not(.astara-benefits--minified) {
    padding: 1.5rem 1.25rem;
    border-radius: 0.5rem;
    background-color: $c-neutral-100;

    @include size-m-up {
      grid-template-columns: repeat(2, 1fr);
    }

    @include size-xl-up {
      grid-template-columns: repeat(4, 1fr);
      padding: 1.5rem 0 1.5rem 1.25rem;
    }

    .astara-benefits__benefit {
      @include size-l {
        & + .astara-benefits__benefit {
          margin-top: 1.25rem;
          padding-top: 1.25rem;
          border-top: 0.0625rem solid $c-neutral-500;
        }
      }

      @include size-m-up {
        padding-right: 1.25rem;

        & + .astara-benefits__benefit > div {
          padding-left: 1.25rem;
          border-left: 0.0625rem solid $c-neutral-500;
        }

        &:nth-child(2) {
          margin-top: 0;
          padding-top: 0;
          border-top: 0;
        }
      }

      @media (width >= 768px) and (width <= 1279px) {
        &:nth-child(1) > div,
        &:nth-child(3) > div {
          padding-right: 1.25rem;
        }

        &:nth-child(3) > div {
          padding-left: 0;
          border-left: 0;
        }
      }

      @include size-xxl-up {
        flex: 1 1 25%;
      }

      &__description {
        @include size-s {
          display: none;
        }
      }
    }
  }

  &--minified {
    .astara-benefits__benefit {
      & + .astara-benefits__benefit {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: 0.0625rem solid $c-neutral-500;
      }

      &__header {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.75rem !important;
      }
    }
  }
}
</style>
