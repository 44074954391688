/* It sets a thousand separator */
export const toThousand = (value) => {
  if (value == null) return ''
  if (!typeof value === 'number' || Number.isNaN(value)) return value
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const toKms = (value) => {
  if (value == null) return ''
  if (!typeof value === 'number' || Number.isNaN(value)) return value
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' km'
}

export default {
  toThousand,
}
