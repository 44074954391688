import { gql } from 'graphql-tag'

export const newCarsQuery = gql`
  query newCarsQuery {
    newCars: models(
      vehicles: { categoryIn: "VN" }
      sortByRelevance: true
      page: 1
      perPage: 8
      vehicleTypeIn: ["car", "van"]
      tierRelevanceNin: 0
    ) {
      nodes {
        name
        slug
        salesVnCheapestPrice(financialEntityName: "Nemuru")
        tierRelevance
        publishableUnits
        make {
          name
          slug
        }
        hero {
          file
        }
        vehicles(sortByPrice: "asc", categoryIn: "VN") {
          useCases
          vehicleConfig {
            hero {
              file
            }
          }
        }
      }
    }
  }
`

export const motosQuery = gql`
  query motosQuery {
    motos: versions(
      model: { vehicleTypeLike: "motorbike" }
      sortByRelevance: true
      page: 1
      perPage: 8
      tierRelevanceNin: 0
    ) {
      nodes {
        slug
        name
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
        drivingLicence
        specs
        bodywork
        environmentalLabel
        hero {
          file
        }
        useCases
        publishableUnits(category: "vn")
        model {
          name
          slug
          publishableUnits(category: "vn")
          vehicleType
        }
        make {
          name
          slug
        }
        discounts {
          typeId
          startDate
          percentage
          name
          endDate
          amountCurrency
          amountCents
        }
        vehicleConfigs(onlyAvailables: true, sortByPrice: "asc") {
          specs
          publishable
          hero {
            file
          }
          colors {
            name
            surfaceType
          }
        }
      }
    }
  }
`

export const km0CarsQuery = gql`
  query km0CarsQuery {
    km0Cars: vehicles(
      page: 1
      perPage: 8
      sortByRelevance: true
      categoryIn: "Km0"
      tierRelevanceNin: 0
    ) {
      nodes {
        uid
        registrationDate
        kilometres
        salesPricing(financialEntityName: "Nemuru")
        tierRelevance
        hero {
          file
        }
        upFrontPayment(entity: "Nemuru")
        make {
          name
          slug
        }
        model {
          name
          slug
          vehicleType
          publishableUnits
        }
        version {
          slug
          name
        }
        vehicleConfig {
          slug
          hero {
            file
          }
        }
      }
    }
  }
`

export const voCarsQuery = gql`
  query voCarsQuery {
    voCars: vehicles(
      page: 1
      perPage: 8
      sortByRelevance: true
      categoryIn: "VO"
      tierRelevanceNin: 0
    ) {
      nodes {
        uid
        registrationDate
        kilometres
        salesPricing(financialEntityName: "Nemuru")
        tierRelevance
        hero {
          file
        }
        upFrontPayment(entity: "Nemuru")
        make {
          name
          slug
        }
        model {
          name
          slug
          vehicleType
          publishableUnits
        }
        version {
          slug
          name
        }
        vehicleConfig {
          slug
          hero {
            file
          }
        }
      }
    }
  }
`

export const catalogQuery = gql`
  query catalogQuery($financialEntityName: String) {
    newCars: models(
      vehicles: { categoryIn: "VN" }
      sortByRelevance: true
      page: 1
      perPage: 8
      vehicleTypeIn: ["car", "van"]
      tierRelevanceNin: 0
    ) {
      nodes {
        name
        slug
        vehicleType
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
        tierRelevance
        publishableUnits
        make {
          name
          slug
        }
        vehicles(categoryIn: "VN", sortByPrice: "desc", first: 1) {
          useCases
          vehicleConfig {
            hero {
              file
            }
          }
        }
      }
    }
    motos: versions(
      model: { vehicleTypeLike: "motorbike" }
      sortByRelevance: true
      page: 1
      perPage: 8
      tierRelevanceNin: 0
    ) {
      nodes {
        slug
        name
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
        hero {
          file
        }
        useCases
        publishableUnits(category: "vn")
        model {
          name
          slug
          publishableUnits(category: "vn")
          vehicleType
        }
        make {
          name
          slug
        }
        discounts {
          typeId
          startDate
          percentage
          name
          endDate
          amountCurrency
          amountCents
        }
        vehicleConfigs(onlyAvailables: true, sortByPrice: "asc") {
          specs
          publishable
          hero {
            file
          }
          colors {
            name
            surfaceType
          }
        }
      }
    }
    km0Cars: vehicles(
      page: 1
      perPage: 8
      sortByRelevance: true
      categoryIn: "Km0"
      tierRelevanceNin: 0
    ) {
      nodes {
        uid
        registrationDate
        kilometres
        salesPricing(financialEntityName: $financialEntityName)
        tierRelevance
        useCases
        hero {
          file
        }
        make {
          name
          slug
        }
        model {
          name
          slug
          vehicleType
          publishableUnits
        }
        version {
          slug
          name
        }
        vehicleConfig {
          slug
          hero {
            file
          }
        }
      }
    }
    voCars: vehicles(
      page: 1
      perPage: 8
      sortByRelevance: true
      categoryIn: "VO"
      tierRelevanceNin: 0
    ) {
      nodes {
        uid
        registrationDate
        kilometres
        useCases
        salesPricing(financialEntityName: $financialEntityName)
        tierRelevance
        hero {
          file
        }
        make {
          name
          slug
        }
        model {
          name
          slug
          vehicleType
          publishableUnits
        }
        version {
          slug
          name
        }
        vehicleConfig {
          slug
          hero {
            file
          }
        }
      }
    }
  }
`
export const vnCarsOnOffer = gql`
  query vnCarsOnOffer($useCasesIn: [String!]) {
    vehicles(categoryIn: "VN", useCasesIn: $useCasesIn) {
      nodesCount
    }
  }
`

export const km0CarsOnOffer = gql`
  query km0CarsOnOffer($useCasesIn: [String!]) {
    vehicles(categoryIn: "Km0", useCasesIn: $useCasesIn) {
      nodesCount
    }
  }
`

export const voCarsOnOffer = gql`
  query voCarsOnOffer($useCasesIn: [String!], $dealerUidIn: [String!]) {
    vehicles(dealerUidIn: $dealerUidIn, categoryIn: "VO", useCasesIn: $useCasesIn) {
      nodesCount
    }
  }
`

export const motorbikesOnOffer = gql`
  query motorbikesOnOffer($useCasesIn: [String!]) {
    vehicles: versions(model: { vehicleTypeLike: "motorbike" }, useCasesIn: $useCasesIn) {
      nodesCount
    }
  }
`

export default {
  catalogQuery,
  km0CarsOnOffer,
  km0CarsQuery,
  motorbikesOnOffer,
  motosQuery,
  newCarsQuery,
  vnCarsOnOffer,
  voCarsOnOffer,
  voCarsQuery,
}
