// This middleware makes sure the page is not under maintenance.
// Otherwise it will redirect the user to `/maintenance`.
export default function ({ app, route, redirect, store }) {
  const isAlive = store.getters.getAlive
  const currentPath = route.path
  const maintenancePath = app.localePath('maintenance')
  const indexPath = app.localePath('index')

  if (!isAlive && currentPath !== maintenancePath) {
    return redirect(maintenancePath)
  } else if (isAlive && currentPath === maintenancePath) {
    return redirect(indexPath)
  }
}
