<template>
  <CatalogPage
    :filters="filters"
    :makes="makes"
    :models-count="modelsCount"
    :models="models"
    :pages-count="pagesCount"
    :seo-content="seoContent"
    :vehicles-count="vehiclesCount"
    :vehicles-on-offer-count="vehiclesOnOfferCount"
    :vehicles="vehicles"
    category="move"
    vehicle-type="car"
  />
</template>

<script>
// Dependencies
import { markRaw } from 'vue'

// Queries
import vehiclesCarsQuery from '@/queries/vehiclesCarsQuery'
import { voCarsOnOffer } from '@/queries/catalogQuery'

// Helpers & mixins
import { getFiltersFromUrl, getSeoContent, getFilterableUseCases } from '@/helpers/catalog'

// Components
import CatalogPage from '@/components/catalog/Page.vue'

export default {
  name: 'PagesAstaraSelectionCars',
  layout: 'empty',
  components: {
    CatalogPage,
  },
  data() {
    return {
      filters: {},
      initialLoad: false,
      makes: [],
      models: [],
      modelsCount: 0,
      pagesCount: 0,
      seoContent: {},
      vehicles: undefined,
      vehiclesCount: 0,
      vehiclesOnOfferCount: 0,
    }
  },
  async validate({ app, error, route, store, redirect }) {
    try {
      const vehicleType = 'car'
      const category = 'VO'

      await store.dispatch('catalog/checkCatalogOptions', { vehicleType, category })

      const { filters } = await getFiltersFromUrl(
        store,
        category,
        route.params,
        route.query,
        app.i18n,
        store.getters['campaign/getActiveCampaigns'],
      )

      let redirectionConfig

      if (filters.invalidMakeSlug) {
        redirectionConfig = {
          name: 'astara-selection-cars',
        }
      } else if (filters.invalidModelSlug) {
        redirectionConfig = {
          name: 'astara-selection-cars',
          params: { facet1: filters.makeSlug },
        }
      } else if (filters.invalidParam) {
        redirectionConfig = {}

        if (filters.makeSlug) {
          redirectionConfig.params.facet1 = filters.makeSlug
        }

        if (filters.modelSlug) {
          redirectionConfig.params.facet2 = filters.modelSlug
        }

        redirectionConfig.name = 'astara-selection-cars'
      }

      if (redirectionConfig) {
        redirect(301, app.localePath(redirectionConfig))
      } else {
        return true
      }
    } catch (err) {
      app.$logger('Error fetching data', err)

      error({
        statusCode: err.response?.status || 500,
        message: err.response?.body || err || 'Internal Server Error',
      })
    }
  },
  async asyncData({ app, error, route, store, $axios }) {
    try {
      const useCases = await getFilterableUseCases(route, store)

      const client = app.apolloProvider.defaultClient

      let vehiclesOnOfferCount = 0
      if (useCases.length) {
        const { data: onOfferVehiclesData } = await client.query({
          query: voCarsOnOffer,
          variables: {
            useCasesIn: useCases,
            dealerUidIn: process.env.uidMoveDealer,
          },
        })
        vehiclesOnOfferCount = onOfferVehiclesData.vehicles.nodesCount
      }

      return {
        ...(await fetchData(
          client,
          app.i18n,
          route,
          store,
          $axios,
          store.getters['campaign/getActiveCampaigns'],
        )),
        vehiclesOnOfferCount,
      }
    } catch (err) {
      app.$logger('Error fetching data', err)

      error({
        statusCode: err.response?.status || 500,
        message: err.response?.body || 'Internal Server Error',
      })
    }
  },
  watch: {
    '$route.query': {
      async handler() {
        if (this.initialLoad) {
          const {
            filters,
            makes,
            models,
            modelsCount,
            pagesCount,
            seoContent,
            vehicles,
            vehiclesCount,
          } = await fetchData(this.$apollo, this.$i18n, this.$route, this.$store, this.$axios)

          this.filters = filters
          this.makes = makes
          this.models = models
          this.modelsCount = modelsCount
          this.pagesCount = pagesCount
          this.seoContent = seoContent
          this.vehicles = vehicles
          this.vehiclesCount = vehiclesCount
        }

        this.initialLoad = true
      },
      immediate: true,
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('campaign/fetchData', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return this.$nuxtI18nHead({
      addSeoAttributes: {
        canonicalQueries: ['page'],
      },
    })
  },
}

const fetchData = async (client, i18n, route, store, axios) => {
  const vehicleType = 'car'
  const category = 'VO'

  const makes = store.getters['catalog/getMakes'] || []

  const { filters, models } = await getFiltersFromUrl(
    store,
    category,
    route.params,
    route.query,
    i18n,
  )

  const variables = {
    financialEntityName: process.env.defaultFinancialEntity,
    language: store.getters['index/languageIso'] || 'es',
    dealerUidIn: process.env.uidMoveDealer,
    ...filters,
  }

  const { vehicles, models: vehicleModels } = await client
    .query({
      query: markRaw(vehiclesCarsQuery),
      variables,
    })
    .then(({ data }) => data)

  const seoContent = await getSeoContent(vehicleType, 'move', route, i18n, axios, store, filters)

  return {
    filters,
    makes,
    models,
    modelsCount: vehicleModels?.nodesCount || 0,
    pagesCount: vehicles?.pagesCount || 0,
    seoContent,
    vehicles: vehicles?.nodes || [],
    vehiclesCount: vehicles?.nodesCount || 0,
  }
}
</script>
