<template>
  <div :class="['textarea-wrapper', { 'skeleton-loader': skeletonLoader }]">
    <label v-if="label" :for="`textarea-${id}`" class="f-size-14 f-500">
      {{ label }}
    </label>
    <div
      v-click-outside="onClickOutside"
      :class="[
        'textarea-content',
        's-mt-4',
        {
          focus: this.focused,
          error: this.error,
          disabled: this.disabled,
        },
      ]"
    >
      <textarea
        :id="`textarea-${id}`"
        :aria-errormessage="error ? `textarea-${id}-error` : null"
        :aria-invalid="!!error"
        :aria-label="label"
        :invalid="!!error"
        :name="name"
        :placeholder="placeholder"
        class="textarea"
        v-bind="$attrs"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
        @click="clicked()"
      />
      <div v-if="error" data-cy="error-icon" class="icon-wrapper error">
        <Icon name="warning" size="1.25rem" />
      </div>
      <div
        v-if="$attrs.value && removeIcon"
        data-cy="cross-icon"
        class="icon-wrapper remove clickable"
        @click="remove()"
      >
        <Icon name="cancel" filled size="1.25rem" />
      </div>
    </div>
    <div v-show="error" :id="`textarea-${id}-error`" class="error size-12" role="alert">
      {{ error }}
    </div>
    <div v-show="!error && hint" class="hint size-12">{{ hint }}</div>
  </div>
</template>

<script>
// Plugins
import clickOutside from '@/plugins/click-outside'

// Components
import Icon from '@/components/Icon.vue'

export default {
  name: 'Textarea',
  components: {
    Icon,
  },
  directives: {
    clickOutside,
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    skeletonLoader: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      id: null,
      focused: false,
      removeIcon: false,
    }
  },
  created() {
    this.id = this._uid
  },
  methods: {
    focus() {
      this.$emit('focus')
      this.focused = true
    },
    blur() {
      this.$emit('blur')
      this.focused = false
    },
    remove() {
      this.$emit('input', '')
    },
    prependClick() {
      this.$emit('prependClick')
    },
    appendClick() {
      if (this.type === 'password') {
        this.passwordIcon = !this.passwordIcon
      } else {
        this.$emit('appendClick')
      }
    },
    clicked() {
      this.removeIcon = true
    },
    onClickOutside() {
      this.removeIcon = false
    },
  },
}
</script>

<style lang="scss" scoped>
.textarea-wrapper {
  margin-top: 2rem;

  .textarea-content {
    display: flex;
    position: relative;
    align-items: center;
    gap: 0.25rem;
    margin: 0.25px 0;
    padding: 1rem;
    border: solid 2px $c-neutral-700;
    border-radius: 8px;
    outline: none;
    background-color: $c-neutral-300;

    &:hover {
      border-color: $c-neutral-900;
      background-color: $c-neutral-500;

      > .textarea {
        background-color: $c-neutral-500;

        &:-webkit-autofill,
        &:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0 100px $c-neutral-500 inset;
          -moz-box-shadow: 0 0 0 100px $c-neutral-500 inset;
          box-shadow: 0 0 0 100px $c-neutral-500 inset;
        }
      }
    }

    &.focus {
      border-color: $c-primary-500;
    }

    &.error {
      padding-left: 1rem;
      border-color: $c-error;
    }

    &.disabled {
      padding-right: 3rem;
      border-color: $disabled-01;
      background-color: $disabled-01;
      color: $disabled-02;
      cursor: no-drop;

      > .textarea {
        background-color: $disabled-01;
        cursor: no-drop;
      }
    }

    .textarea {
      flex: 1;
      width: 100%;
      height: fit-content;
      border: none;
      outline: none;
      background-color: $c-neutral-300;

      &:-webkit-autofill,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 100px $c-neutral-300 inset;
        -moz-box-shadow: 0 0 0 100px $c-neutral-300 inset;
        box-shadow: 0 0 0 100px $c-neutral-300 inset;
      }

      &:-webkit-autofill:hover {
        -webkit-box-shadow: 0 0 0 100px $c-neutral-500 inset;
        -moz-box-shadow: 0 0 0 100px $c-neutral-500 inset;
        box-shadow: 0 0 0 100px $c-neutral-500 inset;
      }

      &:placeholder-shown {
        color: $c-neutral-800;
      }
    }

    .icon-wrapper {
      display: flex;
      position: absolute;
      top: 1.25rem;
      right: 1.25rem;
      align-self: flex-start;

      &.error :deep(.icon) {
        transform: translateY(-0.125rem);
        color: $c-error !important;
      }

      &.remove {
        justify-self: flex-end;
      }

      &.clickable {
        cursor: pointer;
      }
    }
  }

  .error {
    padding-left: 1rem;
    color: $c-error;
  }

  .hint {
    margin: 4px 0 0;
    padding-left: 1rem;
    color: $c-info;
  }
}
</style>
