var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'astara-benefits',
    {
      'astara-benefits--minified': _vm.minified,
    },
  ]},_vm._l((_vm.benefits),function(benefit){return _c('div',{key:benefit.name,staticClass:"astara-benefits__benefit"},[_c('div',[_c('div',{staticClass:"astara-benefits__benefit__header container-flex s-gap-8"},[_c('div',{staticClass:"astara-benefits__benefit__header__icon"},[_c('Icon',{attrs:{"name":benefit.icon,"size":"1.5rem"}})],1),_vm._v(" "),_c('span',{class:{
            'f-size-18 f-500': !_vm.minified,
            'f-size-14': _vm.minified,
          }},[_vm._v("\n          "+_vm._s(_vm.minified ? benefit.short_description : benefit.name)+"\n        ")])]),_vm._v(" "),(!_vm.minified)?_c('p',{staticClass:"astara-benefits__benefit__description f-size-14 s-mt-12"},[_vm._v("\n        "+_vm._s(benefit.description)+"\n      ")]):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }