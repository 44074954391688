<template>
  <div
    :class="{
      'radio-button-container': true,
      disabled: disabled,
      'skeleton-loader': skeletonLoader,
    }"
  >
    <div class="radio-button">
      <input
        :id="inputId"
        :aria-errormessage="error ? `${inputId}-error` : null"
        :aria-invalid="!!error"
        :aria-label="label"
        :checked="checked"
        :class="`radio-button-input ${size}`"
        :disabled="disabled"
        :name="name"
        :required="required"
        :value="value"
        type="radio"
        v-bind="$attrs"
        v-on="listeners"
      />
      <label v-if="showLabel" class="label" :for="inputId">
        {{ label || value }}
      </label>
    </div>
    <div v-show="!!error" :id="`${inputId}-error`" class="error size-14" role="alert">
      {{ error }}
    </div>
    <div v-show="!error && hint" :class="`hint size-14 ${size}`">{{ hint }}</div>
  </div>
</template>

<script>
const SIZES = ['s', 'm']
export default {
  name: 'RadioButton',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    hint: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      validator: (value) => SIZES.includes(value),
      default: 'm',
    },
    btnId: {
      type: Number,
      required: false,
      default: null,
    },
    skeletonLoader: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      id: this.btnId,
    }
  },
  computed: {
    inputId() {
      return `radio-button-${this.id}-${this.value}`
    },
    listeners() {
      return { ...this.$listeners, input: () => this.$emit('input', event.target.value) }
    },
  },
}
</script>

<style lang="scss" scoped>
.radio-button-container {
  --size: 1.5rem;

  &.s {
    --size: 1.25rem;
  }

  .radio-button {
    display: flex;
    align-items: center;

    label {
      cursor: pointer;
    }

    .radio-button-input {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--size);
      min-width: var(--size);
      height: var(--size);
      margin: 0;
      overflow: hidden;
      transition: border-color 0.3s ease, color 0.3s ease;
      border: 0.125rem solid $c-neutral-700;
      border-radius: 50%;
      color: $button-primary-enabled;
      cursor: pointer;
      appearance: none;

      &::before {
        content: '';
        width: calc(var(--size) * 0.5);
        height: calc(var(--size) * 0.5);
        transition: opacity 0.3s ease;
        border-radius: 50%;
        opacity: 0;
        background-color: $button-primary-enabled;
        box-shadow: inset 1rem 1rem $button-primary-enabled;
      }

      &:hover {
        border-color: $button-primary-hovered;
      }

      &:active {
        border-color: $button-primary-pressed;
      }

      &:checked {
        border-color: $button-primary-enabled;

        &::before {
          opacity: 1;
        }
      }

      &:disabled {
        border-color: $disabled-01;

        &::before {
          background-color: $disabled-01;
          box-shadow: inset 1rem 1rem $disabled-01;
        }

        ~ .label {
          color: $disabled-01;
        }
      }
    }

    .label {
      display: flex;
      align-items: center;
      min-height: var(--size);
      margin-left: 0.6875rem;
    }
  }

  .error {
    display: flex;
    padding-left: 2.25rem;
    color: $c-error;
  }

  .hint {
    display: flex;
    color: $text-secondary;

    &.m {
      padding-left: 2.25rem;
    }

    &.s {
      padding-left: 2rem;
    }
  }

  &.disabled .hint {
    color: $disabled-01;
  }
}
</style>
