import { gql } from 'graphql-tag'

export const optionsMotorbikeQuery = gql`
  query optionsMotorbikeQuery($financialEntityName: String, $language: String) {
    lowestPrice: versions(
      model: { vehicleTypeLike: "motorbike" }
      perPage: 1
      sortByPrice: "asc"
      page: 1
    ) {
      nodes {
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
      }
    }

    highestPrice: versions(
      model: { vehicleTypeLike: "motorbike" }
      perPage: 1
      sortByPrice: "desc"
      page: 1
    ) {
      nodes {
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
      }
    }
    bodyworks(category: "vn", vehicleType: "motorbike")
    useCases(category: "vn", vehicleType: "motorbike")
    environmentalLabels(category: "vn", vehicleType: "motorbike")
    makes(
      vehicleTypeLike: "motorbike"
      vehicleCategoryLike: "vn"
      sortBy: "name"
      vehicles: { statusIn: "available" }
    ) {
      nodes {
        name
        slug
        metaTitle(language: $language)
        metaDescription(language: $language)
        title(language: $language)
        description(language: $language)
        characteristic {
          description(language: $language)
        }
      }
    }
  }
`
export const optionsCarQuery = gql`
  query optionsCarQuery(
    $financialEntityName: String
    $categoryIn: [String!]
    $category: String!
    $dealerUidIn: [String!]
    $language: String
  ) {
    lowestPrice: vehicles(
      sortByPrice: "asc"
      page: 1
      perPage: 1
      categoryIn: $categoryIn
      dealerUidIn: $dealerUidIn
    ) {
      nodes {
        salesPricing(financialEntityName: $financialEntityName)
      }
    }

    highestPrice: vehicles(
      sortByPrice: "desc"
      page: 1
      perPage: 1
      categoryIn: $categoryIn
      dealerUidIn: $dealerUidIn
    ) {
      nodes {
        salesPricing(financialEntityName: $financialEntityName)
      }
    }
    bodyworks(category: $category, vehicleTypeIn: ["car", "van"], dealerUidIn: $dealerUidIn)
    useCases(category: $category, vehicleTypeIn: ["car", "van"], dealerUidIn: $dealerUidIn)
    environmentalLabels(
      category: $category
      vehicleTypeIn: ["car", "van"]
      dealerUidIn: $dealerUidIn
    )
    makes(
      vehicleTypeIn: ["car", "van"]
      vehicleCategoryLike: $category
      sortBy: "name"
      vehicles: { statusIn: "available", dealerUidIn: $dealerUidIn }
    ) {
      nodes {
        name
        slug
        metaTitle(language: $language)
        metaDescription(language: $language)
        title(language: $language)
        description(language: $language)
        characteristic {
          description(language: $language)
        }
      }
    }
  }
`
export const optionsModelQuery = gql`
  query optionsModelQuery($financialEntityName: String, $language: String) {
    lowestPrice: models(
      vehicleTypeIn: ["car", "van"]
      perPage: 1
      sortByPrice: "asc"
      page: 1
      vehicles: { categoryIn: "VN" }
    ) {
      nodes {
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
      }
    }

    highestPrice: models(
      vehicleTypeIn: ["car", "van"]
      perPage: 1
      sortByPrice: "desc"
      page: 1
      vehicles: { categoryIn: "VN" }
    ) {
      nodes {
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
      }
    }
    bodyworks(category: "vn", vehicleTypeIn: ["car", "van"])
    useCases(category: "vn", vehicleTypeIn: ["car", "van"])
    environmentalLabels(category: "vn", vehicleTypeIn: ["car", "van"])
    makes(
      vehicleTypeIn: ["car", "van"]
      vehicleCategoryLike: "vn"
      sortBy: "name"
      vehicles: { statusIn: "available" }
    ) {
      nodes {
        name
        slug
        metaTitle(language: $language)
        metaDescription(language: $language)
        title(language: $language)
        description(language: $language)
        characteristic {
          description(language: $language)
        }
      }
    }
  }
`

export default {
  optionsMotorbikeQuery,
  optionsCarQuery,
  optionsModelQuery,
}
