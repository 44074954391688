<template>
  <footer
    :class="[
      'footer',
      {
        'footer--purchase-mode': purchaseMode,
      },
    ]"
  >
    <div class="container">
      <div class="footer__top">
        <div class="logo">
          <Icon name="astara_logo" />
        </div>

        <ul v-if="!purchaseMode" class="media-list">
          <li>
            <div
              class="pseudo-link f-not-as-link"
              @click="
                () =>
                  onPseudoRedirect(
                    'https://www.youtube.com/channel/UC3Q-QF0rwJIDaaY6qivcDQg',
                    'youtube',
                    false,
                    '_blank',
                  )
              "
            >
              <Icon size="1.5rem" name="youtube" label="YouTube" />
            </div>
          </li>
          <li>
            <div
              class="pseudo-link f-not-as-link"
              @click="
                () =>
                  onPseudoRedirect(
                    'https://www.facebook.com/storeastara',
                    'facebook',
                    false,
                    '_blank',
                  )
              "
            >
              <Icon size="1.5rem" name="facebook" label="Facebook" />
            </div>
          </li>
          <li>
            <div
              class="pseudo-link f-not-as-link"
              @click="
                () =>
                  onPseudoRedirect(
                    'https://www.instagram.com/astarastorees/',
                    'instagram',
                    false,
                    '_blank',
                  )
              "
            >
              <Icon size="1.5rem" name="instagram" label="Instagram" />
            </div>
          </li>
        </ul>
      </div>

      <div v-if="!purchaseMode" class="footer__main">
        <!-- Discover -->
        <div class="discovery">
          <div class="bold">{{ $t('footer.discover.title') }}</div>
          <ul>
            <li>
              <NuxtLink
                :to="localePath('astara-selection')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('discover move selection')"
              >
                {{ $t('global.categories_extended.move')[1] }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="localePath('motorbikes')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('discover moto')"
                >{{ $t('global.categories_extended.motorbike')[1] }}</NuxtLink
              >
            </li>
            <li>
              <NuxtLink
                :to="localePath('cars-vo')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('discover vo')"
              >
                {{ $t('global.categories_extended.vo')[1] }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="localePath('cars-km0')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('discover km')"
              >
                {{ $t('global.categories_extended.km0')[1] }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="localePath('cars-vn')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('discover car')"
              >
                {{ $t('global.categories_extended.vn')[1] }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="localePath('financing')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('financing')"
              >
                {{ $t('footer.discover.financing') }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="localePath('trade-in')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('trade-in')"
              >
                {{ $t('footer.discover.trade_in') }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="localePath('warranty')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('warranty')"
              >
                {{ $t('footer.discover.warranty') }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="localePath('eco-vehicles')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('discover eco')"
              >
                {{ $t('global.categories_extended.eco')[1] }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="localePath('zero-emissions')"
                class="f-as-link f-as-link--secondary-alt"
              >
                {{ $t('global.categories_extended.zero_emissions')[1] }}
              </NuxtLink>
            </li>
            <li v-if="showBlog">
              <NuxtLink
                :to="localePath('news')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('blog')"
              >
                {{ $t('footer.discover.blog') }}
              </NuxtLink>
            </li>
            <li class="bottom">
              <a
                href="https://astaramove.com/"
                target="_blank"
                class="f-as-link f-as-link--secondary-alt"
                @click.capture="pushEvent('astara move')"
              >
                {{ $t('footer.discover.astara_move') }}
              </a>
            </li>
          </ul>
        </div>

        <!-- Information -->
        <div class="information">
          <div class="bold">{{ $t('footer.information.title') }}</div>
          <ul>
            <li>
              <NuxtLink
                :to="localePath('about')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('what trive')"
              >
                {{ $t('footer.information.about_us') }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="localePath('faqs')"
                class="f-as-link f-as-link--secondary-alt"
                @click.native="pushEvent('faqs')"
              >
                {{ $t('footer.information.faqs') }}
              </NuxtLink>
            </li>
            <li>
              <div
                class="pseudo-link f-as-link f-as-link--secondary-alt"
                @click="onPseudoRedirect('use-terms', 'use terms')"
              >
                {{ $t('footer.information.terms') }}
              </div>
            </li>
            <li>
              <div
                class="pseudo-link f-as-link f-as-link--secondary-alt"
                @click="onPseudoRedirect('privacy-policy', 'privacy policy')"
              >
                {{ $t('footer.information.privacy_policy') }}
              </div>
            </li>
            <li>
              <div
                class="pseudo-link f-as-link f-as-link--secondary-alt"
                @click="onPseudoRedirect('cookies-policy', 'cookies policy')"
              >
                {{ $t('footer.information.cookies_policy') }}
              </div>
            </li>
            <li
              class="cookies_configuration"
              @click="openCookiesConfigurator"
              @click.capture="pushEvent('cookies configuration')"
            >
              <span class="f-as-link f-as-link--secondary-alt">
                {{ $t('footer.information.cookies_configuration') }}
              </span>
            </li>
          </ul>
        </div>

        <!-- Contact -->
        <div class="contact">
          <div>
            <div class="bold">{{ $t('footer.contact.title') }}</div>
            <ul>
              <li>
                <NuxtLink
                  :to="localePath('contact')"
                  class="f-as-link f-as-link--secondary-alt"
                  @click.native="pushEvent('contact')"
                >
                  {{ $t('footer.contact.contact') }}
                </NuxtLink>
              </li>
              <li>
                <a
                  :href="`mailto:${contactEmail}`"
                  class="f-as-link f-as-link--secondary-alt"
                  @click.capture="() => $tracker.emailClick(contactEmail)"
                >
                  {{ contactEmail }}
                </a>
              </li>
              <li>
                <a
                  :href="`tel:${contactPhone}`"
                  class="f-as-link f-as-link--secondary-alt"
                  @click.capture="() => $tracker.phoneClick(contactPhone)"
                >
                  {{ contactPhone }}
                </a>
              </li>
            </ul>
          </div>
          <Icon
            class="trust-mark-icon"
            name="sello_confianza_online"
            label="Sello Confianza Online"
          />
        </div>
        <div class="trust-mark">
          <div
            class="pseudo-link f-not-as-link"
            @click="
              onPseudoRedirect(
                'https://www.confianzaonline.es/',
                'confianza online',
                false,
                '_blank',
              )
            "
          >
            <Icon
              class="trust-mark-icon"
              name="sello_confianza_online"
              label="Sello Confianza Online"
            />
          </div>
        </div>
      </div>

      <div class="footer__bottom">
        <hr v-if="!purchaseMode" class="divider" />
        <div>
          <div class="legal">{{ $t('footer.copyright', { year }) }}</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// Helpers & mixins
import { validateBlogUrl } from '@/helpers/urls'

// Components
import Icon from '@/components/Icon'

export default {
  components: { Icon },
  props: {
    purchaseMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      contactPhone: process.env.contactPhone,
      contactEmail: process.env.contactEmail,
    }
  },
  computed: {
    year() {
      return new Date().getFullYear()
    },
    showBlog() {
      return validateBlogUrl(this.$route)
    },
  },
  methods: {
    openCookiesConfigurator() {
      window.OneTrust.ToggleInfoDisplay()
    },
    pushEvent(mainCategory) {
      this.$tracker.footerClick(mainCategory)
    },
    onPseudoRedirect(destination, eventMainCategory = undefined, local = true, target = '_self') {
      if (eventMainCategory) {
        this.pushEvent(eventMainCategory)
      }

      if (local) {
        this.$router.push(this.localePath(destination))
      } else {
        window.open(destination, target)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: $footer;
  color: $c-white;

  > .container {
    display: flex;
    gap: 3rem;
    position: relative;
    flex-direction: column;
    padding-top: 3.5rem;
    padding-bottom: 2rem;

    @include size-s {
      justify-content: center;
    }
  }

  ul {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    padding: 0;
    list-style: none;

    @include size-m {
      gap: 0 1.5rem;
    }

    li {
      margin: 0.5rem 0;
      font-size: 14px;
    }
  }

  &__top {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    @include size-s {
      align-items: center;
    }

    .logo .icon {
      width: 12rem;
      height: 2.25rem;
    }

    .media-list {
      display: flex;
      flex-direction: row;
      margin: 0;

      @include size-m-up {
        position: absolute;
        right: 2rem;
        bottom: 1.25rem;
      }

      li {
        margin: 0 1rem;
      }
    }
  }

  &__main {
    display: grid;
    gap: 0 2.5rem;
    grid-template-columns: repeat(4, 1fr);

    @include size-l {
      gap: 0 2rem;
    }

    @include size-m {
      grid-template-columns: repeat(3, 1fr);
      gap: 0 1.5rem;
    }

    @include size-xs {
      gap: 3rem 1.5rem;
      grid-template-columns: repeat(2, 1fr);
    }

    .discovery,
    .information,
    .contact,
    .trust-mark {
      @include size-xs {
        box-sizing: border-box;
      }

      div:not(.pseudo-link) {
        margin: 0 0 1rem;
      }
    }

    .trust-mark-icon {
      width: 5rem;
      height: 5rem;
    }

    .contact {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include size-l-up {
        .trust-mark-icon {
          display: none;
        }
      }

      @include size-xs {
        .trust-mark-icon {
          display: none;
        }
      }
    }

    .trust-mark {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      @include size-m {
        .trust-mark-icon {
          display: none;
        }
      }

      @include size-s {
        display: none;
      }

      @include size-xs {
        .trust-mark-icon {
          display: flex;
        }
      }
    }

    ul {
      @include size-m {
        gap: 1.25rem;
      }

      li {
        margin: 0;

        &.bottom {
          order: 20;
        }
      }
    }

    .cookies_configuration {
      font-weight: 500;
      cursor: pointer;
    }
  }

  &__bottom {
    .divider {
      width: 100%;
      height: 1px;
      margin: 0;
      border: 0;
      background: $c-primary-600;
    }

    > div:not(.pseudo-link) {
      display: flex;
      justify-content: space-between;
      padding-top: 2.25rem;

      @include size-s {
        justify-content: center;
      }
    }
  }

  &--purchase-mode .container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @include size-s {
      flex-direction: column;
      gap: 1.5rem;
    }

    .footer__bottom > div:not(.pseudo-link) {
      padding-top: 0;
    }
  }
}
</style>
