import Vue from 'vue'
import { toCurrency } from '@/helpers/currency'
import { toThousand } from '@/helpers/numbers'
import dateYearFormatter from '@/helpers/datetime'
import { concatValues } from '@/helpers/utils'

Vue.filter('currency', toCurrency)
Vue.filter('toThousand', toThousand)
Vue.filter('year', dateYearFormatter)
Vue.filter('range', concatValues(' - '))
Vue.filter('list', concatValues(', '))
