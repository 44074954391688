<template>
  <div>
    <div
      id="expandable-content-module"
      ref="seoContent"
      class="expandable-content-component collapsed"
    >
      <div class="expandable-content-list">
        <div class="f-size-18" v-html="htmlContent" />
        <div class="content-shadow" />
      </div>
    </div>

    <div class="container-buttons s-mt-16">
      <Button
        :label="toggleBtnLabel"
        :icon-right="toggleIcon"
        class="see-more"
        size="medium"
        subtype="icon-text"
        type-theme="ghost-light"
        @click="expandModule"
      />
    </div>
  </div>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'

// Plugins
import { customSanitizer } from '@/plugins/sanitizer'

// Components
import Button from '@/components/Button.vue'

export default {
  name: 'CatalogSeoContent',
  components: {
    Button,
  },
  props: {
    slug: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      contentBody: null,
      htmlContent: '',
      open: false,
    }
  },
  computed: {
    ...mapGetters({
      languageIso: 'getLanguageIso',
    }),
    toggleBtnLabel() {
      return this.open ? this.$t('global.see_less') : this.$t('global.see_more')
    },
    toggleIcon() {
      return this.open ? 'north' : 'south'
    },
  },
  methods: {
    async loadPageContent() {
      if (!this.slug) return ''

      try {
        const page = await this.$companyService.fetchLegals(this.slug)
        const data = page[0].description
          .filter((x) => x.language === this.languageIso || 'es')[0]
          .text.replaceAll('&lt;', '<')
          .replaceAll('&gt;', '>')
          .replaceAll('<pre>', '')
          .replaceAll('</pre>', '')

        return data
      } catch (error) {
        return ''
      }
    },
    expandModule() {
      this.open = !this.open
      const toggleContentShadow = document.querySelector('.content-shadow')
      const charModule = document.getElementById('expandable-content-module')

      this.open
        ? (toggleContentShadow.style.height = '0%')
        : (toggleContentShadow.style.height = '90%')

      this.open ? (charModule.style.maxHeight = 'none') : (charModule.style.maxHeight = '48vh')

      if (!this.open) {
        this.gotoTop()
      }
    },
    gotoTop() {
      window.scroll({
        top: this.$refs.seoContent.offsetTop - 60,
        behavior: 'smooth',
      })
    },
  },
  async created() {
    try {
      this.htmlContent = customSanitizer(this.content || (await this.loadPageContent()))
    } catch {
      this.htmlContent = ''
    }
  },
}
</script>

<style lang="scss" scoped>
#expandable-content-module {
  position: relative;
  max-height: 48vh;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.expandable-content-component {
  .expandable-content-list {
    display: flex;
    flex-direction: column;
    max-width: 55.5rem;

    @include size-xl {
      max-width: 50rem;
    }

    @include size-l {
      max-width: 40.1875rem;
    }

    @include size-m {
      max-width: 100%;
    }

    .content-shadow {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  :deep(*) {
    @include enrichedContentCustomization;
  }
}
</style>
