<template>
  <div
    data-cy="catalog-card-usp"
    :class="['catalog-card-usp', `catalog-card-usp--${type.replaceAll('_', '-')}`]"
  >
    <!-- Header -->
    <header class="catalog-card-usp__header">
      <Icon v-if="icon" :name="icon" size="2rem" class="s-mb-8" />
      <p class="title f-size-18 f-700 s-pb-4">
        {{ $t(`card_usp.${type}.title`) }}
      </p>
    </header>

    <!-- Main content -->
    <div class="catalog-card-usp__main">
      <p
        v-if="type !== 'astara_benefits'"
        class="description f-size-18"
        v-html="$t(`card_usp.${type}.description`, { contactPhone })"
      />
      <ModulesAstaraBenefits v-else minified class="astara-benefits" />
    </div>

    <!-- Actions -->
    <footer v-if="type !== 'astara_benefits'" class="catalog-card-usp__footer s-pt-20">
      <Button
        :type-theme="buttonTheme"
        :label="$t(`card_usp.${type}.cta`)"
        size="medium"
        @click="() => onCta()"
      />
    </footer>

    <ModalsContact
      v-if="showModalsContact"
      open
      @formStatus="(status) => (modalsContactStatus = status)"
      @submit="(formData) => onSubmitContactForm(formData)"
      @close="() => (showModalsContact = false)"
    />
  </div>
</template>

<script>
// Dependencies
import { mapActions } from 'vuex'

// Helpers & mixins
import { uspTypes } from '@/helpers/catalog'

// Components
import Button from '@/components/Button.vue'
import ModalsContact from '@/components/modals/Contact.vue'
import Icon from '@/components/Icon.vue'
import ModulesAstaraBenefits from '@/components/modules/AstaraBenefits.vue'

export default {
  name: 'CatalogCard',
  components: {
    Button,
    ModalsContact,
    Icon,
    ModulesAstaraBenefits,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return uspTypes.includes(value)
      },
    },
    searchOptions: {
      type: [Object, undefined],
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showModalsContact: false,
      contactPhone: process.env.contactPhone,
      modalsContactStatus: false,
    }
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'buying_power':
          return 'favorite_border'
        case 'warranty':
          return 'lock'
        case 'alert':
          return 'hotel_class'
        case 'financing':
          return 'calculate'
        case 'contact':
        case 'trade_in':
          return 'support_agent'
        case 'eco_vehicles':
          return 'sprout'
        default:
          return undefined
      }
    },
    buttonTheme() {
      switch (this.type) {
        case 'warranty':
          return 'primary-light'
        default:
          return 'secondary-light'
      }
    },
    url() {
      switch (this.type) {
        case 'buying_power':
          return this.localePath('buying-power')
        case 'warranty':
          return this.localePath('warranty')
        case 'alert':
          return this.localePath('alerts-new')
        case 'financing':
          return this.localePath('financing')
        case 'trade_in':
          return this.localePath('trade-in')
        case 'eco_vehicles':
          return this.localePath('vehiculos-eco')
        default:
          return undefined
      }
    },
  },
  methods: {
    ...mapActions({
      clearOptions: 'searches/clearOptions',
      saveOptions: 'searches/saveOptions',
    }),
    async onCta() {
      switch (this.type) {
        case 'contact':
          this.onOpenContactForm()
          break
        default:
          if (this.type === 'alert') {
            await this.clearOptions()
          }

          if (this.searchOptions) {
            this.saveOptions(this.searchOptions)
          }

          this.$router.push(this.url)
      }
    },
    onOpenContactForm() {
      this.showModalsContact = true

      // SEO
      if (process?.browser && process?.env?.gtm_enabled) {
        this.$gtm.push({
          event: 'contact_card',
          make: null,
          model: null,
          version: null,
          source: this.$store.getters['campaign/getSource'],
          medium: this.$store.getters['campaign/getMedium'],
          campaign: this.$store.getters['campaign/getCampaign'],
        })
      }

      this.$tracker.contactFormClick('page')
    },
    onSubmitContactForm(formData) {
      if (this.modalsContactStatus) {
        // SEO
        if (process?.browser && process?.env?.gtm_enabled) {
          this.$gtm.push({
            event: 'confirmation_contact_card',
            make: null,
            model: null,
            version: null,
            source: this.$store.getters['campaign/getSource'],
            medium: this.$store.getters['campaign/getMedium'],
            campaign: this.$store.getters['campaign/getCampaign'],
          })
        }

        this.$tracker.contactFormCompleted({
          ...formData,
          origin: formData.origin,
          vehicle_type: null,
          product_condition: null,
          status: this.modalsContactStatus,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.catalog-card-usp {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 1.75rem 1.25rem;
  overflow: hidden;
  border: 0.0625rem solid $c-neutral-500;
  border-radius: 0.75rem;
  background-color: $c-neutral-200;

  a {
    position: relative;
    z-index: 2;
  }

  p {
    margin: 0;

    & + p {
      margin-top: 1.4em;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 4.5rem;
  }

  &__main {
    .icon,
    .title,
    .description {
      color: $text-primary;
    }
  }

  &__footer {
    position: relative;
    z-index: 2;
    margin-top: auto;

    :deep(.button) {
      width: 100%;

      &.-secondary-light {
        border-color: $c-white;
      }
    }
  }

  &--buying-power,
  &--contact {
    border-color: $c-primary-800;
    background-color: $c-primary-800;

    .icon,
    .title,
    .description {
      color: $c-white;
    }
  }

  &--trade-in {
    border-color: $c-primary-700;
    background-color: $c-primary-700;

    .icon,
    .title,
    .description {
      color: $c-white;
    }
  }

  &--alert {
    border-color: $c-support-01-500;
    background-color: $c-support-01-500;
  }

  &--financing {
    border-color: $c-support-02-500;
    background-color: $c-support-02-500;
  }

  &--warranty {
    border-color: $c-neutral-200;
    background-color: $c-neutral-300;
  }

  &--eco-vehicles {
    background-color: #61cfa7;
  }
}
</style>
