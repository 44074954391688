export const digestMessage = async (message) => {
  if (!message) return null

  try {
    const encoder = new TextEncoder()
    const data = encoder.encode(message)
    const hashBuffer = await crypto.subtle.digest('SHA-256', data)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
    return hashHex
  } catch {
    return null
  }
}

export const concatValues = (separator) => {
  return (value) => (Array.isArray(value) ? value.join(separator) : value)
}

export const isContainerVisible = (container, topGap = 0, bottomGap = 0) => {
  if (!container) return false

  const bounds = container.getBoundingClientRect()

  return (
    (bounds.top < window.innerHeight && bounds.top >= topGap) ||
    (bounds.bottom > bottomGap && bounds.bottom <= window.innerHeight)
  )
}

export const isContainerInViewport = (element) => {
  try {
    const bounds = element.getBoundingClientRect()
    const sectionCenter = bounds.top + bounds.height / 1.6
    return sectionCenter >= 0 && sectionCenter <= window.innerHeight
  } catch {
    return undefined
  }
}

export const generateIdFromString = (str) => {
  if (!str) return undefined

  const replaceSpecialCharacters = (text) => {
    return text
      .replace(/á/g, 'a')
      .replace(/é/g, 'e')
      .replace(/í/g, 'i')
      .replace(/ó/g, 'o')
      .replace(/ú/g, 'u')
      .replace(/ñ/g, 'n')
  }

  return replaceSpecialCharacters(str.toLowerCase()).replace(/[\W0-9]+/g, '')
}

export const getThunderLegalTextsWithTitleIds = (htmlString) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const h2Elements = doc.querySelectorAll('h2')

  h2Elements.forEach((h2) => {
    const h2Text = h2.textContent
    if (h2Text) {
      h2.id = generateIdFromString(h2Text)
    }
  })

  return doc.body.innerHTML
}

export const stripHtml = (string) => {
  return string
    .replace(/<[^>]*>/g, '')
    .replace(/&gt;/g, '')
    .replace(/&amp;/g, '&')
    .replace(/\n/g, '')
    .trim()
}

export const camelToSnake = (string) => {
  return string.replace(/[A-Z]/g, (match) => '_' + match.toLowerCase())
}

/*
 * The Levenshtein algorithm (https://en.wikipedia.org/wiki/Levenshtein_distance)
 * calculates the Levenshtein distance between two strings, `a` and `b`, which
 * is a metric for how different they are. That is, how many basic operations
 * are needed to transform one string into another.
 */
export const calculateStringSimilarity = (a, b) => {
  const matrix = []

  let i
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i]
  }

  let j
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j
  }

  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1]
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j] + 1,
        )
      }
    }
  }

  return matrix[b.length][a.length]
}

export const generateUniqueId = () => {
  const timestamp = Date.now()
  const randomNum = Math.floor(Math.random() * 10000)

  return `${timestamp}-${randomNum}`
}

export default {
  camelToSnake,
  concatValues,
  digestMessage,
  generateIdFromString,
  getThunderLegalTextsWithTitleIds,
  isContainerInViewport,
  isContainerVisible,
  calculateStringSimilarity,
  generateUniqueId,
}
