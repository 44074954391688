<template>
  <div v-if="icon || label" class="flag">
    <Icon v-if="icon" :name="icon" :filled="filledIcon" size="1.25rem" />
    <span v-if="label" class="f-size-14 f-700 f-uppercase">{{ label }}</span>
  </div>
</template>

<script>
// Components
import Icon from '@/components/Icon.vue'

export default {
  name: 'PartialFlag',
  components: {
    Icon,
  },
  props: {
    label: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    icon: {
      type: [String, undefined],
      required: false,
      default: undefined,
    },
    filledIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.flag {
  display: inline-flex !important;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  background-color: var(--background-color);
  gap: 0.25rem;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -0.5625rem;
    width: 0.625rem;
    height: 100%;
    background-color: var(--background-color);
    clip-path: polygon(100% 0, 0 51%, 100% 100%, 0% 100%, 0 52%, 0% 0%);
  }

  .icon {
    color: var(--icon-color);
  }

  span {
    color: var(--color);
  }
}
</style>
