export function getBrowserName() {
  const browserInfo = navigator.userAgent

  let browser

  if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
    browser = 'opera'
  } else if (browserInfo.includes('Edg')) {
    browser = 'edge'
  } else if (browserInfo.includes('Chrome')) {
    browser = 'chrome'
  } else if (browserInfo.includes('Safari')) {
    browser = 'safari'
  } else if (browserInfo.includes('Firefox')) {
    browser = 'firefox'
  } else {
    browser = 'unknown'
  }

  return browser
}
