<template>
  <div :id="id" class="radio-group">
    <p v-if="label" class="radio-group__label">
      {{ label }}
    </p>
    <div :class="['radio-group__items', { 'radio-group__items--as-column': asColumn }]">
      <div v-for="(option, index) in options" :key="index" class="radio-item">
        <RadioButton
          :id="`radio-item-${option.value}-${index}`"
          :name="name"
          :checked="option.value === value"
          :value="option.value"
          :label="option.label"
          :loading="loading"
          @input="$emit('input', option.value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RadioButton from '@/components/RadioButton.vue'

export default {
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input',
  },
  components: {
    RadioButton,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    asColumn: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      id: null,
    }
  },
  mounted() {
    this.id = this._uid
  },
}
</script>

<style lang="scss" scoped>
.radio-group {
  &__label {
    display: block;
    margin: 0;
    text-align: left;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.25rem;
    margin-top: 0.625rem;

    &--as-column {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
  }
}
</style>
