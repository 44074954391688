export const state = () => ({
  contactEmail: '',
  server: '',
})

export const getters = {
  getServer: (state) => state.server,
  getEmail: (state) => state.contactEmail,
}

export const mutations = {
  setServer: (state, server) => (state.server = server),
  setEmail: (state, contactEmail) => (state.contactEmail = contactEmail),
}

export const actions = {
  async sendContactForm({ state }, { from, body, env }) {
    const url = `${state.server}/api/sendForm`

    const payload = {
      template_id: 'd-78e797bf4f214b36943e6621907a3fde',
      to: state.contactEmail,
      from,
      dynamic_template_data: body,
    }

    const res = await this.$axios.$post(url, payload)

    return res
  },
}
