// Usage: This middleware is used to auto login the user if the user has the token and uid in the query params.
// key1 = 'token'
// key2 = 'uid'
export default async function ({ route, redirect, store, app }) {
  if (route.query.key1 && route.query.key2) {
    await store.dispatch('auth/logout')
    const result = await store.dispatch('auth/loginWithOtp', {
      key1: route.query.key1,
      key2: route.query.key2,
    })
    if (result) {
      redirect(route.path)
    } else {
      app.context.error({
        status: 401,
        type: 'auto_login',
      })
    }
  }
}
