// This plugin allows to customize axios by registering interceptors and changing global config
// Docs: https://axios.nuxtjs.org/extend
export default function (context) {
  const { $axios, app, store, redirect } = context

  $axios.onRequest((config) => {
    config.headers.common.API_KEY =
      app.store.getters.getApiKey ||
      app.context.env.config.countries[app.i18n.locale.split('_')[0]].apiKey

    if (store.state.languageIso) {
      config.headers.common['accept-language'] = store.state.languageIso
    }
  })

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)

    // The jwt token has expired, the user needs to log in again
    if ([403].includes(code)) {
      const { name, fullPath } = app.router.currentRoute

      // If we are already trying to log in, this would cause infinite loops
      if (
        !name.startsWith('sign-in') &&
        !name.startsWith('password-reset') &&
        !name.startsWith('uid-request-booking') &&
        !name.startsWith('uid-request-finance') &&
        !name.startsWith('coche-store-selection')
      ) {
        app.store.dispatch('auth/sign-out')
        const redirectTo = encodeURIComponent(fullPath)
        redirect(app.localePath('sign-in') + `?return=${redirectTo}`)
      }
    }
  })
}
