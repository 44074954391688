import { decode } from 'jsonwebtoken'

const getToken = ($cookiez) => {
  try {
    return $cookiez.get('token')?.replace('Bearer ', '') || undefined
  } catch {
    return undefined // JSON SyntaxError still raises, though
  }
}

const tokenIsValid = (token) => {
  try {
    return token && decode(token).exp * 1000 > Date.now()
  } catch {
    return false
  }
}

export default async function ({ app: { $cookiez }, store }) {
  const token = getToken($cookiez)

  if (token) {
    if (tokenIsValid(token)) {
      await store.dispatch('auth/refreshToken', { token: `Bearer ${token}` })
    } else {
      await store.dispatch('auth/logout')
    }
  }
}
