import { format, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'

export const errorDateLimitFromToday = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth()
  const day = today.getDate()
  return `${day.toString().padStart(2, '0')}/${(month + 1).toString().padStart(2, '0')}/${year}`
}

export const errorDateTodayLess100Years = () => {
  const today = new Date()
  const year = today.getFullYear() - 100
  const month = today.getMonth()
  const day = today.getDate()
  return `${day.toString().padStart(2, '0')}/${(month + 1).toString().padStart(2, '0')}/${year}`
}

export const formatDate = (value) => {
  try {
    if (!value) return ''
    return format(parseISO(value), 'dd/MM/yyyy', { locale: es })
  } catch {
    return ''
  }
}

export const formatDateAlt = (value) => {
  try {
    if (!value) return ''
    return format(parseISO(value), 'dd.MM.yyyy', { locale: es })
  } catch {
    return ''
  }
}

export const formatDateAsMonthYear = (value) => {
  try {
    if (!value) return ''
    return format(parseISO(value), 'MMMM, yyyy', { locale: es })
  } catch {
    return ''
  }
}

export const limitFromToday = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth()
  const day = today.getDate()
  return `${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
}

export const todayLess100Years = () => {
  const today = new Date()
  const year = today.getFullYear() - 100
  const month = today.getMonth()
  const day = today.getDate()
  return `${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
}

export default {
  errorDateLimitFromToday,
  errorDateTodayLess100Years,
  formatDate,
  formatDateAlt,
  formatDateAsMonthYear,
  limitFromToday,
  todayLess100Years,
}
