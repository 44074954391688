<template>
  <main id="layoutLocalize" class="container container--pv">
    <Modal open class="localize-dialog" :label="title">
      <nuxt />
    </Modal>
  </main>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
  name: 'LayoutsLocalize',
  scrollToTop: true,
  components: {
    Modal,
  },
  data() {
    return {
      title: '',
    }
  },
  methods: {
    setTitle(title) {
      this.title = title
    },
  },
  provide() {
    return {
      setLocalizeLayoutTitle: this.setTitle,
    }
  },
  async beforeCreate() {
    await this.$store.dispatch('campaign/fetchData', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
}
</script>

<style lang="scss" scoped>
#layoutLocalize {
  background-image: url('/images/home-large.webp');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  main {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }
  }
}
</style>
