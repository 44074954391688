export default ({ app }, inject) => {
  // Dependencies
  const i18n = app.i18n

  // Methods
  const displayNotification = (
    type,
    title,
    message,
    actions,
    horizontal,
    waitForAction = false,
    duration = 5000,
  ) => {
    const now = Date.now()
    const actualDuration = duration || 8000

    let promise
    let actualActions

    const notification = {
      id: now.toString(),
      duration: actualDuration,
      expiration: now + actualDuration,
      displayed: true,
      horizontal,
      data: {},
    }

    if (waitForAction && actions) {
      let resolveFn

      promise = new Promise((resolve) => {
        resolveFn = resolve
      })

      actualActions = []

      actions.forEach((action) => {
        if (action.key) {
          actualActions.push({
            label: action.label,
            fn: () => {
              app.store.commit('notifications/close', notification.id)
              resolveFn(action.key)
            },
          })
        }
      })

      notification.onClose = () => {
        app.store.commit('notifications/close', notification.id)
        resolveFn('close')
      }
    } else {
      actualActions = actions

      notification.onClose = () => {
        app.store.commit('notifications/close', notification.id)
      }
    }

    notification.data = {
      type,
      title,
      message,
      actions: actualActions,
    }

    app.store.commit('notifications/add', notification)

    if (waitForAction && actions) {
      return promise
    }
  }

  const displayPredefinedNotification = (
    type,
    key,
    titleString = '',
    messageString = '',
    actions = undefined,
    horizontal = false,
  ) => {
    const title = i18n.t(`messages.${type}.${key}.title`, { name: titleString })
    const message = i18n.t(`messages.${type}.${key}.message`, { name: messageString })
    displayNotification(type, title, message, actions, !titleString ? true : horizontal)
  }

  inject('displayNotification', displayNotification)
  inject('displayPredefinedNotification', displayPredefinedNotification)
}
