import Vue from 'vue'

export default ({ app }, inject) => {
  inject('nemuru', Vue.observable({ loaded: false }))
  Vue.mixin({
    methods: {
      nemuruAvailable() {
        try {
          return window.Nemuru && window.Nemuru.calculateConditions
        } catch {
          return false
        }
      },
      /**
       * It launches a simulation using the Nemuru calculator widget
       *
       * @param {float} financingPriceCents - The vehicle price to finance
       * @param {number} months - Number of months to finance
       * @param {string} tariffName - Financing product name
       * @returns {Array}
       */
      nemuruGetSimulation(financingPriceCents, months, tariffName) {
        if (!this.nemuruAvailable()) return false

        const terms = Array.isArray(months) ? months : [parseInt(months)]
        const amount = financingPriceCents / 100
        let result = window.Nemuru.calculateConditions({
          conditions: {
            amount,
            product: 'auto_loan',
            terms,
            chooseStrategy: false,
          },
          onError: function (error) {
            // eslint-disable-next-line no-console
            console.log('ON ERROR CALLBACK', error)
            return []
          },
        })
        if (result === undefined || result.length === 0) return null
        result = result.filter((x) => x.pricingName === tariffName)
        if (result === undefined || result.length === 0) return null
        return result // return simulation object
      },
      nemuruGetInstallmentAmount(financingPriceCents, months, vehicleType) {
        if (!this.nemuruAvailable()) return false

        const terms = Array.isArray(months) ? months : [parseInt(months)]
        const amount = financingPriceCents / 100
        let result = window.Nemuru.calculateConditions({
          conditions: {
            amount,
            product: 'auto_loan',
            terms,
            chooseStrategy: false,
          },
          onError: function (error) {
            // eslint-disable-next-line no-console
            console.log('ON ERROR CALLBACK', error)
            return []
          },
        })
        if (result === undefined || result.length === 0) return null
        result =
          vehicleType.toLowerCase() === 'moto'
            ? result.filter((x) => x.pricingName.includes('motos'))
            : result.filter((x) => x.pricingName.includes('coches'))
        if (result === undefined || result.length === 0) return null
        return result[0].instalmentAmount.string // return installment amount
      },
      nemuruGetAvailableConditions(financingPriceCents, maxFinancingTerm) {
        const amount = financingPriceCents / 100

        const result = window.Nemuru.availableConditions({
          amount,
          onError: function (error) {
            // eslint-disable-next-line no-console
            console.error('Nemuru callback error while fetching conditions:', error)
            return []
          },
        })
        if (result === undefined || result.length === 0) return null
        result[0].terms = result[0].terms.filter((term) => {
          return term <= maxFinancingTerm
        })
        return result[0]
      },
    },
  })
}
