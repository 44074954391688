import { gql } from 'graphql-tag'

const modelsFilterQuery = gql`
  query modelsFilterQuery(
    $vehicleTypeIn: [String!]
    $makeSlug: String
    $categoryIn: [String!]
    $dealerUidIn: [String!]
    $language: String
  ) {
    models(
      vehicleTypeIn: $vehicleTypeIn
      make: { slugLike: $makeSlug }
      sortBy: "name"
      vehicles: { categoryIn: $categoryIn, dealerUidIn: $dealerUidIn }
    ) {
      nodes {
        name
        slug
        metaTitle(language: $language)
        metaDescription(language: $language)
      }
    }
  }
`
export default modelsFilterQuery
