<template>
  <PartialsSubmenu v-if="availableLanguages.length > 1" class="lang-switcher">
    <template v-slot:header>
      <Icon name="language" size="1.25rem" />
      <span>{{ $i18n.locale.split('_')[1] }}</span>
    </template>
    <template v-slot:menu>
      <span
        v-for="language in availableLanguages"
        :key="language.code"
        :aria-label="$t(`accesibility.language_${language.code.split('_')[1].toLowerCase()}`)"
        :class="{ 'current-locale': $i18n.locale === language.code }"
        @click="switchToLang(language.code)"
      >
        <span>{{ language.name }}</span>
        <icon name="check" />
      </span>
    </template>
  </PartialsSubmenu>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'

// Components
import Icon from '~/components/Icon.vue'
import PartialsSubmenu from '~/components/partials/Submenu.vue'

export default {
  name: 'PartialLangSwitcher',
  components: {
    Icon,
    PartialsSubmenu,
  },
  props: {
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    scroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      country: 'getCountry',
      availableLanguages: 'getCountryLanguages',
    }),
  },
  methods: {
    switchToLang(language) {
      this.$localize.setValidLanguage(language)
    },
  },
}
</script>

<style lang="scss">
.lang-switcher .submenu__dropdown__menu span {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    margin: 0;
  }

  &:not(.current-locale) .icon {
    display: none !important;
  }

  &.current-locale {
    color: $submenu-color-hover;

    span {
      color: inherit;
    }
  }
}
</style>
