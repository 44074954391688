import { gql } from 'graphql-tag'
const vehiclesCarsQuery = gql`
  query vehiclesCarsQuery(
    $makeSlug: String
    $modelName: String
    $page: Int
    $perPage: Int
    $registrationDate: Int
    $kilometresGte: Int
    $kilometresLte: Int
    $sortByFinancingPrice: String
    $sortByRelevance: Boolean
    $categoryIn: [String!]
    $useCasesIn: [String!]
    $fuelTypeIn: [String!]
    $powertrainTypeIn: [String!]
    $transmissionTypeIn: [String!]
    $discountedPriceCentsBetween: [Int!]
    $discountedPriceCentsGte: Int
    $discountedPriceCentsLte: Int
    $instalmentPriceCentsBetween: [Int!]
    $instalmentPriceCentsGte: Int
    $instalmentPriceCentsLte: Int
    $bodyworkIn: [String!]
    $environmentalLabels: [String!]
    $dealerUidIn: [String!]
    $financialEntityName: String
    $language: String
  ) {
    vehicles(
      sortByFinancingPrice: $sortByFinancingPrice
      sortByRelevance: $sortByRelevance
      page: $page
      perPage: $perPage
      categoryIn: $categoryIn
      useCasesIn: $useCasesIn
      registrationDateYear: $registrationDate
      kilometresGte: $kilometresGte
      kilometresLte: $kilometresLte
      make: { slugLike: $makeSlug }
      model: { nameLike: $modelName, vehicleTypeIn: ["car", "van"] }
      version: {
        specs: {
          fuelTypeIn: $fuelTypeIn
          powertrainTypeIn: $powertrainTypeIn
          transmissionTypeIn: $transmissionTypeIn
        }
        bodyworkIn: $bodyworkIn
        environmentalLabelIn: $environmentalLabels
        vehicles: { categoryIn: $categoryIn }
      }
      discountedPriceCentsBetween: $discountedPriceCentsBetween
      discountedPriceCentsGte: $discountedPriceCentsGte
      discountedPriceCentsLte: $discountedPriceCentsLte
      instalmentPriceCentsLte: $instalmentPriceCentsLte
      instalmentPriceCentsGte: $instalmentPriceCentsGte
      instalmentPriceCentsBetween: $instalmentPriceCentsBetween
      dealerUidIn: $dealerUidIn
    ) {
      nodes {
        useCases
        uid
        specs
        hero {
          file
        }
        vehicleConfig {
          slug
          specs
          hero {
            file
          }
          colors {
            name
            surfaceType
          }
          extraEquipment(language: $language)
        }
        make {
          name
          slug
        }
        model {
          name
          slug
          publishableUnits
        }
        version {
          name
          slug
          specs
          environmentalLabel
        }
        registrationDate
        kilometres
        manufacturerRef
        salesPricing(financialEntityName: $financialEntityName)
      }
      nodesCount
      pagesCount
      minKilometres
      maxKilometres
    }

    models(
      make: { slugLike: $makeSlug }
      nameLike: $modelName
      vehicles: {
        categoryIn: $categoryIn
        useCasesIn: $useCasesIn
        kilometresGte: $kilometresGte
        kilometresLte: $kilometresLte
        dealerUidIn: $dealerUidIn
      }
      versions: {
        bodyworkIn: $bodyworkIn
        environmentalLabelIn: $environmentalLabels
        specs: {
          fuelTypeIn: $fuelTypeIn
          powertrainTypeIn: $powertrainTypeIn
          transmissionTypeIn: $transmissionTypeIn
        }
        vehicles: {
          categoryIn: $categoryIn
          kilometresGte: $kilometresGte
          kilometresLte: $kilometresLte
        }
      }
      discountedPriceCentsBetween: $discountedPriceCentsBetween
      discountedPriceCentsGte: $discountedPriceCentsGte
      discountedPriceCentsLte: $discountedPriceCentsLte
      instalmentPriceCentsLte: $instalmentPriceCentsLte
      instalmentPriceCentsGte: $instalmentPriceCentsGte
      instalmentPriceCentsBetween: $instalmentPriceCentsBetween
      vehicleTypeIn: ["car", "van"]
    ) {
      nodesCount
    }
  }
`
export default vehiclesCarsQuery
