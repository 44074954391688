export const state = () => ({
  campaignData: {},
})

export const mutations = {
  setCampaignData(state, campaignData) {
    state.campaignData = { ...state.campaignData, ...campaignData }

    if (process.client && sessionStorage) {
      const storedState = sessionStorage.getItem('astara_store')

      let currentState
      if (storedState) {
        currentState = JSON.parse(storedState)
      }

      sessionStorage.setItem(
        'astara_store',
        JSON.stringify({
          ...currentState,
          campaignData: state.campaignData,
        }),
      )
    }
  },
}

export const actions = {
  initializeStore({ state, commit }) {
    if (process.client && sessionStorage) {
      const storedState = sessionStorage.getItem('astara_store')
      let currentState

      if (storedState) {
        currentState = JSON.parse(storedState)
      }

      if (!Object.keys(state?.campaignData || {}).length) {
        if (currentState && currentState.campaignData) {
          commit('setCampaignData', currentState.campaignData)
        }
      } else {
        const newState = {
          ...currentState,
          campaignData: state.campaignData,
        }
        sessionStorage.setItem('astara_store', JSON.stringify(newState))
      }
    }
  },

  async fetchData({ state, commit, dispatch }, query = undefined) {
    if (Object.keys(state.campaignData)?.length) {
      await dispatch('initializeStore')
      return
    }

    const campaignData = {}
    let foundParams = false

    if (query) {
      // Extract campaign data from query params
      try {
        Object.keys(query).forEach((key) => {
          if (key.startsWith('utm_')) {
            const newKey = key.slice(4)
            campaignData[newKey] = query[key]
            foundParams = true
          } else if (key === 'test_campaign') {
            campaignData.testing = query[key]
            foundParams = true
          }
        })
      } catch {
        foundParams = false
      }
    }

    // Get campaigns from Thunder
    try {
      const campaigns = await this.$campaignsService.list()

      campaignData.inSiteCampaigns = campaigns
        ?.filter((campaign) => {
          if (campaignData.testing === campaign.slug) {
            return true
          }

          const currentDate = new Date()
          const startDate = new Date(campaign.startDateTime)
          const endDate = new Date(campaign.endDateTime)
          return currentDate >= startDate && currentDate <= endDate
        })
        ?.sort((a, b) => {
          return new Date(b.endDateTime) - new Date(a.startDateTime)
        })
    } catch {
      campaignData.inSiteCampaigns = []
    }

    // Save data locally
    if (foundParams || campaignData.inSiteCampaigns) {
      commit('setCampaignData', campaignData)
    }
  },
}

export const getters = {
  getCampaignData: (state) => state?.campaignData,
  getSource: (state) => state.campaignData?.source || null,
  getMedium: (state) => state.campaignData?.medium || null,
  getCampaign: (state) => state.campaignData?.campaign || null,
  getContent: (state) => state.campaignData?.content || null,
  getTerm: (state) => state.campaignData?.term || null,
  getAction: (state) => state.campaignData?.action || null,
  getTesting: (state) => state.campaignData?.testing || null,
  getActiveCampaigns: (state) => state.campaignData?.inSiteCampaigns || null,
}
