<template>
  <section id="newsletter" class="newsletter">
    <div class="container container--pv">
      <div class="newsletter__content">
        <template v-if="!requested">
          <header class="newsletter__content__header">
            <Icon name="mark_email_unread" size="2rem" />
            <p class="section-title">{{ $t('modules.newsletter.subscribe_title') }}</p>
            <p>{{ $t('modules.newsletter.subscribe_description') }}</p>
          </header>

          <ValidationObserver v-slot="{ validate }">
            <form class="newsletter__content__form" @submit.prevent="onSubmit(validate)">
              <Notification
                v-if="error"
                :message="$t(`messages.error.${error}.message`)"
                :title="
                  $te(`messages.error.${error}.title`)
                    ? $t(`messages.error.${error}.title`)
                    : undefined
                "
                displayed
                error
                inline
                @close="() => (error = '')"
              />

              <div class="container-name">
                <ValidationProvider
                  v-slot="{ touched, errors }"
                  :custom-messages="customNameError"
                  :name="$t('modules.newsletter.name')"
                  :skip-if-empty="false"
                  rules="required|max:40"
                >
                  <Input
                    v-model="user.name"
                    :error="touched ? errors[0] : null"
                    :label="`${$t('modules.newsletter.name')}`"
                    :placeholder="$t('modules.newsletter.name_placeholder')"
                    :maxlength="40"
                    name="name"
                    required
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ touched, errors }"
                  :custom-messages="customLastNameError"
                  :name="$t('modules.newsletter.last_name')"
                  :skip-if-empty="false"
                  rules="required|max:80"
                >
                  <Input
                    v-model="user.last_name"
                    :error="touched ? errors[0] : null"
                    :label="`${$t('modules.newsletter.last_name')}`"
                    :placeholder="$t('modules.newsletter.last_name_placeholder')"
                    :maxlength="80"
                    name="last_name"
                    required
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ touched, errors }"
                  :name="$t('modules.newsletter.second_last_name')"
                  :skip-if-empty="false"
                  rules="max:80"
                >
                  <Input
                    v-model="user.second_last_name"
                    :error="touched ? errors[0] : null"
                    :label="`${$t('modules.newsletter.second_last_name')}`"
                    :placeholder="$t('modules.newsletter.second_last_name_placeholder')"
                    :maxlength="80"
                    name="second_last_name"
                  />
                </ValidationProvider>
              </div>

              <ValidationProvider
                v-slot="{ touched, errors }"
                :skip-if-empty="false"
                :name="$t('modules.newsletter.email')"
                rules="required|email"
                :custom-messages="customEmailError"
                class="email-input"
              >
                <Input
                  v-model="user.email"
                  :error="touched ? errors[0] : null"
                  :label="$t('modules.newsletter.email')"
                  :placeholder="$t('modules.newsletter.email_placeholder')"
                  name="newsletter-email"
                  type="email"
                  required
                  data-cy="newsletter-email-input"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                :skip-if-empty="false"
                :rules="{ required: { allowFalse: false } }"
                :custom-messages="customLegalTermsError"
                :name="$t('signup.form.terms')"
              >
                <Checkbox
                  v-model="user.terms_of_service_accepted"
                  name="terms_of_service_accepted"
                  size="s"
                  :error="errors[0]"
                  required
                  data-cy="terms-checkbox"
                  class="checkbox-comp"
                >
                  <span>
                    {{ $t('signup.form.terms_use')[0] }}
                    <NuxtLink
                      class="f-as-link"
                      :to="localePath('use-terms')"
                      target="_blank"
                      rel="noopener"
                    >
                      {{ $t('signup.form.terms_use')[1] }}
                    </NuxtLink>
                    {{ $t('signup.form.terms_use')[2] }}
                    <NuxtLink
                      class="f-as-link"
                      :to="localePath('privacy-policy')"
                      target="_blank"
                      rel="noopener"
                    >
                      {{ $t('signup.form.terms_use')[3] }}
                    </NuxtLink>
                    {{ $t('signup.form.terms_use')[4] }}
                  </span>
                </Checkbox>
              </ValidationProvider>

              <div class="container-buttons container-buttons--justify-right">
                <Button
                  type-theme="primary-light"
                  size="large"
                  :loading="loading"
                  data-cy="newsletter-send-button"
                >
                  {{ $t('modules.newsletter.submit') }}
                </Button>
              </div>
            </form>
          </ValidationObserver>
        </template>
        <template v-else>
          <div class="newsletter__content__success">
            <Icon name="check_title" size="2.5rem" />
            <div class="newsletter__content__success__body">
              <h2>
                {{ $t('modules.newsletter.success.title') }}
              </h2>
              <p class="size-18 text-secondary">
                {{ $t('modules.newsletter.success.message') }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
// Dependencies
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// Helpers
import checkViewportSize from '@/mixins/checkViewportSize.js'
import { getFullPageUrl } from '@/helpers/urls'

// Components
import Button from '@/components/Button.vue'
import Checkbox from '@/components/Checkbox.vue'
import Icon from '@/components/Icon.vue'
import Input from '@/components/Input.vue'
import Notification from '@/components/Notification.vue'

export default {
  name: 'ModuleNewsletter',
  mixins: [checkViewportSize],
  components: {
    Button,
    Checkbox,
    Icon,
    Input,
    Notification,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      loading: false,
      error: false,
      requested: false,
      user: {
        name: '',
        last_name: '',
        second_last_name: '',
        email: '',
        terms_of_service_accepted: false,
        origin: 'newsletter',
        meta: {
          origin_process: 'Contact',
          origin: this.$store.getters['campaign/getSource'] || 'Newsletter',
          medium: this.$store.getters['campaign/getMedium'],
          campaign: this.$store.getters['campaign/getCampaign'],
          legalTratamientoDatos: this.$t('signup.form.terms_use').join(' '),
          legalComunicaciones: this.$t('signup.form.communications'),
        },
      },
      customNameError: {
        required: this.$t('modules.newsletter.errors.name'),
      },
      customLastNameError: {
        required: this.$t('modules.newsletter.errors.last_name'),
      },
      customEmailError: {
        required: this.$t('modules.newsletter.errors.email'),
        email: this.$t('modules.newsletter.errors.email'),
      },
      customLegalTermsError: {
        required: this.$t('modules.newsletter.errors.legal_terms'),
      },
    }
  },
  methods: {
    async onSubmit(validate) {
      this.loading = true
      this.error = ''

      await validate().then(async (isValid) => {
        if (!isValid) {
          this.error = 'invalid_fields'
          this.loading = false
        } else {
          this.user.meta.financial_onboarding_url = getFullPageUrl(this.$route, true, false)

          try {
            await this.$leadService
              .sendLead(this.user)
              .then(() => {
                if (process?.browser && process?.env?.gtm_enabled) {
                  this.$gtm.push({ event: 'newsletter-form-submit' })
                }
              })
              .catch(() => {
                this.error = 'service'
              })
          } catch {
            this.error = 'service'
          } finally {
            this.loading = false
            this.requested = true
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.newsletter {
  display: flex;
  align-items: center;
  min-height: 39.8125rem;
  background: linear-gradient(324deg, #e4e3e5 49.96%, #f8f7fa 89.89%);

  &__content {
    max-width: 55.5rem;
    margin: 0 auto;
  }

  &__content__header {
    margin-bottom: 2.5rem;

    > *:not(:first-child) {
      margin-top: 0.5rem;
    }

    @include size-s-up {
      text-align: center;
    }
  }

  &__content__form {
    display: flex;
    flex-direction: column;

    .input-wrapper,
    :deep(.checkbox-container) {
      margin: 0;
    }

    > *:not(:first-child) {
      margin-top: 1rem;
    }

    .container-name {
      gap: 1.5rem;

      @include size-m-up {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      @include size-s {
        .input-wrapper {
          margin-top: 1rem;
        }
      }
    }

    .container-buttons {
      margin-top: 2.5rem;
    }
  }

  &__content__success {
    display: flex;
    gap: 1rem;

    .icon {
      width: 2.5rem !important;
      height: 2.5rem !important;
      border-radius: 1.25rem;
      background: #e6f5ef;
      font-size: 2.5rem !important;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
</style>
