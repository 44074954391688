var render = function render(){var _vm=this,_c=_vm._self._c;return _c('picture',{class:[
    'image-imgix',
    _vm.xclass,
    {
      'image-imgix--fallback': _vm.imgixError,
    },
  ],attrs:{"data-cy":"image-imgix"}},[_c('img',{ref:"image",attrs:{"alt":_vm.alt,"decoding":_vm.required ? undefined : 'async',"fetchpriority":_vm.required ? 'medium' : 'low',"loading":_vm.required ? undefined : 'lazy',"srcset":_vm.imageSrcset,"src":_vm.imageSrc},on:{"error":() => _vm.setupFallbackImage(_vm.url),"click":function($event){return _vm.$emit('click')}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }