import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'

Vue.use(VueApollo)

export default function ({ app }) {
  const httpLink = new HttpLink({
    uri: app.context.env.config.apiUrl.replace(/api$/, 'graphql'),
  })

  const authLink = setContext((_, { headers }) => {
    const customHeaders = {
      API_KEY:
        app.store.getters.getApiKey ||
        app.context.env.config.countries[app.i18n.locale.split('_')[0]].apiKey,
      authorization: app.$cookiez.get('token'),
    }
    return {
      headers: {
        ...headers,
        ...customHeaders,
      },
    }
  })

  const link = authLink.concat(httpLink)

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    connectToDevTools: true,
    ssrMode: app.ssr,
    defaultOptions: {
      loadingKey: 'loading',
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  })

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.error('Global error handler', error)
    },
    // watchLoading(isLoading) {
    //   // eslint-disable-next-line no-console
    //   console.log('Global loading handler', isLoading)
    // },
    prefetch: true,
    prefetchMaxPending: 5,
    prefetchOnMount: true,
  })

  app.apolloProvider = apolloProvider

  app.$apolloProvider = apolloProvider
}
