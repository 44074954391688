var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabbed-layout"},[_c('header',{staticClass:"page__header"},[_c('div',{staticClass:"container"},[(_vm.breadcrumb?.length)?_c('Breadcrumb',{attrs:{"breadcrumb-links":_vm.breadcrumb}}):_vm._e(),_vm._v(" "),_c('h1',{staticClass:"section-title"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]),_vm._v(" "),_c('nav',{ref:"swiper",staticClass:"tabs swiper"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.tabs),function(tab){return _c('NuxtLink',{key:tab.key,class:[
              'tabs__tab',
              'f-not-as-link',
              'swiper-slide',
              {
                'tabs__tab--active': tab.key === _vm.currentTab,
              },
            ],attrs:{"to":tab.url}},[(tab.icon)?_c('Icon',{attrs:{"name":tab.icon,"size":"1.25rem"}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(tab.label)+"\n            ")])],1)}),1)])],1)]),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }