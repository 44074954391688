const BASE_URL = '/v1'

export default ($axios, language) => ({
  list() {
    return $axios.$get(`${BASE_URL}/campaigns?language=${language}`, { params: { enabled: true } })
  },
  get(slug) {
    return $axios.$get(`${BASE_URL}/campaigns/${slug}?language=${language}`)
  },
})
