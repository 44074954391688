const requireServices = require.context('../services', false, /[\w-]+Service\.js$/)

export default (ctx, inject) => {
  requireServices.keys().forEach((fileName) => {
    const serviceExport = requireServices(fileName)
    const serviceName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')

    // Inject the service with both axios and i18n
    const serviceExportFunction = serviceExport.default || serviceExport
    inject(
      serviceName,
      serviceExportFunction(ctx.$axios, ctx.app.i18n?.locale?.split('_')[1]?.toLowerCase() || 'es'),
    )
  })
}
