const normaliseModelData = (model, category) => {
  let version
  try {
    version = model?.versions[0].filter((version) => version.publishable)[0]
  } catch {
    version = undefined
  } finally {
    if (!version) {
      version = model?.versions ? model.versions[0] : undefined
    }
  }

  let vehicle
  try {
    vehicle = model?.vehicles.filter((vehicle) => vehicle?.vehicleConfig?.publishable)[0]
  } catch {
    vehicle = undefined
  } finally {
    if (!vehicle) {
      vehicle = model?.vehicles ? model.vehicles[0] : undefined
    }
  }

  let versions
  try {
    versions = model?.versions?.filter((version) =>
      version?.vehicleConfigs?.filter((vc) => vc?.publishable),
    )
  } catch {
    versions = undefined
  }

  let vehicleConfigs
  try {
    vehicleConfigs = versions
      ?.map((version) => version?.vehicleConfigs)
      ?.flat()
      ?.filter((vc) => vc?.publishable)
  } catch {
    vehicleConfigs = undefined
  }

  let vehicleConfig
  try {
    vehicleConfig = vehicle?.vehicleConfig || vehicleConfigs[0]
  } catch {
    vehicleConfig = undefined
  }

  let environmentalLabels = model?.environmentalLabels
  if (environmentalLabels && !Array.isArray(environmentalLabels)) {
    environmentalLabels = [environmentalLabels]
  }

  let useCases = []
  try {
    model?.vehicles?.forEach((vehicle) => {
      if (vehicle?.useCases) {
        useCases.push(...vehicle.useCases)
      }
    })

    useCases = [...new Set(useCases)]
  } catch {
    useCases = model?.useCases
  }

  let colors
  try {
    if (vehicleConfigs) {
      colors = vehicleConfigs.map((vc) => ({
        ...vc.colors,
      }))
    }
  } catch {
    colors = undefined
  }

  let modelSpecs
  try {
    modelSpecs = model?.specs
  } catch {
    modelSpecs = undefined
  }

  let engines = {}
  try {
    for (const key of Object.keys(modelSpecs)) {
      if (modelSpecs[key].powertrainTypes && modelSpecs[key].powertrainTypes[0].engines) {
        engines[key] = {
          ...modelSpecs[key].powertrainTypes[0].engines[0],
          ...modelSpecs[key].powertrainTypes[0],
        }
      }
    }
  } catch {
    engines = undefined
  }

  const data = {
    bodywork: version?.bodywork,
    brochure: model?.brochure,
    category,
    characteristic: model?.characteristic?.description,
    colors,
    discounts: vehicle?.discounts,
    drivingLicence: version?.drivingLicence,
    engines,
    environmentalLabels,
    hero: model?.hero?.file,
    heroBackUp: version?.hero?.file || vehicleConfig?.hero?.file || vehicle?.hero?.file,
    kilometres: vehicle?.kilometres,
    makeName: model?.make?.name,
    makeSlug: model?.make?.slug,
    makeUrl: undefined,
    manufacturerRef: vehicle?.manufacturerRef,
    modelName: model?.name,
    modelSlug: model?.slug,
    modelSpecs,
    onlyContactForm: model?.onlyContactForm,
    pricing: undefined,
    publishableUnits: model?.publishableUnits,
    registrationDate: vehicle?.registrationDate,
    salesPricing: model?.salesPricing,
    url: undefined,
    useCases,
    vehicleConfigHero: vehicleConfig?.hero?.file,
    vehicleConfigs,
    vehicleConfigSlug: vehicleConfig?.slug,
    vehicleSpecs: vehicleConfig?.specs,
    vehicleType: model?.vehicleType,
    vehicleUid: vehicle?.uid,
    versionName: version?.name,
    versions,
    versionSlug: version?.slug,
    versionSpecs: version?.specs,
    year: model?.year,
    versionGallery: version?.galleryAssets
      ? version.galleryAssets.map((x) => x.file).reverse()
      : undefined,
  }

  if (data.vehicleType?.toLowerCase() === 'motorbike') {
    data.url = `/moto/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}`
    data.makeUrl = `/motos/${data.makeSlug}`
    data.pricing = model?.salesVnCheapestPrice || model?.salesPricing
  } else {
    switch (category.toLowerCase()) {
      case 'move':
        data.url = `/astara-selection/coche/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}/${data.vehicleConfigSlug}/${data.vehicleUid}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = model?.salesMoveCheapestPrice || model?.salesPricing
        break
      case 'vn':
        data.url = `/coche/nuevo/${data.makeSlug}/${data.modelSlug}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = model?.salesVnCheapestPrice || model?.salesPricing
        break
      case 'km0':
        data.url = `/coche/km0/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}/${data.vehicleConfigSlug}/${data.vehicleUid}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = model?.salesKm0CheapestPrice || model?.salesPricing
        break
      case 'vo':
      default:
        data.url = `/coche/segunda-mano/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}/${data.vehicleConfigSlug}/${data.vehicleUid}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = model?.salesVoCheapestPrice || model?.salesPricing
    }
  }

  return data
}

const normaliseVersionData = (version, category) => {
  let vehicle
  try {
    vehicle = version?.vehicles ? version.vehicles[0] : version.vehicleConfigs[0].vehicles[0]
  } catch {
    vehicle = undefined
  }

  let publishableUnits
  try {
    publishableUnits = version?.publishableUnits || 0
  } catch {
    publishableUnits = 0
  }

  let vehicleConfigs = []
  try {
    if (publishableUnits > 0) {
      vehicleConfigs = version.vehicleConfigs.filter((vehicleConfig) => vehicleConfig.publishable)
    } else {
      vehicleConfigs = version.vehicleConfigs
    }
  } catch {
    vehicleConfigs = version?.vehicleConfigs || undefined
  }

  let vehicleConfig
  try {
    vehicleConfig = vehicleConfigs[0]
  } catch {
    vehicleConfig = undefined
  }

  let colors
  try {
    if (version?.vehicleConfigs) {
      colors = version.vehicleConfigs.map((vc) => ({
        ...vc.colors,
      }))
    }
  } catch {
    colors = undefined
  }

  let environmentalLabels = version?.environmentalLabel || version?.model?.environmentalLabels
  if (environmentalLabels && !Array.isArray(environmentalLabels)) {
    environmentalLabels = [environmentalLabels]
  }

  let versionSpecs
  try {
    versionSpecs = version?.specs
  } catch {
    versionSpecs = undefined
  }

  let engines = {}
  try {
    engines = version?.specs.engines.reduce((acc, engine) => {
      const { engines, ...restSpecs } = version?.specs
      acc[engine.type] = {
        ...engine,
        ...restSpecs,
      }
      return acc
    }, {})
  } catch {
    engines = undefined
  }

  const data = {
    bodywork: version?.bodywork,
    brochure: version?.model?.brochure,
    category,
    characteristic: version?.model?.characteristic?.description,
    colors,
    discounts: version?.discounts,
    drivingLicence: version?.drivingLicence,
    edited: vehicle?.edited,
    engines,
    environmentalLabels,
    equipment: version?.equipmentCategories,
    extraEquipment: vehicleConfig?.extraEquipment,
    hero: version?.hero?.file,
    heroBackUp: vehicleConfig?.hero?.file,
    kilometres: vehicle?.kilometres,
    makeName: version?.make?.name,
    makeSlug: version?.make?.slug,
    makeUrl: undefined,
    manufacturerRef: vehicle?.manufacturerRef,
    metaDescription: version?.metaDescription,
    metaTitle: version?.metaTitle,
    modelName: version?.model?.name,
    modelSlug: version?.model?.slug,
    modelSpecs: version?.model?.specs,
    onlyContactForm: vehicleConfig?.onlyContactForm,
    pricing: undefined,
    publishableUnits,
    registrationDate: vehicle?.registrationDate,
    salesPricing: version?.salesPricing,
    standardEquipment: vehicleConfig?.standardEquipment,
    url: undefined,
    useCases: vehicle ? vehicle?.useCases : version.useCases,
    vehicleConfigHero: vehicleConfig?.hero?.file,
    vehicleConfigs,
    vehicleConfigSlug: vehicleConfig?.slug,
    vehicleSpecs: vehicleConfig?.specs,
    vehicleType: version?.model?.vehicleType,
    vehicleUid: vehicle?.uid,
    versionName: version?.name,
    versionSlug: version?.slug,
    versionSpecs,
    year: version?.model?.year,
    versionGallery: version?.galleryAssets
      ? version.galleryAssets.map((x) => x.file).reverse()
      : undefined,
    exteriorGallery: vehicle?.productAssets
      ? vehicle.productAssets.map((x) => x.file).reverse()
      : undefined,
    interiorGallery: vehicle?.marketingAssets
      ? vehicle.marketingAssets.map((x) => x.file).reverse()
      : undefined,
    versionProductGallery: version?.productAssets
      ? version.version.productAssets.map((x) => x.file).reverse()
      : undefined,
    versionMarketingGallery: version?.productAssets
      ? version.version.marketingAssets.map((x) => x.file).reverse()
      : undefined,
  }

  if (data.vehicleType?.toLowerCase() === 'motorbike') {
    data.url = `/moto/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}`
    data.makeUrl = `/motos/${data.makeSlug}`
    data.pricing = version?.salesVnCheapestPrice
  } else {
    switch (category.toLowerCase()) {
      case 'move':
        data.url = `/astara-selection/coche/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}/${data.vehicleConfigSlug}/${data.vehicleUid}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = version?.salesMoveCheapestPrice || version?.salesPricing
        break
      case 'vn':
        data.url = `/coche/nuevo/${data.makeSlug}/${data.modelSlug}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = version?.salesVnCheapestPrice || version?.salesPricing
        break
      case 'km0':
        data.url = `/coche/km0/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}/${data.vehicleConfigSlug}/${data.vehicleUid}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = version?.salesKm0CheapestPrice || version?.salesPricing
        break
      case 'vo':
      default:
        data.url = `/coche/segunda-mano/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}/${data.vehicleConfigSlug}/${data.vehicleUid}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = version?.salesVoCheapestPrice || version?.salesPricing
    }
  }

  return data
}

const normaliseVehicleData = (vehicle, category) => {
  let environmentalLabels =
    vehicle?.version?.environmentalLabel || vehicle?.model?.environmentalLabels
  if (environmentalLabels && !Array.isArray(environmentalLabels)) {
    environmentalLabels = [environmentalLabels]
  }

  let vehicleSpecs
  try {
    vehicleSpecs = vehicle?.vehicleConfig?.specs
  } catch {
    vehicleSpecs = undefined
  }

  let engines = {}
  try {
    engines = vehicleSpecs.engines.reduce((acc, engine) => {
      const { engines, ...restSpecs } = vehicleSpecs
      acc[engine.type] = {
        ...engine,
        ...restSpecs,
      }
      return acc
    }, {})
  } catch {
    engines = undefined
  }

  const data = {
    bodywork: vehicle?.version?.bodywork,
    brochure: vehicle?.model?.brochure,
    category,
    characteristic: vehicle?.model?.characteristic?.description,
    colors: vehicle?.vehicleConfig?.colors,
    discounts: vehicle?.discounts,
    drivingLicence: vehicle?.version?.drivingLicence,
    edited: vehicle?.edited,
    engines,
    environmentalLabels,
    equipment: vehicle?.version?.equipmentCategories,
    extraEquipment: vehicle?.vehicleConfig?.extraEquipment,
    hero: vehicle?.hero?.file,
    heroBackUp: vehicle?.vehicleConfig?.hero?.file,
    kilometres: vehicle?.kilometres,
    makeName: vehicle?.make?.name,
    makeSlug: vehicle?.make?.slug,
    makeUrl: undefined,
    manufacturerRef: vehicle?.manufacturerRef,
    metaDescription: vehicle?.metaDescription,
    metaTitle: vehicle?.metaTitle,
    modelName: vehicle?.model?.name,
    modelSlug: vehicle?.model?.slug,
    modelSpecs: vehicle?.model?.specs,
    onlyContactForm: vehicle?.onlyContactForm,
    pricing: undefined,
    publishableUnits: vehicle?.publishableUnits,
    registrationDate: vehicle?.registrationDate,
    salesPricing: vehicle?.salesPricing,
    standardEquipment: vehicle?.vehicleConfig?.standardEquipment,
    uid: vehicle?.uid,
    url: undefined,
    useCases: vehicle?.useCases,
    vehicleConfigHero: vehicle?.vehicleConfig?.hero?.file,
    vehicleConfigSlug: vehicle?.vehicleConfig?.slug,
    vehicleSpecs,
    vehicleType: vehicle?.model?.vehicleType,
    vehicleUid: vehicle?.uid,
    versionName: vehicle?.version?.name,
    versionSlug: vehicle?.version?.slug,
    versionSpecs: vehicle?.version?.specs,
    year: vehicle?.model?.year,
    versionGallery: vehicle?.version?.galleryAssets
      ? vehicle.version.galleryAssets.map((x) => x.file).reverse()
      : undefined,
    exteriorGallery: vehicle?.version?.productAssets
      ? vehicle.productAssets.map((x) => x.file).reverse()
      : undefined,
    interiorGallery: vehicle?.marketingAssets
      ? vehicle.marketingAssets.map((x) => x.file).reverse()
      : undefined,
    versionProductGallery: vehicle?.version?.productAssets
      ? vehicle.version.productAssets.map((x) => x.file).reverse()
      : undefined,
    versionMarketingGallery: vehicle?.version?.productAssets
      ? vehicle.version.marketingAssets.map((x) => x.file).reverse()
      : undefined,
  }

  if (data.vehicleType?.toLowerCase() === 'motorbike') {
    data.url = `/moto/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}`
    data.makeUrl = `/motos/${data.makeSlug}`
    data.pricing = vehicle?.salesVnCheapestPrice || vehicle?.salesPricing
  } else {
    switch (category.toLowerCase()) {
      case 'move':
        data.url = `/astara-selection/coche/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}/${data.vehicleConfigSlug}/${data.vehicleUid}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = vehicle?.salesMoveCheapestPrice || vehicle?.salesPricing
        break
      case 'vn':
        data.url = `/coche/nueva/${data.makeSlug}/${data.modelSlug}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = vehicle?.salesVnCheapestPrice || vehicle?.salesPricing
        break
      case 'km0':
        data.url = `/coche/km0/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}/${data.vehicleConfigSlug}/${data.vehicleUid}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = vehicle?.salesKm0CheapestPrice || vehicle?.salesPricing
        break
      case 'vo':
      default:
        data.url = `/coche/segunda-mano/${data.makeSlug}/${data.modelSlug}/${data.versionSlug}/${data.vehicleConfigSlug}/${data.vehicleUid}`
        data.makeUrl = `/coche/${data.makeSlug}`
        data.pricing = vehicle?.salesVoCheapestPrice || vehicle?.salesPricing
    }
  }

  if (data?.pricing) {
    data.pricing.upFrontPayment = vehicle?.upFrontPayment
  }

  return data
}

export const normaliseData = (data, category, level) => {
  switch (level.toLowerCase()) {
    case 'vehicle':
      return normaliseVehicleData(data, category)
    case 'version':
      return normaliseVersionData(data, category)
    case 'model':
    default:
      return normaliseModelData(data, category)
  }
}

export default {
  normaliseData,
}
