/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIBANNumber(input) {
  const CODE_LENGTHS = { ES: 24 }
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '') // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
  let digits = ''
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]] || /\W/.test(input)) {
    return 'Empieza por ES, seguido de 22 caracteres sin espacios'
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })
  // final check
  return mod97(digits) === 1
}

function mod97(string) {
  let checksum = string.slice(0, 2)
  let fragment
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }
  return checksum
}
export default isValidIBANNumber
