<template>
  <div id="layoutsCustomer">
    <Navbar />

    <TabbedLayout
      data-cy="saved-searches-page"
      :title="currentTab.label"
      :tabs="tabs"
      :current-tab="currentTab.key"
      :breadcrumb="breadcrumb"
      class="tabs"
    >
      <main class="container container--np">
        <nuxt />
      </main>
    </TabbedLayout>

    <Footer />
  </div>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'

// Components
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'
import TabbedLayout from '@/components/layouts/Tabbed.vue'

export default {
  name: 'LayoutsCustomer',
  scrollToTop: true,
  components: {
    Footer,
    Navbar,
    TabbedLayout,
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    tabs() {
      return [
        {
          key: this.localePath('customer-orders'),
          label: this.$t('pages.customer.orders.cta.label'),
          url: this.localePath('customer-orders'),
          title: this.customerFullName,
        },
        {
          key: this.localePath('customer-details'),
          label: this.$t('pages.customer.details.cta.label'),
          url: this.localePath('customer-details'),
          title: this.customerFullName,
        },
        {
          key: this.localePath('customer-settings'),
          label: this.$t('pages.customer.settings.cta.label'),
          url: this.localePath('customer-settings'),
          title: this.customerFullName,
        },
      ]
    },
    currentTab() {
      return this.tabs?.find((tab) => this.$route.path === tab.key) || undefined
    },
    breadcrumb() {
      const path = [
        {
          name: this.$t('pages.customer.head.title'),
        },
      ]

      switch (this.currentTab.key) {
        case this.localePath('customer-orders'):
          path.push({
            name: this.$t('pages.customer.orders.cta.label'),
          })
          break
        case this.localePath('customer-details'):
          path.push({
            name: this.$t('pages.customer.details.cta.label'),
          })
          break
        case this.localePath('customer-settings'):
          path.push({
            name: this.$t('pages.customer.settings.cta.label'),
          })
          break
      }

      return path
    },
    customerFullName() {
      return [
        this.currentUser?.name,
        this.currentUser?.lastName,
        this.currentUser?.secondLastName,
      ].join(' ')
    },
    paramOrder() {
      return this.$route.params.order
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('campaign/fetchData', this.$route.query)
  },
  async mounted() {
    await this.$store.dispatch('campaign/initializeStore')
    await this.$store.dispatch('auth/initializeStore')
    await this.$store.dispatch('searches/initializeStore')
  },
  head() {
    return {
      title: this.currentTab?.title,
    }
  },
}
</script>

<style lang="scss" scoped>
#layoutsCustomer .tabs :deep(main) {
  min-height: calc(100vh - var(--navbar-height) - 9rem - 36.125rem);
}
</style>
