var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['textarea-wrapper', { 'skeleton-loader': _vm.skeletonLoader }]},[(_vm.label)?_c('label',{staticClass:"f-size-14 f-500",attrs:{"for":`textarea-${_vm.id}`}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],class:[
      'textarea-content',
      's-mt-4',
      {
        focus: this.focused,
        error: this.error,
        disabled: this.disabled,
      },
    ]},[_c('textarea',_vm._b({staticClass:"textarea",attrs:{"id":`textarea-${_vm.id}`,"aria-errormessage":_vm.error ? `textarea-${_vm.id}-error` : null,"aria-invalid":!!_vm.error,"aria-label":_vm.label,"invalid":!!_vm.error,"name":_vm.name,"placeholder":_vm.placeholder},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){return _vm.$emit('blur')},"focus":function($event){return _vm.$emit('focus')},"click":function($event){return _vm.clicked()}}},'textarea',_vm.$attrs,false)),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"icon-wrapper error",attrs:{"data-cy":"error-icon"}},[_c('Icon',{attrs:{"name":"warning","size":"1.25rem"}})],1):_vm._e(),_vm._v(" "),(_vm.$attrs.value && _vm.removeIcon)?_c('div',{staticClass:"icon-wrapper remove clickable",attrs:{"data-cy":"cross-icon"},on:{"click":function($event){return _vm.remove()}}},[_c('Icon',{attrs:{"name":"cancel","filled":"","size":"1.25rem"}})],1):_vm._e()]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"error size-12",attrs:{"id":`textarea-${_vm.id}-error`,"role":"alert"}},[_vm._v("\n    "+_vm._s(_vm.error)+"\n  ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.error && _vm.hint),expression:"!error && hint"}],staticClass:"hint size-12"},[_vm._v(_vm._s(_vm.hint))])])
}
var staticRenderFns = []

export { render, staticRenderFns }