import Vue from 'vue'
import Router from 'vue-router'

import vnCarsCatalogPage from '~/pages/cars/vn/index.vue'
import km0CarsCatalogPage from '~/pages/cars/km0/index.vue'
import voCarsCatalogPage from '~/pages/cars/vo/index.vue'
import motorbikesCatalogPage from '~/pages/motorbikes/index.vue'
import moveCarsCatalogPage from '~/pages/astara-selection/cars/index.vue'

Vue.use(Router)

export const createRouter = (ssrContext, createDefaultRouter, routerOptions, config, store) => {
  const options = routerOptions || createDefaultRouter(ssrContext, config).options

  const router = new Router({
    ...options,
    routes: catalogRoutesInterceptor(options.routes, store),
    mode: 'history',
  })

  return router
}

const catalogRoutesInterceptor = (defaultRoutes) => {
  const catalogRoutes = [
    {
      path: '/coches/nuevo/:facet1?/:facet2?/:facet3?/:facet4?/:facet5?',
      component: vnCarsCatalogPage,
      name: 'cars-vn___es_ES',
    },
    {
      path: '/cars/new/:facet1?/:facet2?/:facet3?/:facet4?/:facet5?',
      component: vnCarsCatalogPage,
      name: 'cars-vn___es_EN',
    },
    {
      path: '/coches/km0/:facet1?/:facet2?/:facet3?/:facet4?/:facet5?',
      component: km0CarsCatalogPage,
      name: 'cars-km0___es_ES',
    },
    {
      path: '/cars/km0/:facet1?/:facet2?/:facet3?/:facet4?/:facet5?',
      component: km0CarsCatalogPage,
      name: 'cars-km0___es_EN',
    },
    {
      path: '/coches/segunda-mano/:facet1?/:facet2?/:facet3?/:facet4?/:facet5?',
      component: voCarsCatalogPage,
      name: 'cars-vo___es_ES',
    },
    {
      path: '/cars/second-hand/:facet1?/:facet2?/:facet3?/:facet4?/:facet5?',
      component: voCarsCatalogPage,
      name: 'cars-vo___es_EN',
    },
    {
      path: '/motos/:facet1?/:facet2?/:facet3?/:facet4?/:facet5?',
      component: motorbikesCatalogPage,
      name: 'motorbikes___es_ES',
    },
    {
      path: '/motorcycles/:facet1?/:facet2?/:facet3?/:facet4?/:facet5?',
      component: motorbikesCatalogPage,
      name: 'motorbikes___es_EN',
    },
    {
      path: '/astara-selection/coches/:facet1?/:facet2?/:facet3?/:facet4?/:facet5?',
      component: moveCarsCatalogPage,
      name: 'astara-selection-cars___es_ES',
    },
    {
      path: '/astara-selection/cars/:facet1?/:facet2?/:facet3?/:facet4?/:facet5?',
      component: moveCarsCatalogPage,
      name: 'astara-selection-cars___es_EN',
    },
  ]

  const filteredRoutes = defaultRoutes.filter(
    (route) => !catalogRoutes.some((customRoute) => customRoute.name === route.name),
  )

  return [...filteredRoutes, ...catalogRoutes]
}
