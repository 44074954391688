var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['pagination', { 'pagination--prev-next-with-label': _vm.showNextPrevLabel }]},[(!_vm.firstPageSelected)?_c('NuxtLink',{class:[
      'f-not-as-link',
      'pagination__prev',
      {
        'pagination__prev--disabled': _vm.firstPageSelected,
        'pagination__prev--with-label': !_vm.isSizeLUp && _vm.showNextPrevLabel,
      },
    ],attrs:{"to":_vm.localePath({
        path: _vm.$route.path,
        query: {
          ..._vm.$route.query,
          page: this.selected - 1 === 0 ? this.selected : this.selected - 1,
        },
      }),"rel":"noindex follow","replace":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onPrevPage()}},nativeOn:{"click":function($event){return _vm.onPrevPage()}}},[_c('Icon',{attrs:{"name":"chevron_left","size":"1.5rem","tabindex":_vm.firstPageSelected ? -1 : 0}}),_vm._v(" "),(!_vm.isSizeLUp && _vm.showNextPrevLabel)?_c('span',{staticClass:"upper-text"},[_vm._v("\n      "+_vm._s(_vm.$t('global.prev'))+"\n    ")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pagination__pages"},[_vm._l((_vm.pages),function(page,i){return [(page.disabled || page.breakView)?_c('div',{key:i,class:[
          'f-not-as-link',
          'pagination__pages__page',
          {
            'pagination__pages__page--as-break': page.breakView,
          },
        ]},[_c('span',{attrs:{"tabindex":"0"}},[(page.breakView)?[_vm._v("...")]:[_vm._v(_vm._s(page.content))]],2)]):_c('NuxtLink',{key:page.index,class:[
          'f-not-as-link',
          'pagination__pages__page',
          {
            'pagination__pages__page--active': page.selected,
          },
        ],attrs:{"rel":_vm.getRel(_vm.selected, page.index),"to":_vm.localePath({
            path: _vm.$route.path,
            query: {
              ..._vm.$route.query,
              page: page.index + 1,
            },
          }),"replace":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handlePageSelected(page.index + 1)}},nativeOn:{"click":function($event){return _vm.handlePageSelected(page.index + 1)}}},[_c('span',{attrs:{"tabindex":"0"}},[_vm._v("\n          "+_vm._s(page.content)+"\n        ")])])]})],2),_vm._v(" "),(!_vm.lastPageSelected)?_c('NuxtLink',{class:[
      'f-not-as-link',
      'pagination__next',
      {
        'pagination__next--disabled': _vm.lastPageSelected,
        'pagination__next--with-label': !_vm.isSizeLUp && _vm.showNextPrevLabel,
      },
    ],attrs:{"to":_vm.localePath({
        path: _vm.$route.path,
        query: {
          ..._vm.$route.query,
          page: this.selected === this.pageCount ? this.selected : this.selected + 1,
        },
      }),"rel":"noindex follow","replace":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onNextPage()}},nativeOn:{"click":function($event){return _vm.onNextPage()}}},[(!_vm.isSizeLUp && _vm.showNextPrevLabel)?_c('span',{staticClass:"upper-text"},[_vm._v("\n      "+_vm._s(_vm.$t('global.next'))+"\n    ")]):_vm._e(),_vm._v(" "),_c('Icon',{attrs:{"tabindex":_vm.lastPageSelected ? -1 : 0,"name":"navigate_next","size":"1.5rem"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }