export default function ({ app }) {
  if (window && (window.Cypress || window.cy)) {
    window.app = app

    app.store.commit('setAlive', true)
    app.store.commit('setCountries', {
      es: {
        iso: 'es',
        name: 'Pruebalandia',
        apiKey: 'M0dW0tSyjsufKCmMVyiQ',
      },
    })
    app.store.commit('setCountry', 'es')
    app.store.commit('setLanguage', 'es_ES')
    app.store.commit('setLanguageIso', 'es')
    app.store.commit('setCountryLanguages', [
      {
        code: 'es_ES',
        iso: 'es',
        name: 'Español',
      },
      {
        code: 'es_EN',
        iso: 'en',
        name: 'English',
      },
    ])
    app.store.commit('setCountry', 'es')
    app.store.commit('setLanguage', 'es_ES')
    app.store.commit('setLanguageIso', 'es')
    app.store.commit('catalog/setHighestPrice', 1800000)
    app.store.commit('catalog/setLowestPrice', 100000)
    app.store.commit('catalog/setBodyworks', ['pick_up', 'supersport', 'compact', 'suv', 'scooter'])
    app.store.commit('catalog/setUseCases', [])
    app.store.commit('catalog/setEnvironmentalLabels', ['eco'])
    app.store.commit('catalog/setMakes', [
      ['honda', 'Honda'],
      ['audi', 'Audi'],
      ['ford', 'Ford'],
      ['peugeot', 'Peugeot'],
      ['aprilia', 'Aprilia'],
    ])
    app.store.commit('catalog/setModels', [
      ['rs', 'RS'],
      ['3008', '3008'],
      ['c5-aircross', 'c5-aircross'],
    ])
    app.store.commit('purchase/setCustomerFinancingOptions', {
      term: 120,
      product: 'auto_loan',
      program: 'free_program',
      annualInterest: { value: 0.075, string: '7,50 %' },
      apr: { value: 0.0872, string: '8,72 %' },
      setupFeeType: 'type_financed',
      setupFeeAmount: { value: 836.13, string: '836,13 €' },
      insurance: { value: 'seguro_ppp', isInsuranceFinanced: true },
      insuranceTotalAmount: 2167.9,
      gracePeriod: 0,
      gracePercentage: 0,
      principalAmount: { value: 19000, string: '19.000,00€' },
      instalmentAmount: { value: 261.19, string: '261,19€' },
      instalmentFeeAmount: { value: 0, string: '0,00€' },
      instalmentTotalAmount: { value: 261.19, string: '261,19€' },
      downpaymentAmount: { value: 261.19, string: '261,19€' },
      downpaymentFeeAmount: { value: 0, string: '0,00€' },
      downpaymentTotalAmount: { value: 261.19, string: '261,19€' },
      lastInstalmentAmount: { value: 261.19, string: '261,19€' },
      lastInstalmentFeeAmount: { value: 0, string: '0,00€' },
      lastInstalmentTotalAmount: { value: 261.19, string: '261,19€' },
      totalAmount: { value: 31343.01, string: '31.343,01€' },
      totalInterestAmount: { value: 9338.98, string: '9338,98€' },
      totalInstalmentFeeAmount: { value: 0, string: '0,00€' },
      lenderName: 'astara',
      pricingName: 'Astara manual - coches seguro ppp',
      pricingId: '1c442b23-e33c-472d-a5e5-b9e812361076',
    })
    app.store.commit('setPromofinance', {
      active: true,
      maximumVehicleYears: 6,
      minimalPriceToFinancingCents: 1050000,
    })
  }
}
