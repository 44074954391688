var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'notification--status-inline': _vm.inline,
    'notification--status-visible': _vm.displayed,
    'notification--style-horizontal': _vm.isHorizontal,
    'notification--style-light': _vm.light,
    'notification--style-no-header': !_vm.$slots.title && !_vm.title && !_vm.isHorizontal,
    'notification--style-no-close-btn': !_vm.canBeClosed,
    'notification--style-small': _vm.small,
    'notification--type-error': _vm.error || _vm.type === 'error',
    'notification--type-info':
      !_vm.info && !_vm.error && !_vm.success && !_vm.warning ? true : _vm.info || _vm.type === 'info',
    'notification--type-success': _vm.success || _vm.type === 'success',
    'notification--type-warning': _vm.warning || _vm.type === 'warning',
    notification: true,
  },attrs:{"data-cy":`${_vm.type}_notification`}},[_c('aside',{staticClass:"notification__aside"},[(_vm.currentIcon)?_c('Icon',{attrs:{"filled":"","name":_vm.currentIcon,"size":"1.25rem"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"notification__body"},[(_vm.title || _vm.$slots.title || (_vm.canBeClosed && !_vm.light))?_c('div',{staticClass:"notification__body__header"},[(_vm.$slots.title && !_vm.isHorizontal)?_vm._t("title"):(_vm.title && !_vm.isHorizontal)?_c('p',{class:[
          'notification__body__header__title',
          'bold',
          {
            'f-size-16': _vm.small,
            'f-size-18': !_vm.small,
          },
        ]},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.canBeClosed || (_vm.notificationId && !_vm.light))?_c('Button',{staticClass:"notification__body__header__close",attrs:{"type-theme":"ghost-light","size":"small","subtype":"only-icon"},on:{"click":() => _vm.$emit('close')}},[_c('Icon',{attrs:{"name":"close"}})],1):_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.message || _vm.actions.length || _vm.$slots.message)?_c('div',{staticClass:"notification__body__main"},[(_vm.message && !_vm.$slots.message)?_c('p',{class:[
          {
            'f-size-14': _vm.small,
            'f-size-16': !_vm.small,
          },
        ],domProps:{"innerHTML":_vm._s(_vm.message)}}):_vm._e(),_vm._v(" "),_vm._t("message"),_vm._v(" "),(_vm.actions.length)?_c('nav',{staticClass:"notification__body__actions"},_vm._l((_vm.actions),function(action){return _c(action.to || action.href ? 'NuxtLink' : 'span',{key:action.label,tag:"component",class:{
            notification__body__actions__action: true,
            'f-as-link': _vm.inline || _vm.light,
            'upper-text f-not-as-link': !_vm.inline && !_vm.light,
            'f-size-16': !_vm.inline && !_vm.light && !_vm.small,
            'f-size-14': !_vm.inline && !_vm.light && _vm.small,
          },attrs:{"to":action.to ? action.to : undefined,"href":action.href ? action.href : undefined},on:{"click":function($event){action?.fn ? action.fn : undefined}}},[_vm._v("\n          "+_vm._s(action.label)+"\n        ")])}),1):_vm._e()],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }