export const checkSpecsSection = (specs, section, engine) => {
  if (engine === null) {
    return section.some((item) => {
      return (
        // eslint-disable-next-line no-prototype-builtins
        specs.hasOwnProperty(item) &&
        specs[item] !== null &&
        specs[item] !== undefined &&
        specs[item] !== '' &&
        parseInt(specs[item])
      )
    })
  } else {
    return section.some((item) => {
      return (
        // eslint-disable-next-line no-prototype-builtins
        engine?.hasOwnProperty(item) &&
        engine?.[item] !== null &&
        engine?.[item] !== undefined &&
        engine?.[item] !== ''
      )
    })
  }
}

export const modelsOptionsWithoutYear = (i18n, modelsFilter) => {
  try {
    // Remove the year from each modelSlug in the modelsFilter
    modelsFilter.forEach((modelFilter) => {
      modelFilter[0] = normaliseModelSlug(modelFilter[0])
    })

    // After removing the dates from each modelSlug, it removes the duplicated models
    const uniqueModelsFilter = new Map()

    modelsFilter.forEach(([slug, name]) => {
      uniqueModelsFilter.set(slug, name)
    })

    const modelsFilterNoYear = Array.from(uniqueModelsFilter, ([slug, name]) => [
      slug,
      name.charAt(0).toUpperCase() + name.slice(1),
    ])

    const all = i18n ? i18n.t('filters.labels.all')[0] : 'Todos'

    // Adds all models option
    modelsFilterNoYear.unshift([null, all])

    return modelsFilterNoYear
  } catch (error) {
    return [[null, i18n ? i18n.t('filters.labels.all')[0] : 'Todos']]
  }
}

export const kilometersRange = [
  [5000, '5.000 km'],
  [10000, '10.000 km'],
  [20000, '20.000 km'],
  [40000, '40.000 km'],
  [60000, '60.000 km'],
  [80000, '80.000 km'],
  [100000, '100.000 km'],
  [120000, '120.000 km'],
  [140000, '140.000 km'],
  [160000, '160.000 km'],
  [180000, '180.000 o más km'],
]

export const fuelTypes = ['diesel', 'gasoline']

export const powertrains = ['electric', 'hybrid']

export const drivingLicenses = ['A', 'A2', 'A1', 'AM']

export const transmissionTypes = ['manual', 'automatic']

export const cubicCapacities = [
  'lte125',
  'gte126lte400',
  'gte401lte600',
  'gte601lte1000',
  'gte1000',
]

export const batteryMaxPowers = ['lte4kw', 'gte5lte11kw', 'gte12lte35kw', 'gte36kw']

export const priceOptions = (i18n) => {
  return [
    {
      key: 'total',
      value: i18n.t('filters.labels.total'),
    },
    {
      key: 'installment',
      value: i18n.t('filters.labels.installment'),
    },
  ]
}

export const carTotalPriceIntervals = [
  [500000, '5.000€'],
  [1000000, '10.000€'],
  [1500000, '15.000€'],
  [2000000, '20.000€'],
  [2500000, '25.000€'],
  [3000000, '30.000€'],
  [4000000, '40.000€'],
  [6000000, '60.000€'],
  [8000000, '80.000€'],
  [12000000, '120.000€'],
  [16000000, '160.000€'],
  [20000000, '200.000€'],
]

export const carInstallmentPriceIntervals = [
  [10000, '100€'],
  [15000, '150€'],
  [20000, '200€'],
  [30000, '300€'],
  [40000, '400€'],
  [50000, '500€'],
  [100000, '1.000€'],
  [150000, '1.500€'],
  [200000, '2.000€'],
  [300000, '3.000€'],
  [400000, '4.000€'],
  [500000, '5.000€'],
]

export const motorbikeTotalPriceIntervals = [
  [150000, '1.500€'],
  [200000, '2.000€'],
  [400000, '4.000€'],
  [600000, '6.000€'],
  [800000, '8.000€'],
  [1000000, '10.000€'],
  [1500000, '15.000€'],
  [2000000, '20.000€'],
  [2500000, '25.000€'],
  [3000000, '30.000€'],
  [3500000, '35.000€'],
]

export const motorbikeInstallmentPriceIntervals = [
  [3000, '30€'],
  [5000, '50€'],
  [10000, '100€'],
  [15000, '150€'],
  [20000, '200€'],
  [30000, '300€'],
  [40000, '400€'],
  [60000, '600€'],
  [80000, '800€'],
  [100000, '1.000€'],
]

export const bodyworks = {
  moto: [
    {
      image: '/images/scooter.webp',
      key: 'scooter',
    },
    {
      image: '/images/naked.webp',
      key: 'naked',
    },
    {
      image: '/images/deportiva.webp',
      key: 'supersport',
    },
    {
      image: '/images/custom.webp',
      key: 'custom',
    },
    {
      image: '/images/trail.webp',
      key: 'trail',
    },
    {
      image: '/images/tres-ruedas.webp',
      key: 'three_wheeler',
    },
  ],
  car: [
    {
      image: '/images/compacto.webp',
      key: 'compact',
    },
    {
      image: '/images/suv.webp',
      key: 'suv',
    },
    {
      image: '/images/familiar.webp',
      key: 'family',
    },
    {
      image: '/images/berlina.webp',
      key: 'sedan',
    },
    {
      image: '/images/coupe.webp',
      key: 'coupe',
    },
    {
      image: '/images/monovolumen.webp',
      key: 'minivan',
    },
    {
      image: '/images/pick-up.webp',
      key: 'pick_up',
    },
    {
      image: '/images/cabrio.webp',
      key: 'cabrio',
    },
    {
      image: '/images/suv.webp',
      key: 'suv_4x4',
    },
  ],
}

export const vehicleTypes = [
  {
    key: 'car',
  },
  {
    key: 'moto',
  },
]

export const uspTypes = [
  'buying_power',
  'warranty',
  'alert',
  'financing',
  'contact',
  'astara_benefits',
  'trade_in',
  'eco_vehicles',
]

export const getRandomUspTypes = (n = uspTypes.length) => {
  n = Math.min(Math.max(n, 1), uspTypes.length)

  const copy = uspTypes.slice()
  const selectedItems = []

  for (let i = 0; i < n; i++) {
    const index = Math.floor(Math.random() * copy.length)
    selectedItems.push(copy[index])
    copy.splice(index, 1)
  }

  return selectedItems
}

export const astaraBenefits = (i18n) => {
  return [
    {
      icon: 'calculate',
      name: i18n.t('modules.astara_benefits.1.name'),
      description: i18n.t('modules.astara_benefits.1.description'),
      short_description: i18n.t('modules.astara_benefits.1.short_description'),
      x_short_description: i18n.t('modules.astara_benefits.1.x_short_description'),
    },
    {
      icon: 'workspace_premium',
      name: i18n.t('modules.astara_benefits.2.name'),
      description: i18n.t('modules.astara_benefits.2.description'),
      short_description: i18n.t('modules.astara_benefits.2.short_description'),
      x_short_description: i18n.t('modules.astara_benefits.2.x_short_description'),
    },
    {
      icon: 'assignment_return',
      name: i18n.t('modules.astara_benefits.3.name'),
      description: i18n.t('modules.astara_benefits.3.description'),
      short_description: i18n.t('modules.astara_benefits.3.short_description'),
      x_short_description: i18n.t('modules.astara_benefits.3.x_short_description'),
    },
    {
      icon: 'local_shipping',
      name: i18n.t('modules.astara_benefits.4.name'),
      description: i18n.t('modules.astara_benefits.4.description'),
      short_description: i18n.t('modules.astara_benefits.4.short_description'),
      x_short_description: i18n.t('modules.astara_benefits.4.x_short_description'),
    },
  ]
}

export const getFiltersFromUrl = async (store, category, params, query, i18n) => {
  try {
    const normalizeUrlValues = (key, value) => {
      try {
        const filtersTranslations = Object.entries(i18n.t('filters'))

        const normalizeString = (str) => {
          try {
            return str
              ?.normalize('NFD')
              ?.replace(/[\u0300-\u036F]/g, '')
              ?.toLowerCase()
          } catch {
            return str
          }
        }

        const findKeyByValue = (val) => {
          for (const [key, translation] of filtersTranslations) {
            if (translation === val) {
              return key
            }
          }

          return val
        }

        if (Array.isArray(value)) {
          return value.map((val) => findKeyByValue(normalizeString(val))) || value
        } else {
          const valueToFind =
            value !== 'true' && (!Number(value) || (Number(value) && key === 'facet2'))
              ? normalizeString(value)
              : key

          return findKeyByValue(valueToFind) || value
        }
      } catch {
        return value
      }
    }

    let filters = {
      categoryIn: category,
      makeSlug: undefined,
      modelName: undefined,
      bodyworkIn: undefined,
      fuelTypeIn: [],
      powertrainTypeIn: [],
      environmentalLabels: [],
      sortByFinancingPrice: undefined,
      sortByRelevance: false,
      perPage: 16,
      useCasesIn: [],
      cubicCapacityLte: undefined,
      cubicCapacityGte: undefined,
      batteryMaxPowerLte: undefined,
      batteryMaxPowerGte: undefined,
    }

    // Get filters values from the URL params and query
    const normalizedFacets = Object.entries(params)
      .map(([key, value]) => normalizeUrlValues(key, value))
      .flat()

    const normalizedQueryParams = Object.entries(query)
      .map(([key, value]) => {
        if (Object.values(i18n.t('filters')).includes(key)) {
          return normalizeUrlValues(key, value)
        }
      })
      .flat()

    const normalizedParams = [...normalizedFacets, ...normalizedQueryParams].filter(
      (param) => param !== undefined,
    )

    // Make
    const makes = store.getters['catalog/getMakes'] || []
    let models

    let makeMatch, modelMatch
    try {
      makeMatch = makes?.find((value) => normalizedFacets.includes(value[0]))

      if (makeMatch) {
        filters.makeSlug = makeMatch[0]

        // Model
        await store.dispatch('catalog/setModelsByMake', makeMatch[0])
        models = modelsOptionsWithoutYear(i18n, store.getters['catalog/getModels'])

        modelMatch = models?.find((value) => normalizedFacets.includes(value[0]))

        if (modelMatch) {
          filters.modelName = modelMatch[1]
        } else if (normalizedFacets && normalizedFacets[1]) {
          const validMatch = Object.keys(i18n.t('filters'))?.find(
            (value) => value === normalizedFacets[1],
          )

          if (!validMatch) {
            // Invalid model slug
            return { filters: { invalidModelSlug: true, makeSlug: filters?.makeSlug }, models: [] }
          }
        }
      } else if (normalizedFacets && normalizedFacets[0]) {
        const validMatch = Object.keys(i18n.t('filters'))?.find(
          (value) => value === normalizedFacets[0],
        )

        if (!validMatch) {
          // Invalid make slug
          return { filters: { invalidMakeSlug: true }, models: [] }
        }
      }
    } catch {
      filters.makeSlug = undefined
      filters.modelName = undefined
    }

    const unexistentParamMatch = [...normalizedFacets.slice(2, 6), ...normalizedQueryParams]?.find(
      (value) =>
        value !== undefined &&
        value !== makeMatch &&
        value !== modelMatch &&
        !Array.isArray(value) &&
        !Object.keys(i18n.t('filters')).includes(value),
    )

    if (unexistentParamMatch) {
      // Invalid param or query param
      return {
        filters: { invalidParam: true, makeSlug: filters?.makeSlug, modelSlug: filters?.modelSlug },
        models: [],
      }
    }

    // Bodywork
    const bodyworks = store.getters['catalog/getBodyworks'] || []

    const bodyworkMatch = bodyworks.filter((value) => normalizedParams.includes(value))

    if (bodyworkMatch.length > 0) {
      filters.bodyworkIn = bodyworkMatch
    }

    // Power source
    const fuelTypeMatch = fuelTypes.filter((value) => normalizedParams.includes(value))

    if (fuelTypeMatch.length > 0) {
      filters.fuelTypeIn = fuelTypeMatch
    }

    const powertrainTypesMatch = powertrains.filter((value) => normalizedParams.includes(value))

    if (powertrainTypesMatch.length > 0) {
      filters.powertrainTypeIn = powertrainTypesMatch
    }

    // Environmental labels
    const environmentalLabels = store.getters['catalog/getEnvironmentalLabels'] || []

    const environmentalLabelMatch = environmentalLabels.filter((value) =>
      normalizedParams.includes(value),
    )

    if (environmentalLabelMatch.length > 0) {
      filters.environmentalLabels = environmentalLabelMatch
    }

    // Sorting
    switch (query?.order) {
      case 'asc':
        filters.sortByFinancingPrice = 'asc'
        filters.sortByRelevance = false
        break
      case 'desc':
        filters.sortByFinancingPrice = 'desc'
        filters.sortByRelevance = false
        break
      case i18n.t('filters.relevance'):
      default:
        filters.sortByPrice = undefined
        filters.sortByRelevance = true
        break
    }

    // Page
    const page = Number(query?.page)
    filters.page = page > 1 ? page : 1

    // On offer vehicles
    try {
      const useCases = [
        'offer',
        ...store.getters['campaign/getActiveCampaigns']
          ?.filter((campaign) => campaign.visibility)
          ?.map((campaign) => campaign?.useCase),
      ]

      if (normalizedQueryParams.includes('onOffer') && useCases?.length) {
        filters.useCasesIn = useCases
        filters.onOffer = true
      }
    } catch {
      filters.useCasesIn = []
      filters.onOffer = false
    }

    // Cubic capacity
    const computedCubicCapacities = {
      lte125: { lte: 125 },
      gte126lte400: { gte: 126, lte: 400 },
      gte401lte600: { gte: 401, lte: 600 },
      gte601lte1000: { gte: 601, lte: 1000 },
      gte1000: { gte: 1000 },
    }

    const cubicCapacityMatch = Object.keys(computedCubicCapacities)?.find((key) => {
      if (normalizedQueryParams.includes(key)) {
        return key
      }
    })

    if (cubicCapacityMatch) {
      filters.cubicCapacity = cubicCapacityMatch
      filters.cubicCapacityLte = computedCubicCapacities[cubicCapacityMatch]?.lte || undefined
      filters.cubicCapacityGte = computedCubicCapacities[cubicCapacityMatch]?.gte || undefined
    }

    // Electric power
    const computedbatteryMaxPowers = {
      lte4kw: { lte: 4 },
      gte5lte11kw: { gte: 5, lte: 11 },
      gte12lte35kw: { gte: 12, lte: 35 },
      gte36kw: { gte: 36 },
    }

    const batteryMaxPowerMatch = Object.keys(computedbatteryMaxPowers)?.find((key) => {
      if (normalizedQueryParams.includes(key)) {
        return key
      }
    })

    if (batteryMaxPowerMatch) {
      filters.batteryMaxPower = batteryMaxPowerMatch
      filters.batteryMaxPowerLte = computedbatteryMaxPowers[batteryMaxPowerMatch]?.lte || undefined
      filters.batteryMaxPowerGte = computedbatteryMaxPowers[batteryMaxPowerMatch]?.gte || undefined
    }

    // Registration date
    if (normalizedQueryParams.includes('registrationDate')) {
      filters.registrationDate = Number(query[i18n.t('filters.registrationDate')])
    }

    // Driving licenses
    const drivingLicensesMatch = drivingLicenses.filter((value) =>
      normalizedQueryParams.includes(value?.toLowerCase()),
    )

    if (drivingLicensesMatch.length > 0) {
      filters.drivingLicenses = drivingLicensesMatch
    }

    // Kilometers range
    const kilometresGteValue = Number(query[i18n.t('filters.kilometresGte')])
    if (
      kilometresGteValue &&
      kilometersRange.some((subArray) => subArray[0] === kilometresGteValue)
    ) {
      filters.kilometresGte = kilometresGteValue
    }

    const kilometresLteValue = Number(query[i18n.t('filters.kilometresLte')])
    if (
      kilometresLteValue &&
      kilometersRange.some((subArray) => subArray[0] === Number(kilometresLteValue))
    ) {
      filters.kilometresLte = kilometresLteValue
    }

    // Transmission
    const transmissionTypeMath = transmissionTypes.filter((value) =>
      normalizedQueryParams.includes(value),
    )

    if (transmissionTypeMath.length > 0) {
      filters.transmissionTypeIn = transmissionTypeMath
    }

    // Pricing
    const minPrice = Number(query[i18n.t('filters.discountedPriceCentsGte')])
    if (minPrice) {
      filters.discountedPriceCentsGte = minPrice * 100
    }

    const maxPrice = Number(query[i18n.t('filters.discountedPriceCentsLte')])
    if (maxPrice) {
      filters.discountedPriceCentsLte = maxPrice * 100
    }

    const minInstallment = Number(query[i18n.t('filters.instalmentPriceCentsGte')])
    if (minInstallment) {
      filters.instalmentPriceCentsGte = minInstallment * 100
    }

    const maxInstallment = Number(query[i18n.t('filters.instalmentPriceCentsLte')])
    if (maxInstallment) {
      filters.instalmentPriceCentsLte = maxInstallment * 100
    }

    // Clear undefined filters
    filters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => {
        if (!value) return false

        if (Array.isArray(value)) {
          return value.length > 0 && value.every((v) => v)
        }

        return true
      }),
    )

    return {
      filters,
      models,
    }
  } catch {
    return {
      filters: {},
      models: [],
    }
  }
}

export const getFitersState = (filters, i18n, store) => {
  const formState = {
    batteryMaxPower: undefined,
    bodyworkIn: {},
    cubicCapacity: undefined,
    discountedPriceCentsGte: undefined,
    discountedPriceCentsLte: undefined,
    drivingLicenses: {},
    environmentalLabels: {},
    fuelTypeIn: {},
    instalmentPriceCentsGte: undefined,
    instalmentPriceCentsLte: undefined,
    kilometresGte: undefined,
    kilometresLte: undefined,
    makeSlug: undefined,
    modelSlug: undefined,
    onOffer: false,
    powertrainTypeIn: {},
    registrationDate: undefined,
    transmissionTypeIn: {},
  }

  try {
    Object.keys(filters).forEach((key) => {
      if (key === 'modelName') {
        const models = modelsOptionsWithoutYear(i18n, store.getters['catalog/getModels'])

        const modelMatch = models?.find((value) => value[1] === filters[key])
        if (modelMatch) {
          formState.modelSlug = modelMatch[0]
        }
      } else {
        const value = filters[key]

        if (Array.isArray(value)) {
          formState[key] = {}
          value.forEach((valueKey) => {
            formState[key][valueKey] = true
          })
        } else {
          formState[key] = filters[key]
        }
      }
    })

    const {
      // Filters to be ignored on the URL
      perPage,
      financialEntityName,
      categoryIn,
      useCasesIn,
      cubicCapacityLte,
      cubicCapacityGte,
      batteryMaxPowerLte,
      batteryMaxPowerGte,
      order,
      // Filters intented to be shown on the URL
      ...remaining
    } = formState

    return remaining
  } catch {
    return formState
  }
}

export const getSeoContent = async (vehicleType, category, route, i18n, axios, store, filters) => {
  // Dynamic content
  let headerTitle, headerSubtitle, characteristicsSlug

  const lwcCategory = category.toLowerCase()
  const isMotorbike = vehicleType === 'motorbike'
  const languageIso = store.getters?.getLanguageIso || 'es'
  const makes = store.getters['catalog/getMakes'] || []
  const models = store.getters['catalog/getModels'] || []

  let matchedMake
  try {
    const makeSlug = filters.makeSlug

    if (makeSlug) {
      matchedMake = makes?.find((make) => make[0] === makeSlug)
    }
  } catch {
    matchedMake = undefined
  }

  try {
    const isOnMainCatalogPage = !route.params?.facet1
    const isOnMakeCatalogPage = matchedMake && !route.params.facet2

    // Contents
    if (isMotorbike) {
      headerTitle = isOnMakeCatalogPage
        ? matchedMake[2]?.title ||
          i18n.t(`seo.${vehicleType}.${lwcCategory}.header.make_title`, { make: matchedMake[1] })
        : i18n.t(`seo.${vehicleType}.${lwcCategory}.header.title`)

      if (isOnMainCatalogPage) {
        headerSubtitle = i18n.t(`seo.${vehicleType}.${lwcCategory}.header.subtitle`)
        characteristicsSlug = 'catalogo-de-motos'
      } else if (isOnMakeCatalogPage) {
        headerSubtitle = matchedMake[2].description
      }
    } else {
      headerTitle = i18n.t(`seo.${vehicleType}.${lwcCategory}.header.title`)

      if (isOnMainCatalogPage && lwcCategory !== 'move') {
        headerSubtitle = i18n.t(`seo.${vehicleType}.${lwcCategory}.header.subtitle`)

        switch (lwcCategory) {
          case 'vn':
            characteristicsSlug = 'catalogo-de-coches-nuevos'
            break
          case 'km0':
            characteristicsSlug = 'catalogo-de-coches-km0'
            break
          case 'vo':
            characteristicsSlug = 'catalogo-de-coches-usados'
            break
        }
      }
    }

    let characteristicsContent = ''
    if (isOnMakeCatalogPage && isMotorbike && matchedMake) {
      characteristicsContent = matchedMake[2].characteristics
    } else if (characteristicsSlug) {
      const response = await axios.get(`/v1/legal_pages?slug=${characteristicsSlug}`)

      characteristicsContent = response.data[0].description
        .filter((x) => x.language === languageIso)[0]
        .text.replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>')
        .replaceAll('<pre>', '')
        .replaceAll('</pre>', '')
    }

    // Meta-data
    let title, metaTitle, metaDescription

    if (filters?.modelSlug) {
      const unfilteredModels = models
      const model = unfilteredModels?.find((model) => model[0] === filters.modelSlug)

      if (model) {
        metaTitle = model[2]?.metaTitle
        metaDescription = model[2]?.metaDescription
      }
    } else if (filters?.makeSlug) {
      const make = makes.find((make) => make[0] === filters.makeSlug)

      if (make) {
        metaTitle = make[2]?.metaTitle
        metaDescription = make[2]?.metaDescription

        if (isMotorbike && !metaTitle) {
          title = i18n.t(`seo.motorbikes.head.make.title`)
          metaTitle = i18n.t(`seo.motorbikes.head.make.meta.title`)
        }

        if (isMotorbike && !metaDescription) {
          metaDescription = i18n.t(`seo.motorbikes.head.make.meta.description`)
        }
      }
    }

    if (!metaTitle || !metaDescription) {
      if (isMotorbike) {
        const bodyworkIn = filters?.bodyworkIn

        let bodyworkInFilterValue
        if (bodyworkIn) {
          const isArray = Array.isArray(bodyworkIn)

          if (isArray && bodyworkIn.length === 1) {
            bodyworkInFilterValue = bodyworkIn[0]
          } else if (!isArray) {
            bodyworkInFilterValue = bodyworkIn
          }
        }

        const metaTitleKey = `seo.${vehicleType}.${lwcCategory}.head.${bodyworkInFilterValue}.meta.title`
        const metaDescriptionKey = `seo.${vehicleType}.${lwcCategory}.head.${bodyworkInFilterValue}.meta.description`

        if (bodyworkInFilterValue && i18n.te(metaTitleKey)) {
          title = i18n.t(`seo.${vehicleType}.${lwcCategory}.head.${bodyworkInFilterValue}.title`)
          metaTitle = i18n.t(metaTitleKey)
        } else {
          title = i18n.t(`seo.${vehicleType}.${lwcCategory}.head.title`)
          metaTitle = i18n.t(`seo.${vehicleType}.${lwcCategory}.head.meta.title`)
        }

        if (bodyworkInFilterValue && i18n.te(metaDescriptionKey)) {
          metaDescription = i18n.t(metaDescriptionKey)
        } else {
          metaDescription = i18n.t(`seo.${vehicleType}.${lwcCategory}.head.meta.description`)
        }
      } else {
        title = i18n.t(`seo.${vehicleType}.${lwcCategory}.head.title`)
        metaTitle = i18n.t(`seo.${vehicleType}.${lwcCategory}.head.meta.title`)
        metaDescription = i18n.t(`seo.${vehicleType}.${lwcCategory}.head.meta.description`)
      }
    }

    return {
      header: {
        title: headerTitle,
        subtitle: headerSubtitle,
      },
      characteristicsContent,
      title,
      metaTitle,
      metaDescription,
    }
  } catch {
    return {}
  }
}

export const normaliseModelSlug = (slug) => {
  return slug.replace(/-\d{4}$/, '')
}

export const getFilterableUseCases = async (route, store) => {
  await store.dispatch('campaign/fetchData', route.query)

  return [
    'offer',
    ...store.getters['campaign/getActiveCampaigns']
      ?.filter((campaign) => campaign.tagVisibility)
      ?.map((campaign) => campaign?.useCase),
  ]
}

export default {
  astaraBenefits,
  batteryMaxPowers,
  bodyworks,
  carInstallmentPriceIntervals,
  carTotalPriceIntervals,
  checkSpecsSection,
  cubicCapacities,
  drivingLicenses,
  fuelTypes,
  getFiltersFromUrl,
  getFitersState,
  getRandomUspTypes,
  kilometersRange,
  modelsOptionsWithoutYear,
  motorbikeInstallmentPriceIntervals,
  motorbikeTotalPriceIntervals,
  powertrains,
  priceOptions,
  transmissionTypes,
  uspTypes,
  vehicleTypes,
  normaliseModelSlug,
  getFilterableUseCases,
}
