<template>
  <div :class="['pagination', { 'pagination--prev-next-with-label': showNextPrevLabel }]">
    <NuxtLink
      v-if="!firstPageSelected"
      :class="[
        'f-not-as-link',
        'pagination__prev',
        {
          'pagination__prev--disabled': firstPageSelected,
          'pagination__prev--with-label': !isSizeLUp && showNextPrevLabel,
        },
      ]"
      :to="
        localePath({
          path: $route.path,
          query: {
            ...$route.query,
            page: this.selected - 1 === 0 ? this.selected : this.selected - 1,
          },
        })
      "
      rel="noindex follow"
      replace
      @click.native="onPrevPage()"
      @keyup.enter="onPrevPage()"
    >
      <Icon name="chevron_left" size="1.5rem" :tabindex="firstPageSelected ? -1 : 0" />
      <span v-if="!isSizeLUp && showNextPrevLabel" class="upper-text">
        {{ $t('global.prev') }}
      </span>
    </NuxtLink>

    <div class="pagination__pages">
      <template v-for="(page, i) in pages">
        <div
          v-if="page.disabled || page.breakView"
          :key="i"
          :class="[
            'f-not-as-link',
            'pagination__pages__page',
            {
              'pagination__pages__page--as-break': page.breakView,
            },
          ]"
        >
          <span tabindex="0">
            <template v-if="page.breakView">...</template>
            <template v-else>{{ page.content }}</template>
          </span>
        </div>
        <NuxtLink
          v-else
          :key="page.index"
          :rel="getRel(selected, page.index)"
          :class="[
            'f-not-as-link',
            'pagination__pages__page',
            {
              'pagination__pages__page--active': page.selected,
            },
          ]"
          :to="
            localePath({
              path: $route.path,
              query: {
                ...$route.query,
                page: page.index + 1,
              },
            })
          "
          replace
          @click.native="handlePageSelected(page.index + 1)"
          @keyup.enter="handlePageSelected(page.index + 1)"
        >
          <span tabindex="0">
            {{ page.content }}
          </span>
        </NuxtLink>
      </template>
    </div>

    <NuxtLink
      v-if="!lastPageSelected"
      :class="[
        'f-not-as-link',
        'pagination__next',
        {
          'pagination__next--disabled': lastPageSelected,
          'pagination__next--with-label': !isSizeLUp && showNextPrevLabel,
        },
      ]"
      :to="
        localePath({
          path: $route.path,
          query: {
            ...$route.query,
            page: this.selected === this.pageCount ? this.selected : this.selected + 1,
          },
        })
      "
      rel="noindex follow"
      replace
      @click.native="onNextPage()"
      @keyup.enter="onNextPage()"
    >
      <span v-if="!isSizeLUp && showNextPrevLabel" class="upper-text">
        {{ $t('global.next') }}
      </span>

      <Icon :tabindex="lastPageSelected ? -1 : 0" name="navigate_next" size="1.5rem" />
    </NuxtLink>
  </div>
</template>

<script>
// Helpers & mixins
import checkViewportSize from '@/mixins/checkViewportSize'

// Components
import Icon from '@/components/Icon'

export default {
  name: 'Pagination',
  components: { Icon },
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    forcePage: {
      type: Number,
      required: false,
      default: null,
    },
    clickHandler: {
      type: Function,
      required: false,
      default: () => {},
    },
    pageRange: {
      type: Number,
      required: false,
      default: 3,
    },
    marginPages: {
      type: Number,
      required: false,
      default: 1,
    },
    showNextPrevLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      innerValue: 1,
    }
  },
  computed: {
    selected: {
      get() {
        return this.value || this.innerValue
      },
      set(newVal) {
        this.innerValue = newVal
      },
    },
    actualPageRange() {
      return this.isSizeLUp ? this.pageRange : 3
    },
    firstPageSelected() {
      return this.selected === 1
    },
    lastPageSelected() {
      return this.selected === this.pageCount || this.pageCount === 0
    },
    pages() {
      const items = {}
      if (this.pageCount <= this.actualPageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          const page = {
            index,
            content: index + 1,
            selected: index === this.selected - 1,
          }
          items[index] = page
        }
      } else {
        const halfPageRange = Math.floor(this.actualPageRange / 2)
        const setPageItem = (index) => {
          const page = {
            index,
            content: index + 1,
            selected: index === this.selected - 1,
          }
          items[index] = page
        }
        const setBreakView = (index) => {
          const breakView = {
            breakView: true,
          }
          items[index] = breakView
        }
        // 1st - loop thru low end of margin pages
        for (let i = 0; i < this.marginPages; i++) {
          setPageItem(i)
        }
        // 2nd - loop thru selected range
        let selectedRangeLow = 0
        if (this.selected - halfPageRange > 0) {
          selectedRangeLow = this.selected - 1 - halfPageRange
        }
        let selectedRangeHigh = selectedRangeLow + this.actualPageRange - 1
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1
          selectedRangeLow = selectedRangeHigh - this.actualPageRange + 1
        }
        for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
          setPageItem(i)
        }
        // Check if there is breakView in the left of selected range
        if (selectedRangeLow > this.marginPages) {
          setBreakView(selectedRangeLow - 1)
        }
        // Check if there is breakView in the right of selected range
        if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
          setBreakView(selectedRangeHigh + 1)
        }
        // 3rd - loop thru high end of margin pages
        for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
          setPageItem(i)
        }
      }
      return items
    },
  },
  beforeUpdate() {
    if (this.forcePage === undefined) return
    if (this.forcePage !== this.selected) {
      this.selected = this.forcePage
    }
  },
  methods: {
    // We only get prev/next if it's the following or the exact previous page
    getRel(origin, destiny) {
      // Origin (selected) starts in 1 and destiny (page.index) with 0, so we remove 1 position from origin.
      if (origin - 2 === destiny) {
        return 'prev noindex follow'
      } else if (origin === destiny) {
        return 'next noindex follow'
      } else {
        return 'noindex follow'
      }
    },
    handlePageSelected(selected) {
      if (this.selected === selected) return
      this.innerValue = selected
      this.$emit('input', selected)
      this.clickHandler(selected)
    },
    onPrevPage() {
      if (this.selected <= 1) return
      this.handlePageSelected(this.selected - 1)
    },
    onNextPage() {
      if (this.selected >= this.pageCount) return
      this.handlePageSelected(this.selected + 1)
    },
    onFirstPage() {
      if (this.selected <= 1) return
      this.handlePageSelected(1)
    },
    onLastPage() {
      if (this.selected >= this.pageCount) return
      this.handlePageSelected(this.pageCount)
    },
  },
  mixins: [checkViewportSize],
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 0.5rem;

  @include size-s {
    width: fit-content;
    margin: 0 auto;
  }

  a {
    font-weight: 500;
    cursor: pointer;
  }

  &__pages {
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;

    @include size-s {
      grid-column-end: 3;
      grid-column-start: 1;
      grid-row-end: 2;
      grid-row-start: 1;
      justify-content: center;
    }
  }

  &__pages__page,
  &__prev,
  &__next {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    border: 2px solid transparent;
    border-radius: 0.25rem;

    &:not(.pagination__pages__page--as-break) {
      border-color: $button-primary-txt-unselected;
      background-color: $button-secondary-enabled;
      color: $button-primary-txt-unselected;

      &:hover {
        background-color: $button-secondary-hovered;
      }

      &:active {
        background-color: $button-secondary-pressed;
      }
    }
  }

  &__pages__page {
    width: 2.5rem;

    &--active {
      border-color: $button-primary-enabled !important;
      background-color: $button-primary-enabled !important;
      color: $button-primary-txt !important;

      &:hover {
        border-color: $button-primary-hovered;
        background-color: $button-primary-hovered;
      }

      &:active {
        border-color: $button-primary-pressed;
        background-color: $button-primary-pressed;
      }
    }
  }

  &__prev,
  &__next {
    gap: 0.25rem;

    &--disabled {
      display: none;
    }
  }

  &__prev--with-label {
    padding-right: 0.625rem;
  }

  &__next--with-label {
    padding-left: 0.625rem;
  }

  @include size-s {
    &:not(.pagination--prev-next-with-label) {
      .pagination__prev,
      .pagination__next {
        width: 2.5rem;
      }
    }

    &.pagination--prev-next-with-label {
      display: flex;
      flex-wrap: wrap;

      .pagination__prev,
      .pagination__next {
        width: 9rem;
        max-width: calc(50% - 0.375rem);
      }

      &__pages {
        order: 1;
        width: 100%;
      }

      .pagination__prev {
        order: 2;
      }

      .pagination__next {
        order: 3;
      }
    }
  }
}
</style>
