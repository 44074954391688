export default {
  bind(el, binding) {
    let startOutside = false

    const startHandler = (event) => {
      startOutside = !el.contains(event.target)
    }

    const endHandler = (event) => {
      if (startOutside && !el.contains(event.target)) {
        binding.value(event)
      }
    }

    el.__startClickOutside__ = startHandler
    el.__endClickOutside__ = endHandler

    document.addEventListener('mousedown', startHandler)
    document.addEventListener('mouseup', endHandler)
  },
  unbind(el) {
    document.removeEventListener('mousedown', el.__startClickOutside__)
    document.removeEventListener('mouseup', el.__endClickOutside__)
    delete el.__startClickOutside__
    delete el.__endClickOutside__
  },
}
