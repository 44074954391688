<template>
  <div class="selector-content">
    <div
      v-for="option in options"
      :key="option.key"
      class="selection"
      data-cy="button-element"
      :class="classNames(option)"
      @click="selectOption(option.key)"
    >
      <p data-cy="button-title" class="title size-14 medium upper-text">{{ option.value }}</p>
    </div>
  </div>
</template>

<script>
export const SIZES = ['large', 'medium', 'small']

export default {
  name: 'ButtonGroup',
  props: {
    options: {
      type: Array,
      required: true,
    },
    selection: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      validator: (value) => SIZES.includes(value),
      required: false,
      default: 'large',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: this.selection,
    }
  },
  methods: {
    selectOption(key) {
      if (!this.disabled) {
        this.selected = key
        this.$emit('submit', key)
      }
    },
    classNames(option) {
      return {
        selected: this.selected === option.key,
        ...{ [`${this.size}`]: this.size },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.selector-content {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border: 2px solid $button-primary-border;
  border-radius: 8px;
  background-color: $button-primary-unselected;
  color: $button-primary-txt-unselected;
  .selection {
    width: 100%;
    cursor: pointer;
    .title {
      margin: 0;
      padding: 0;
      text-align: center;
      white-space: nowrap;
    }
    &.selected {
      background-color: $button-primary-enabled;
      color: $button-primary-txt;
      font-weight: bold;
    }
  }

  .large {
    padding: 1rem 1.5rem;
  }

  .medium {
    padding: 0.625rem 1rem;
  }

  .small {
    padding: 0.375rem 0.5rem;
  }
}
</style>
