const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['auto_login'] = require('../middleware/auto_login.js')
middleware['auto_login'] = middleware['auto_login'].default || middleware['auto_login']

middleware['campaigns'] = require('../middleware/campaigns.js')
middleware['campaigns'] = middleware['campaigns'].default || middleware['campaigns']

middleware['catalogRoute'] = require('../middleware/catalogRoute.js')
middleware['catalogRoute'] = middleware['catalogRoute'].default || middleware['catalogRoute']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['protected'] = require('../middleware/protected.js')
middleware['protected'] = middleware['protected'].default || middleware['protected']

export default middleware
