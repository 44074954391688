export default {
  data() {
    return {
      isSizeSUp: false,
      isSizeMUp: false,
      isSizeLUp: false,
      isSizeXLUp: false,
      minSizeSUp: 479,
      minSizeMUp: 768,
      minSizeLUp: 1024,
      minSizeXLUp: 1280,
    }
  },
  methods: {
    updateSizes() {
      const width = window.innerWidth
      this.isSizeSUp = width >= this.minSizeSUp
      this.isSizeMUp = width >= this.minSizeMUp
      this.isSizeLUp = width >= this.minSizeLUp
      this.isSizeXLUp = width >= this.minSizeXLUp
    },
  },
  mounted() {
    this.updateSizes()
    window.addEventListener('resize', this.updateSizes)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSizes)
  },
}
