<template>
  <div class="notifications">
    <div class="container container--pv">
      <Notification
        v-for="notification of notifications.filter((notification) => notification.displayed)"
        :key="notification.id"
        :actions="notification.data.actions"
        :displayed="notification.displayed"
        :message="notification.data.message"
        :notification-id="notification.id"
        :on-close="notification.onClose"
        :title="notification.data.title"
        :horizontal="notification.horizontal"
        :success="notification.data.type === 'success'"
        :error="notification.data.type === 'error'"
        :info="notification.data.type === 'info'"
        :warning="notification.data.type === 'warning'"
        @close="() => notification?.onClose()"
      />
    </div>
  </div>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'

// Components
import Notification from '@/components/Notification.vue'

export default {
  name: 'Notifications',
  components: {
    Notification,
  },
  data() {
    return {
      notificationsInterval: undefined,
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/notifications',
    }),
  },
  methods: {
    checkNotifications() {
      const now = Date.now()

      // Check expired notifications
      this.notifications.forEach((notification) => {
        const expired = notification.expiration !== 0 && now > notification.expiration

        if (!notification.expiration || expired) {
          try {
            notification?.onClose()
          } catch {
            this.$store.commit('notification/close', notification.id)
          }
        }
      })
    },
  },
  mounted() {
    // Setup notifications management
    this.notificationsInterval = setInterval(this.checkNotifications, 10000)
    this.checkNotifications()
  },
  beforeDestroy() {
    // Clear intervals and timeouts
    if (this.notificationsInterval) clearInterval(this.notificationsInterval)
  },
}
</script>

<style scoped lang="scss">
.notifications {
  position: fixed;
  z-index: 99999;
  bottom: 0;
  left: 0;

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
  }

  :deep(.notification) {
    display: inline-flex;
    position: relative;
    bottom: unset;
    left: unset;
  }
}
</style>
