import { gql } from 'graphql-tag'

export const promofinanceQuery = gql`
  query promofinanceQuery {
    promofinance {
      active
      maximumVehicleYears
      minimalPriceToFinancingCents
    }
  }
`

export default {
  promofinanceQuery,
}
