// Dependencies
import { config } from '../config'

export const getBaseUrl = () => {
  if (process.client) {
    return window.location.origin
  } else {
    return config.host
  }
}

export function validateBlogUrl(currentRoute) {
  if (currentRoute.name && currentRoute.name.split('___')[1] === 'es_ES') return true
  return false
}

export const getFullPageUrl = (currentRoute, includeBase = true, includeQueryParams = true) => {
  const baseUrl = includeBase ? getBaseUrl() : ''
  const { path, query } = currentRoute

  let queryString
  if (includeQueryParams) {
    queryString = Object.keys(query)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
      .join('&')
  }

  return `${baseUrl}${path}${queryString ? `?${queryString}` : ''}`
}

export const stringToSlug = (str) => {
  return str
    .normalize('NFKD')
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/[^a-zA-Z0-9-]+/g, '')
}

export default {
  getBaseUrl,
  validateBlogUrl,
  getFullPageUrl,
  stringToSlug,
}
