var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'breadcrumb size-14',
    {
      'breadcrumb--secondary': _vm.secondary,
      'breadcrumb--hide-on-mobile': _vm.hideOnMobile,
    },
  ],attrs:{"data-cy":"breadcrumb"}},[_c('div',[_c('NuxtLink',{staticClass:"breadcrumb__link",attrs:{"to":_vm.homeLink ? _vm.localePath(_vm.homeLink) : _vm.localePath('index')}},[(_vm.homeIcon)?_c('img',{attrs:{"loading":"lazy","src":"/images/home-icon.webp","alt":"Homepage icon"}}):_vm._e(),_vm._v("\n      "+_vm._s(!_vm.homeIcon ? 'Home' : '')+"\n    ")])],1),_vm._v(" "),(_vm.breadcrumbLinks?.length)?_c('div',{staticClass:"breadcrumb-links"},_vm._l((_vm.breadcrumbLinks),function(link){return _c('div',{key:link.name},[_c('Icon',{staticClass:"breadcrumb__icon",attrs:{"name":"navigate_next","size":"1rem"}}),_vm._v(" "),(link?.url)?_c('NuxtLink',{staticClass:"breadcrumb__link breadcrumb__link--f-as-link",attrs:{"to":_vm.localePath(link.url),"data-cy":"breadcrumb-link"}},[_vm._v("\n        "+_vm._s(link.name)+"\n      ")]):_c('span',{staticClass:"breadcrumb__link"},[_vm._v("\n        "+_vm._s(link.name)+"\n      ")])],1)}),0):_vm._e(),_vm._v(" "),(_vm.currentPage)?_c('div',[_c('Icon',{staticClass:"breadcrumb__icon",attrs:{"name":"navigate_next","size":"1rem"}}),_vm._v(" "),(_vm.currentPage)?_c('span',{staticClass:"breadcrumb__link breadcrumb__link--current"},[_vm._v("\n      "+_vm._s(_vm.currentPage)+"\n    ")]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }