<template>
  <div class="content" :class="classNames" data-cy="environmental-label" @click="$emit('click')">
    <Icon :name="'env-' + label" />
  </div>
</template>

<script>
import Icon from '@/components/Icon'
export default {
  name: 'EnvironmentalLabel',
  components: { Icon },
  props: {
    label: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    classNames() {
      return {
        selected: this.selected,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}
.selected {
  border: 2px solid $button-primary-enabled;
  border-radius: 100%;
}
</style>
