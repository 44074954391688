import { digestMessage } from '@/helpers/utils'
/* Configuration */

const storeConfiguration = {
  ecommerce: 'astara store',
  currency: 'eur',
  site_brand: 'store',
  site_product_condition: 'new and used',
  site_regional_hub: 'southern europe',
  site_country: 'spain',
  site_language: 'es',
  site_domain: window.location.origin,
}

let previousPage = null

export default ({ app, store }, inject) => {
  /* Helpers */

  app.router.afterEach((to, from) => {
    previousPage = `${storeConfiguration.site_domain}${from.fullPath}`
  })

  const getBrowserLanguage = () => {
    const browserLocale = navigator.language?.toLocaleLowerCase()

    if (!browserLocale) return null

    if (browserLocale.includes('-')) {
      return browserLocale.split('-')[1]
    } else {
      return browserLocale
    }
  }

  const getFuelType = (engines) => {
    try {
      const fuelTypes = []

      engines.forEach((engine) => {
        if (engine?.type === 'electric') {
          fuelTypes.push(engine.type)
        } else if (Object.prototype.hasOwnProperty.call(engine, 'fuelType')) {
          fuelTypes.push(engine.fuelType)
        }
      })

      return fuelTypes.join(',')
    } catch {
      return null
    }
  }

  const getGtmClientId = async () => {
    const promise = new Promise((resolve, reject) => {
      const cookies = document.cookie.split('; ')
      for (const cookie of cookies) {
        if (cookie.startsWith('_ga')) {
          resolve(cookie.split('=')[1])
        }
      }
      reject(new Error('GA4 client_id not found'))
    })

    return await promise
      .then((value) => {
        return value
      })
      .catch(() => {
        return null
      })
  }

  const getUserSessionData = async (loginMethod = 'email') => {
    const currentUser = store.getters['auth/currentUser']
    const currentUserEmail = currentUser?.email || null
    const currentUserEmailSha = (await digestMessage(currentUserEmail)) || null
    return {
      user_name: currentUser?.name || null,
      user_name_sha: (await digestMessage(currentUser?.name)) || null,
      user_email: currentUserEmail,
      user_email_sha: currentUserEmailSha,
      user_phone: currentUser?.phone || null,
      user_phone_sha: (await digestMessage(currentUser?.phone)) || null,
      method: loginMethod, // google, email, facebook
    }
  }
  const getNameSessionData = async () => {
    const currentUser = store.getters['auth/currentUser']
    const currentUserEmail = currentUser?.email || null
    const currentUserEmailSha = (await digestMessage(currentUserEmail)) || null
    return {
      name_form: currentUser?.name || null,
      name_form_sha: (await digestMessage(currentUser?.name)) || null,
      email_form: currentUserEmail,
      email_form_sha: currentUserEmailSha,
      phone_form: currentUser?.phone || null,
      phone_form_sha: (await digestMessage(currentUser?.phone)) || null,
    }
  }

  const getInfoVehiclePurchase = (vehicle, customData = {}) => {
    return {
      vehicle_type: customData?.vehicleType,
      brand: vehicle?.make?.name,
      product_condition: customData?.category,
      model: vehicle?.model?.name,
      version: vehicle?.version?.name,
      fuel_type: getFuelType(vehicle?.version?.specs?.engines),
    }
  }

  const getFinancingDetails = (data) => {
    return {
      initial_fee: data?.initial_fee || null,
      monthly_fee: data?.monthly_fee / 100 || null,
      payments_fees: data?.payment_fees || null,
      financing_amount: data?.financing_amount / 100 || null,
      price: data?.discounted_price / 100 || null,
    }
  }

  const getContactFormData = async (contactForm) => {
    return {
      name_form: contactForm?.name || null,
      name_form_sha: contactForm?.name ? await digestMessage(contactForm.name) : null,
      last_name_form: contactForm?.last_name || null,
      last_name_form_sha: contactForm?.last_name
        ? await digestMessage(contactForm.last_name)
        : null,
      second_last_name_form: contactForm?.second_last_name || null,
      second_last_name_form_sha: contactForm?.second_last_name
        ? await digestMessage(contactForm.second_last_name)
        : null,
      phone_form: contactForm?.phone || null,
      phone_form_sha: contactForm?.phone ? await digestMessage(contactForm.phone) : null,
      email_form: contactForm?.email || null,
      email_form_sha: contactForm?.email ? await digestMessage(contactForm.email) : null,
      vehicle_type: contactForm?.vehicle_type || null,
      brand: contactForm?.brand || contactForm?.make || storeConfiguration.site_brand || null,
      product_condition:
        contactForm?.product_condition || storeConfiguration.site_product_condition || null,
      gender: contactForm?.gender || null,
      model: contactForm?.model || null,
      version: contactForm?.version || null,
      dealer_name: contactForm?.dealer || null,
      contact_reason: contactForm?.contact_reason || null,
    }
  }

  const getPrice = (item, category) => {
    try {
      switch (category) {
        case 'new':
          return item.salesVnCheapestPrice
        case 'used':
        case 'km0':
          return item.salesPricing
        case 'move':
          return item.model.salesMoveCheapestPrice
        default:
          return null
      }
    } catch {
      return null
    }
  }

  const getItemData = (
    listId,
    name,
    item,
    index,
    category = 'new',
    vehicleType = 'car',
    colorSelected,
  ) => {
    let equipments = null
    try {
      const extrasList = item?.extraEquipment || item.config?.extraEquipment
      equipments = extrasList
        .flatMap((eqCategory) => eqCategory?.equipmentItems?.map((eqItem) => eqItem.name))
        .join(',')
    } catch {
      equipments = null
    }

    let fuelType = null
    try {
      const engines = listId === 'versions' ? item?.specs?.engines : item?.version?.specs?.engines
      fuelType = getFuelType(engines) || null
    } catch {
      fuelType = null
    }

    let exteriorColor = null
    try {
      if (colorSelected) exteriorColor = colorSelected
      else {
        const color = item?.colors || item.config?.colors || item?.configs[0]?.colors
        exteriorColor = color.find((color) => color.surfaceType === 'exterior')?.name || null
      }
    } catch {
      exteriorColor = null
    }

    let interiorColor = null
    try {
      const color = item?.colors || item.config?.colors
      interiorColor = color.find((color) => color.surfaceType === 'interior')?.name || null
    } catch {
      interiorColor = null
    }

    const make = item?.make?.name

    const model =
      listId === 'models'
        ? category === 'move'
          ? item?.model?.name
          : item?.name
        : item?.model?.name || null

    const version = listId === 'versions' ? item?.name : item?.version?.name || null

    const price = getPrice(item, category)

    const data = {
      item_id:
        listId === 'vehicles'
          ? item?.config?.slug
          : category === 'move'
          ? item?.model?.slug
          : item?.slug,
      item_name: [make, model, version].join(' '),
      affiliation: null,
      coupon: null,
      discount: null,
      index,
      item_brand: make,
      item_category: vehicleType || null,
      item_category2: make,
      item_category3: category,
      item_category4: model,
      item_category5: version,
      item_category6:
        listId === 'configs' || listId === 'vehicles' || vehicleType === 'motorbike'
          ? exteriorColor
          : null,
      item_category7: listId === 'configs' || listId === 'vehicles' ? interiorColor : null,
      item_category8: equipments || null,
      item_category9: fuelType,
      item_list_id: listId,
      item_list_name: name,
      item_variant: listId === 'configs' || listId === 'vehicles' ? exteriorColor : null,
      item_stock:
        item?.publishableUnits > 0 ||
        category === 'used' ||
        category === 'km0' ||
        category === 'move'
          ? 'in stock'
          : 'out of stock',
      location_id: null,
      price: price ? price.discounted_price / 100 : null,
      quantity: 1,
    }
    return getLowerCaseData(data)
  }

  const getLowerCaseData = (data) => {
    const lowerCaseData = {}
    for (const key of Object.keys(data)) {
      let value
      try {
        value = !key.includes('sha') ? data[key].toLowerCase() : data[key]
      } catch {
        value = data[key]
      }
      lowerCaseData[key] = value
    }

    return lowerCaseData
  }

  /* Plugin */

  const tracker = {
    trackEvent: (eventName, eventData = {}, parent = 'comp_page') => {
      try {
        if (process?.browser && process?.env?.gtm_enabled) {
          const datalayer = {}

          // Clear data
          datalayer.comp_core = null
          datalayer.comp_product = null
          datalayer.comp_user = null
          datalayer.comp_page = null
          datalayer.comp_purchase = null
          datalayer.ecommerce = null

          datalayer.event = eventName?.toLowerCase()

          if (parent) {
            datalayer[parent] = getLowerCaseData(eventData)
          }

          datalayer._clear = true
          app.$gtm.push(datalayer)
        }
      } catch {
        // eslint-disable-next-line no-console
        console.error(
          `error triying to emit ${eventName} event in ${parent} with data: ${eventData}`,
        )
      }
    },

    // Global
    pageView: async (pageCategory, pageTitle) => {
      const currentUser = store.getters['auth/currentUser']
      const currentUserEmail = currentUser?.email || null
      const currentUserEmailSha = (await digestMessage(currentUserEmail)) || null

      const userAgent = navigator.userAgent || null

      let deviceType = null
      if (/mobile|ipad|iphone|android/i.test(userAgent)) {
        deviceType = 'mobile'
      } else if (/tablet/i.test(userAgent)) {
        deviceType = 'tablet'
      } else {
        deviceType = 'desktop'
      }

      let OS = null
      if (/windows phone/i.test(userAgent)) {
        OS = 'windows phone'
      } else if (/android/i.test(userAgent)) {
        OS = 'android'
      } else if (/iphone|ipad|ipod/i.test(userAgent)) {
        OS = 'ios'
      } else if (/mac os x/i.test(userAgent)) {
        OS = 'macos'
      } else if (/windows/i.test(userAgent)) {
        OS = 'windows'
      } else if (/linux/i.test(userAgent)) {
        OS = 'linux'
      } else {
        OS = 'other'
      }

      const cookieSuffix = currentUser ? `_${await digestMessage(currentUser.uid)}` : ''
      const twoYearsCookie = app.$cookiez.get(`twoYearsExpirationDate${cookieSuffix}`)
      const userRecency = `${twoYearsCookie ? twoYearsCookie.dateDiff : 0}d`

      const eventData = {
        site_brand: storeConfiguration.site_brand,
        site_product_condition: storeConfiguration.site_product_condition,
        site_regional_hub: storeConfiguration.site_regional_hub,
        site_country: storeConfiguration.site_country,
        site_language: storeConfiguration.site_language,
        site_type: 'ecommerce',
        site_domain: storeConfiguration.site_domain,
        business_unit: 'store',
        page_category: pageCategory,
        page_title: pageTitle,
        page_url: window.location.href,
        user_agent: userAgent,
        user_name: currentUser?.name || null,
        user_name_sha: (await digestMessage(currentUser?.name)) || null,
        user_email: currentUserEmail,
        user_email_sha: currentUserEmailSha,
        user_phone: currentUser?.phone || null,
        user_phone_sha: (await digestMessage(currentUser?.phone)) || null,
        user_id: currentUserEmailSha,
        sf_contact_id: null, // not sent due to we don't have/use this info
        user_id_cookie: await getGtmClientId(),
        device_id: null, // not sent due to privacy issues
        device_type: deviceType,
        operating_system: OS,
        user_business: 'b2c',
        user_type: currentUser ? 'repeat' : 'new',
        user_recency: userRecency,
        user_status: currentUser ? 'logged' : 'not logged',
        browser_language: getBrowserLanguage(),
        source: store.getters['campaign/getSource'],
        medium: store.getters['campaign/getCampaign'],
        campaign: store.getters['campaign/getCampaign'],
        referrer: document.referrer || null,
        previous_page:
          previousPage && previousPage !== '/' ? previousPage : document.referrer || null,
      }

      tracker.trackEvent('page_view', eventData, 'comp_core')
    },

    // Catalog
    viewItemList: (listId, name, localItems, customData = {}) => {
      const items = []

      localItems.forEach((item, index) => {
        items.push(
          getItemData(listId, name, item, index, customData?.category, customData?.vehicleType),
        )
      })

      const eventData = {
        item_list_id: listId,
        item_list_name: name,
        items,
      }

      tracker.trackEvent('view_item_list', eventData, 'ecommerce')
    },
    selectItem: (listId, name, item, customData = {}) => {
      const eventData = {
        item_list_id: listId,
        item_list_name: name,
        items: [
          getItemData(
            listId,
            name,
            item,
            0,
            customData?.category,
            customData?.vehicleType,
            customData?.selectedColor,
          ),
        ],
      }

      tracker.trackEvent('select_item', eventData, 'ecommerce')
    },
    viewItem: (listId, name, item, customData = {}) => {
      const eventData = {
        currency: storeConfiguration.currency,
        value: null,
        items: [
          getItemData(
            listId,
            name,
            item,
            0,
            customData?.category,
            customData?.vehicleType,
            customData?.selectedColor,
          ),
        ],
      }

      tracker.trackEvent('view_item', eventData, 'ecommerce')
    },
    addShippingInfo: (listId, name, item, customData = {}) => {
      const eventData = {
        currency: storeConfiguration.currency,
        value: null,
        coupon: null,
        shipping_tier: null,
        dealer_name: null,
        estimated_delivery: null,
        items: [getItemData(listId, name, item, 0, customData?.category, customData?.vehicleType)],
      }

      tracker.trackEvent('add_shipping_info', eventData, 'ecommerce')
    },
    purchaseClick: (item, customData = {}) => {
      const eventData = {
        position: 'page',
        vehicle_type: customData?.vehicleType || 'car',
        brand: item?.make?.name || null,
        product_condition: customData?.product_condition || 'new',
        model: item?.model?.name || null,
        version: item?.name || item?.version?.name || null,
        fuel_type: getFuelType(item?.specs?.engines || item?.version?.specs?.engines),
      }

      tracker.trackEvent('purchase_click', eventData, 'comp_purchase')
    },

    // User
    signUp: async (status = 'ok', method = 'email') => {
      const eventData = {
        status,
        ...(await getUserSessionData(method)),
      }

      tracker.trackEvent('sign_up', eventData, 'comp_user')
    },
    login: async (status = 'ok', method = 'email') => {
      const eventData = {
        status,
        ...(await getUserSessionData(method)),
      }

      tracker.trackEvent('login', eventData, 'comp_user')
    },

    // Booking
    reservationRequestClick: (vehicleConfig, customData = {}) => {
      const eventData = {
        position: 'page',
        ...getInfoVehiclePurchase(vehicleConfig, customData),
      }

      tracker.trackEvent('reservation_request_click', eventData, 'comp_product')
    },
    reservationRequestStep: async (vehicleConfig, customData = {}) => {
      const eventData = {
        position: 'page',
        reservation_payment_type: 'credit card',
        step: `${customData?.step || 1}/3`,
        ...(await getNameSessionData()),
        ...getInfoVehiclePurchase(vehicleConfig, customData),
      }

      tracker.trackEvent('reservation_request_steps', eventData, 'comp_product')
    },
    reservationRequestCompleted: async (vehicleConfig, customData = {}) => {
      const eventData = {
        ...getInfoVehiclePurchase(vehicleConfig, customData),
        reservation_payment_type: 'credit card',
        ...(await getNameSessionData()),
        status: 'ok',
        step: '3/3',
      }

      tracker.trackEvent('reservation_request_completed', eventData, 'comp_product')
    },

    // Financing
    financeRequestOnlineClick: async (vehicleConfig, customData = {}, financingData = {}) => {
      const eventData = {
        ...getInfoVehiclePurchase(vehicleConfig, customData),
        dealer_name: null,
        ...getFinancingDetails(financingData),
        ...(await getNameSessionData()),
      }

      tracker.trackEvent('finance_request_online_click', eventData, 'comp_product')
    },
    financeRequestOnlineSteps: async (vehicleConfig, customData = {}, financingData = {}) => {
      const eventData = {
        ...getInfoVehiclePurchase(vehicleConfig, customData),
        dealer_name: null,
        ...getFinancingDetails(financingData),
        ...(await getNameSessionData()),
        step: `${customData?.step || 2}/${customData?.total_steps || 5}`,
      }

      tracker.trackEvent('finance_request_online_steps', eventData, 'comp_product')
    },
    financeRequestOnlineCompleted: async (vehicleConfig, customData = {}, financingData = {}) => {
      const eventData = {
        ...getInfoVehiclePurchase(vehicleConfig, customData),
        dealer_name: null,
        ...getFinancingDetails(financingData),
        ...(await getNameSessionData()),
        status: 'ok',
        step: '5/5',
      }

      tracker.trackEvent('finance_request_online_completed', eventData, 'comp_product')
    },

    // Contact form
    contactFormClick: (position) => {
      const eventData = {
        position: position || null,
      }
      tracker.trackEvent('contact_form_click', eventData, 'comp_page')
    },
    contactFormView: async (formData) => {
      const eventData = {
        ...(await getContactFormData(formData)),
      }
      tracker.trackEvent('contact_form_view', eventData, 'comp_page')
    },
    contactFormCompleted: async (formData) => {
      const eventData = {
        ...(await getContactFormData(formData)),
        status: formData?.status || null,
      }

      tracker.trackEvent('contact_form_completed', eventData, 'comp_page')
    },

    // Other
    menuClick: (name) => {
      const eventData = {
        position: 'top menu',
        main_category: name || null,
        selected_category: name || null,
      }

      tracker.trackEvent('menu_click', eventData, 'comp_page')
    },
    footerClick: (name) => {
      const eventData = {
        position: 'bottom menu',
        main_category: name || null,
        selected_category: name || null,
      }

      tracker.trackEvent('footer_click', eventData, 'comp_page')
    },
    openChat: (chatType) => {
      const eventData = {
        chat_type: chatType || null,
      }

      tracker.trackEvent('open_chat', eventData, 'comp_page')
    },
    emailClick: (email) => {
      const eventData = {
        mail_to: email || null,
      }

      tracker.trackEvent('email_click', eventData, 'comp_page')
    },
    phoneClick: (phone) => {
      const eventData = {
        phone_ctc: phone || null,
      }

      tracker.trackEvent('phone_click', eventData, 'comp_page')
    },
  }

  inject('tracker', tracker)
}
