<template>
  <PartialsSubmenu class="user-menu" data-cy="user-submenu">
    <template v-slot:header>
      <Icon filled name="person" size="1.25rem" data-cy="navbar_customer" />
    </template>
    <template v-slot:menu>
      <NuxtLink :to="localePath('customer-orders')" class="orders">
        <Icon name="directions_car" size="1.25rem" />
        <span>{{ $t('navbar.orders') }}</span>
      </NuxtLink>
      <NuxtLink :to="localePath('customer-details')" class="details">
        <Icon name="person_outline" size="1.25rem" />
        <span>{{ $t('navbar.details') }}</span>
      </NuxtLink>
      <NuxtLink :to="localePath('customer-settings')" class="settings">
        <Icon name="settings" size="1.25rem" />
        <span>{{ $t('navbar.settings') }}</span>
      </NuxtLink>
      <a data-cy="submenu-logout" class="logout" @click="$emit('logout')">
        <Icon name="logout" size="1.25rem" />
        <span>{{ $t('navbar.logout') }}</span>
      </a>
    </template>
  </PartialsSubmenu>
</template>

<script>
// Components
import Icon from '~/components/Icon.vue'
import PartialsSubmenu from '~/components/partials/Submenu.vue'

export default {
  name: 'PartialUserSubmenu',
  components: {
    Icon,
    PartialsSubmenu,
  },
  data() {
    return {
      open: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.user-menu :deep(.submenu__header) {
  aspect-ratio: 1 / 1 !important;
  padding: 0.5rem !important;
}
</style>
