var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{ref:"swiper",class:[
    this.theme,
    'navbar-scrollable',
    `navbar-scrollable--${this.theme}`,
    'swiper',
    {
      'navbar-scrollable--static': _vm.isStatic,
    },
  ]},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.options),function(option){return _c('div',{key:`${_vm.componentUid}_${option.key}`,class:`navbar-scrollable__item swiper-slide selection-element-${option.key}`,attrs:{"data-cy":"selection-element"},on:{"click":() => _vm.onSelectOption(option.key)}},[_c('div',{class:[
          'navbar-scrollable__item__tab',
          {
            'navbar-scrollable__item__tab--current': _vm.selected === option.key,
          },
        ],attrs:{"id":option.key,"data-cy":"scrollable-option"}},[_c('span',{staticClass:"title medium f-size-14 f-uppercase",attrs:{"data-cy":"selection-title"}},[_vm._v("\n          "+_vm._s(option?.value || _vm.$t(`global.categories_extended_shorted.${option.key}`)[1])+"\n        ")]),_vm._v(" "),(option?.hint)?_c('span',{staticClass:"subtitle f-size-12 f-secondary"},[_vm._v("\n          "+_vm._s(option.hint)+"\n        ")]):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }